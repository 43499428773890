import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';
import './style.css';
import NBAPlayerHealthScoresImg from './img/homepage/playerHealthScore.png';
import NBAPositionHealthScoresImg from './img/homepage/positionalHealthScore.png';
import NBATeamHealthScoresImg from './img/homepage/teamHealthScore.png';
// import NBAHeadshot1 from './img/homepage/nbaheadshots/headshot1.png';
// import NBAHeadshot2 from './img/homepage/nbaheadshots/headshot2.png';
// import NBAHeadshot3 from './img/homepage/nbaheadshots/headshot3.png';
// import NBAHeadshot4 from './img/homepage/nbaheadshots/headshot4.png';

import NBAHeadshot1 from './img/homepage/nbaheadshots/aaron.png';
import NBAHeadshot2 from './img/homepage/nbaheadshots/barkley.png';
import NBAHeadshot3 from './img/homepage/nbaheadshots/pat_mahomes.png';
import NBAHeadshot4 from './img/homepage/nbaheadshots/Hill.png';

import { Event } from '../Tracking';

function Dashboard(props) {
	const {
		schedules,
		teams,
		user,
		purchasePlan,
		freeAccess,
		dispatch,
		sources
		// carouselArticleImages,
	} = props;

	if (!schedules && !teams) return null;
	if (schedules.data && !schedules.data.schedules && teams.data && !teams.data.teamInfo && !schedules?.isLoading) {
		return (
			<React.Fragment>
				<div id='fullHeight'>
					<div id='loading' />
				</div>
			</React.Fragment>
		);
	}

	if (user.isLoading || purchasePlan.isLoading || schedules.isLoading || freeAccess?.isLoading) {
		return (
			<div id='fullHeight'>
				<div id='loading' />
			</div>
		);
	}

	return (
		<div>
			<section id='homeMainSection' className='homeMainSection'>
				<div className='homeMainDiv'>
					<div className='homeCoverBackgroundDiv'>
						<div className='homeCoverDiv mainContainer'>
							<div className='homeCoverTextDiv'>
								<h1 className='homeCoverHeader'>Elevate your NFL daily fantasy strategy</h1>
								<p className='homeCoverText'>Our advanced algorithm, backed by top medical experts, analyzes over a million data points, offering deep insights into each player's health and performance potential.</p>
								{!user?.login ? (
									<>
										<Button
											className='signupButton homeCoverSignupButton'
											onClick={() => {
												dispatch({
													type: 'AUDIT',
													payload: { action: 'SIGNUP' }
												});
												Event(sources?.source, 'CLICK_SIGNUP', 'DASHBOARD_SIGNUP_BUTTON');
											}}
											href='/signup'
											>
											Sign Up For Free
										</Button>
										<br></br>
										<br></br>
									</>
								): null }
								<Row className='homeNbaHeadshotRow justify-content-center'>
									<Col lg={3} md={4} sm="6" xs="6">
										<div className='homeNbaHeadshotCol position-relative'>
											<img src={NBAHeadshot1} alt='NBA Headshot' className='homeNbaHeadshotImg' />
											<div className='homeNbaHeadshotHealthScoreDiv homeNbaHeadshotHealthScoreDiv1'>
												<span className='homeNbaHeadshotHealthScore'>9.26</span>
											</div>
											<div className='player_overlay'>
												<div className='title_group'>
													<span>Aaron</span>
													<h6 className='h6'>Rodgers</h6>
												</div>
												<div className='injury_info'>
													<span className='injury_title'>Latest injury</span>
													<span>September 10, 2023</span>
													<span>Ankle</span>
												</div>
											</div>	
										</div>
									</Col>
									<Col lg={3} md={4} sm="6" xs="6">
										<div className='homeNbaHeadshotCol position-relative'>
											<img src={NBAHeadshot2} alt='NBA Headshot' className='homeNbaHeadshotImg' />
											<div className='homeNbaHeadshotHealthScoreDiv homeNbaHeadshotHealthScoreDiv2'>
												<span className='homeNbaHeadshotHealthScore'>8.1</span>
											</div>
											<div className='player_overlay'>
												<div className='title_group'>
													<span>Saquon</span>
													<h6 className='h6'>Barkley</h6>
												</div>
												<div className='injury_info'>
													<span className='injury_title'>Latest injury</span>
													<span>October 22, 2023</span>
													<span>Elbow</span>
												</div>
											</div>	
										</div>
									</Col>
									<Col lg={3} md={4} sm="6" xs="6">
										<div className='homeNbaHeadshotCol position-relative'>
											<img src={NBAHeadshot3} alt='NBA Headshot' className='homeNbaHeadshotImg' />
											<div className='homeNbaHeadshotHealthScoreDiv homeNbaHeadshotHealthScoreDiv3'>
												<span className='homeNbaHeadshotHealthScore'>7.8</span>
											</div>
											<div className='player_overlay'>
												<div className='title_group'>
													<span>Patrick</span>
													<h6 className='h6'>Mahomes</h6>
												</div>
												<div className='injury_info'>
													<span className='injury_title'>Latest injury</span>
													<span>December 6, 2023</span>
													<span>Shoulder</span>
												</div>
											</div>	
										</div>
									</Col>
									<Col lg={3} md={4} sm="6" xs="6">
										<div className='homeNbaHeadshotCol position-relative'>
											<img src={NBAHeadshot4} alt='NBA Headshot' className='homeNbaHeadshotImg' />
											<div className='homeNbaHeadshotHealthScoreDiv homeNbaHeadshotHealthScoreDiv4'>
												<span className='homeNbaHeadshotHealthScore'>4.2</span>
											</div>
											<div className='player_overlay'>
												<div className='title_group'>
													<span>Tyreek</span>
													<h6 className='h6'>Hill</h6>
												</div>
												<div className='injury_info'>
													<span className='injury_title'>Latest injury</span>
													<span>November 17, 2023</span>
													<span>Hand</span>
												</div>
											</div>	
										</div>
									</Col>
								</Row>
								<p className='homeCoverTextBottom'>With our detailed player and team health scores, you gain a doctor's perspective on the game, allowing you to make picks with unmatched precision.</p>
								{/* {!user?.login ? (
									<Button
										className='signupButton homeCoverSignupButton'
										onClick={() => {
											dispatch({
												type: 'AUDIT',
												payload: { action: 'SIGNUP' }
											});
											Event(sources?.source, 'CLICK_SIGNUP', 'DASHBOARD_SIGNUP_BUTTON');
										}}
										href='/signup'
									>
										JOIN FOR FREE
									</Button>
								) : ( */}
									<Button href='/nfl/upcominggames' className='signupButton homeCoverSignupButton'>
										UPCOMING GAMES
									</Button>
								{/* )} */}
							</div>
						</div>
					</div>
					<div className='homeDifferenceDiv mainContainer pb-0'>
						<h1 className='homeDifferenceHeader'>See the difference with Inside Injuries</h1>
						<p className='homeDifferenceText'>Discover how Inside Injuries has revolutionized DFS play. From last-minute lineup changes to season-long strategic adjustments, our platform is an indispensable tool for serious DFS players.</p>
					</div>
					<div className='homeHealthScoresDiv mainContainer'>
						<Row className='homeHealthScoresRow'>
							<Col lg={6} className='homeHealthScoresCol'>
								<div className='homeHealthScoresTextDiv'>
									<p className='homeHealthScoresHeader'>
										Player health score
										<span className='homeHealthScoresHeaderUnderline homeHealthScoresHeaderUnderlinePlayer' />
									</p>
									<p className='homeHealthScoresText'>Your ultimate tool for individual player insights. Factor this in with the Team Health Score and you can really start pinpointing which players are set to shine.</p>
								</div>
							</Col>
							<Col lg={6} className='homeHealthScoresCol'>
								<div className='homeHealthScoresImgDiv'>
									<img src={NBAPlayerHealthScoresImg} alt='Player Health Score' className='homeHealthScoreImg homePlayerHealthScoreImg' />
								</div>
							</Col>
						</Row>
						<Row className='homeHealthScoresRow'>
							<Col lg={6} className='homeHealthScoresCol'>
								<div className='homeHealthScoresTextDiv'>
									<p className='homeHealthScoresHeader'>
										Positional health score
										<span className='homeHealthScoresHeaderUnderline homeHealthScoresHeaderUnderlinePositional' />
									</p>
									<p className='homeHealthScoresText'>Dig deeper into a matchup and see the health of key players and how each position group matches up with the other team.</p>
								</div>
							</Col>
							<Col lg={6} className='homeHealthScoresCol'>
								<div className='homeHealthScoresImgDiv'>
									<img src={NBAPositionHealthScoresImg} alt='Positional Health Score' className='homeHealthScoreImg homePositionalHealthScoreImg' />
								</div>
							</Col>
						</Row>
						<Row className='homeHealthScoresRow'>
							<Col lg={6} className='homeHealthScoresCol'>
								<div className='homeHealthScoresTextDiv'>
									<p className='homeHealthScoresHeader'>
										Team health score
										<span className='homeHealthScoresHeaderUnderline homeHealthScoresHeaderUnderlineTeam' />
									</p>
									<p className='homeHealthScoresText'>The Team Health Score combines injury and health analysis on key players to determine the overall health of the team.</p>
								</div>
							</Col>
							<Col lg={6} className='homeHealthScoresCol'>
								<div className='homeHealthScoresImgDiv'>
									<img src={NBATeamHealthScoresImg} alt='Team Health Score' className='homeHealthScoreImg homeTeamHealthScoreImg' />
								</div>
							</Col>
						</Row>
					</div>
					<div className='homeWinMoreDiv'>
						<div className='mainContainer py-0'>
						<h1 className='homeWinMoreHeader'>Win More. Brag More.</h1>
						<p className='homeWinMoreText'>Inside Injuries elevates your betting with science-backed injury analysis and expert insights. Experience the thrill of making informed, winning decisions.</p>
						</div>		
					</div>
					<div className='homeScienceDiv mainContainer'>
						<h1 className='homeScienceHeader'>Science-Backed Insights.</h1>
						<p className='homeScienceText'>Embark on a new era of fantasy sports strategy with Inside Injury, where extensive medical research meets data science. Our pioneering algorithm, crafted by top orthopedic surgeons, radiologists, and data scientists, leverages over one million data points to provide unparalleled insights.</p>
						<div className='homeScienceCardDiv'>
							<Row className='homeScienceCardRow'>
								<Col lg={3} className='homeScienceCardCol'>
									<div className='homeScienceCard'>
										<div className='howScienceCardImgDiv howScienceCardImgDivFantasy' />
										<div className='homeScienceCardTextDiv'>
											<p className='homeScienceCardText'>
												<b>Understanding the Impact of Injuries on NFL Performance, Fantasy, and Sports Betting</b>
											</p>
											<a href='/articles/fantasyscore' title='Improve Fantasy Scores Article' target='_blank' rel='noreferrer' className='homeScienceCardLink'>
												READ MORE {'>'}
											</a>
										</div>
									</div>
								</Col>
								<Col lg={3} className='homeScienceCardCol'>
									<div className='homeScienceCard'>
										<div className='howScienceCardImgDiv howScienceCardImgDivPower' />
										<div className='homeScienceCardTextDiv'>
											<p className='homeScienceCardText'>
												<b>Unveiling the Doctor's Edge How Comprehensive NFL Injury Data Can Transform Sports Betting Strategies</b>
											</p>
											<a href='/articles/powerofinsideinjuries' title='The Power of Inside Injuries Article' target='_blank' rel='noreferrer' className='homeScienceCardLink'>
												READ MORE {'>'}
											</a>
										</div>
									</div>
								</Col>
								<Col lg={3} className='homeScienceCardCol'>
									<div className='homeScienceCard'>
										<div className='howScienceCardImgDiv howScienceCardImgDivImpact' />
										<div className='homeScienceCardTextDiv'>
											<p className='homeScienceCardText'>
												<b>Understanding the Impact of Injuries on NFL Performance, Fantasy, and Sports Betting</b>
											</p>
											<a href='/articles/impactofinjuries' title='Understanding the Impact of Injuries Article' target='_blank' rel='noreferrer' className='homeScienceCardLink'>
												READ MORE {'>'}
											</a>
										</div>
									</div>
								</Col>
								<Col lg={3} className='homeScienceCardCol'>
									<div className='homeScienceCard'>
										<div className='howScienceCardImgDiv howScienceCardImgDivDoctorsEdge' />
										<div className='homeScienceCardTextDiv'>
											<p className='homeScienceCardText'>
												<b>Understanding the Impact of Injuries on NFL Performance, Fantasy, and Sports Betting</b>
											</p>
											<a href='/articles/doctorsedge' title="Unveiling the Doctor's Edge Article" target='_blank' rel='noreferrer' className='homeScienceCardLink'>
												READ MORE {'>'}
											</a>
										</div>
									</div>
								</Col>
							</Row>
						</div>
					</div>
					<div className='homeFooterDiv mainContainer'>
						<p className='homeFooterHeader'>Experience the fusion of sports and science, offering you real-time injury updates and player health scores.</p>
						{/* {!user?.login ? (
							<Button
								className='signupButton homeCoverSignupButton'
								onClick={() => {
									dispatch({
										type: 'AUDIT',
										payload: { action: 'SIGNUP' }
									});
									Event(sources?.source, 'CLICK_SIGNUP', 'DASHBOARD_SIGNUP_BUTTON');
								}}
								href='/signup'
							>
								JOIN FOR FREE
							</Button>
						) : ( */}
							<Button href='/nba/upcominggames' className='signupButton homeCoverSignupButton'>
								UPCOMING GAMES
							</Button>
						{/* )} */}
					</div>
				</div>
			</section>
		</div>
	);
}

const mapStateToProps = (state) => ({
	isLoading: state.isLoading,
	isLoaded: state.isLoaded,
	freeAccess: state.freeAccess,
	schedules: state.schedules,
	teams: state.teams,
	playersInfo: state.playersInfo,
	match: state.match,
	user: state.user,
	purchasePlan: state.purchasePlan,
	sources: state.sources,
	prices: state.prices
});

export default connect(mapStateToProps)(Dashboard);
