import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Dropdown, Nav, Navbar } from 'react-bootstrap';
import Logo from '../../global/img/logo_full_white.png';
import TwitterIcon from './img/twitterIcon.png';
import './style.css';


function NavbarHeader(props) {
	const { user, dispatch, userActions } = props;

	return (
		<>
			<Navbar id='menu' collapseOnSelect expand='lg' className='bg-body-tertiary' sticky='top'>
				<div className='navbarContainer'>
					<Navbar.Toggle className='toggleMenu' aria-controls='responsive-navbar-nav'>
						<div className='toggleMenuDiv'>
							<span className='toggleMenuLine' />
							<span className='toggleMenuLine' />
							<span className='toggleMenuLine' />
						</div>
					</Navbar.Toggle>
					<a href='/' className='logoLinkMobile'>
						<img src={Logo} alt='Logo' className='logo' />
					</a>
					<Navbar.Collapse id='responsive-navbar-nav'>
						<Nav className='me-auto'>
							<Nav.Link href='/' className='hideMobile'>
								<img src={Logo} alt='Logo' className='logo' />
							</Nav.Link>
						</Nav>
						<Nav>
							<Nav.Link eventKey={2} href='/nfl/upcominggames'>
								NFL
							</Nav.Link>
							<Nav.Link eventKey={4} href='/soccer/upcominggames'>
								SOCCER
							</Nav.Link>
							<Nav.Link eventKey={3} href='/nba/upcominggames'>
								NBA
							</Nav.Link>
							<Nav.Link eventKey={5} href='/about'>
								ABOUT
							</Nav.Link>
							<Nav.Link eventKey={6} href='/whitepaper'>
								WHITEPAPER
							</Nav.Link>
							{user?.admin ? (
								<Nav.Link eventKey={8} href='/admin'>
									ADMIN
								</Nav.Link>
							) : null}
							{user?.login ? <Nav.Link eventKey={7} href='/profile'>
								PROFILE
							</Nav.Link> : null}
							<Nav.Link
								eventKey={6}
								href="/login"
								onClick={() => user?.login && dispatch({ type: "LOGOUT" })}
							>
								{!user?.login ? "LOGIN" : "LOGOUT"}
							</Nav.Link>
							<Nav.Link href='https://twitter.com/InsideInjuries?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor' target='_blank' rel='noreferrer'>
								<img src={TwitterIcon} alt='Twitter Icon' className='twitterIcon' />
							</Nav.Link>
						</Nav>
					</Navbar.Collapse>
				</div>
			</Navbar>
		</>
	);
}

const mapStateToProps = (state) => ({
	options: state.options,
	user: state.user,
	userActions: state.userActions,
	passwordReset: state.passwordReset
});

export default connect(mapStateToProps)(NavbarHeader);
