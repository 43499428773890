import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import '../Dashboard/matchup.css';
import { Alert, Row, Col } from 'react-bootstrap';
import PlayerPanel from '../Dashboard/playerPanel';
import UserHeader from '../UserHeader';
import TeamLegend from '../Dashboard/teamLegend';
import { highlightColumn, highlightColumnColor, checkHealthScoreStatus, getPlayerCategories, sortTopNBAPlayers } from '../utils/dashboardCalculations';
import { sortArrayOfObj } from '../utils/utils';

function NBAMatchup(props) {
	let { id: gameID } = useParams();
	useEffect(() => {
		window.scrollTo(0, 0);
		let { dispatch, match, healthScores, assignCurrentSport } = props;
		assignCurrentSport();
		if (!match.match || gameID) {
			dispatch({ type: 'GET_MATCH', payload: { gameID } });
			if (!healthScores.categoryAverages) {
				dispatch({ type: 'GET_HEALTH_SCORES', payload: { gameID } });
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [showAwayRoster, handleShowAwayRoster] = useState(true);
	const [showHomeRoster, handleShowHomeRoster] = useState(true);

	const toggleRosterView = (type) => {
		if (type === 'away') {
			handleShowAwayRoster(!showAwayRoster);
		} else if (type === 'home') {
			handleShowHomeRoster(!showHomeRoster);
		}
	};

	const { match, schedules, user, hideUserHeader, toggleScheduleView, healthScores, teams } = props;
	const title = 'Health Scores for Team Matchups';

	if (match.isLoading || healthScores.isLoading || user.isLoading)
		return (
			<div id='fullHeight'>
				<div id='loading' />
			</div>
		);
	if (!match.awayPlayers || !match.homePlayers) {
		return (
			<React.Fragment>
				<div className='gameMainDiv'>
					<UserHeader matchup={true} backgroundColor={'#f7f7f7'} title={title} />
					<h1 className='bold text-center'>{match.error}</h1>
				</div>
			</React.Fragment>
		);
	}
	const game = match?.match[0];
	let { awayPlayers, homePlayers } = match;

	const awayTeam = teams?.data?.teamInfo?.find((x) => x.team_id === game?.away_team_id);
	const homeTeam = teams?.data?.teamInfo?.find((x) => x.team_id === game?.home_team_id);

	const reboundersAway = awayPlayers && getPlayerCategories(awayPlayers, 'rebounders', healthScores, awayTeam);
	const scorersAway = awayPlayers && getPlayerCategories(awayPlayers, 'scorers', healthScores, awayTeam);
	const defenseAway = awayPlayers && getPlayerCategories(awayPlayers, 'defense', healthScores, awayTeam);
	const reboundersHome = homePlayers && getPlayerCategories(homePlayers, 'rebounders', healthScores, homeTeam);
	const scorersHome = homePlayers && getPlayerCategories(homePlayers, 'scorers', healthScores, homeTeam);
	const defenseHome = homePlayers && getPlayerCategories(homePlayers, 'defense', healthScores, homeTeam);

	let topFourAway = awayPlayers && sortTopNBAPlayers(awayPlayers);
	let topFourHome = homePlayers && sortTopNBAPlayers(homePlayers);

	const awayRoster =
		awayPlayers &&
		awayPlayers.filter((x) => {
			if (x.active === 1) {
				return x;
			}
			return;
		});
	const homeRoster =
		homePlayers &&
		homePlayers.filter((x) => {
			if (x.active === 1) {
				return x;
			}
			return;
		});

	awayRoster.sort(sortArrayOfObj('player_name', 'asc'));
	homeRoster.sort(sortArrayOfObj('player_name', 'asc'));

	const homeHealthScore = game && game.home_team_gaming_score;
	const awayHealthScore = game && game.away_team_gaming_score;
	const homeStatusText = checkHealthScoreStatus(homeHealthScore, false, 'team');
	const awayStatusText = checkHealthScoreStatus(awayHealthScore, false, 'team');
	let teamHighlightShadowAway;
	let teamHighlightShadowHome;
	const highlightCol = highlightColumn(homeHealthScore, awayHealthScore);
	if (highlightCol === 'away') {
		teamHighlightShadowAway = 'teamShadowHighlight';
	} else if (highlightCol === 'home') {
		teamHighlightShadowHome = 'teamShadowHighlight';
	}
	const homeHighlightColor = highlightColumnColor(homeHealthScore, 'teamHighlight');
	const awayHighlightColor = highlightColumnColor(awayHealthScore, 'teamHighlight');
	const offenseDesc = 'This category reflects the avg health score of all core offensive players injuries on a specific team. It assesses their injury impact on performance as a group.';
	const defenseDesc = "This category reflects the avg health score of all core defensive players' injuries on a specific team. It assesses their injury impact on performance as a group.";
	const offenseDefenseDesc = "This category reflects the avg health score of all core players' injuries on a specific team. It assesses their injury impact on performance as a group.";

	if (!reboundersAway) {
		return '';
	}

	return (
		<section className='gameMainSection'>
			<div className='gameMainDiv'>
				<UserHeader matchup={true} backgroundColor={'#f7f7f7'} title={title} hideUserHeader={hideUserHeader} toggleScheduleView={() => toggleScheduleView()} game={game} />
				<div className='gameBoxShadow'>
					<Row className='gameSpreadHeaderRow'>
						<Col lg={6} className={`gameSpreadHeaderCol ${awayHighlightColor}`}>
							<div className='gameSpreadHeaderDiv'>
								<React.Fragment>
									<p className='gameSpreadHeaderSpread'>
										<span style={{ paddingRight: '20px' }}>{awayStatusText.status}</span>
										{awayHealthScore}
									</p>
									<p className='gameSpreadHeaderDescription'>Team Health Score</p>
								</React.Fragment>
							</div>
						</Col>
						<Col lg={6} className={`gameSpreadHeaderCol ${homeHighlightColor}`}>
							<div className='gameSpreadHeaderDiv'>
								<React.Fragment>
									<p className='gameSpreadHeaderDescription'>Team Health Score</p>
									<p className='gameSpreadHeaderSpread pull-right'>
										<span style={{ paddingRight: '20px' }}>{homeStatusText.status}</span>
										{homeHealthScore}
									</p>
								</React.Fragment>
							</div>
						</Col>
					</Row>
					<Row className='gameTeamHeaderRow'>
						<Col lg={6} className={`gameTeamHeaderCol ${teamHighlightShadowAway} col-xs-12`}>
							<div className='gameTeamHeaderDiv'>
								<p className={`gameTeamHeaderSpread bold ${awayHighlightColor}`}>{awayHealthScore}</p>
								<div className='gameTeamHeaderLogoDiv gameTeamHeaderLogoAwayDiv'>
									<img src={game.away_team_logo} alt='Team Logo' className='gameTeamLogo' />
									<p className='gameTeamHeaderName gameTeamHeaderNameAway'>{game.away_team_city + ' ' + game.away_team_name}</p>
								</div>
							</div>
						</Col>
						<Col lg={6} className={`gameTeamHeaderCol ${teamHighlightShadowHome} col-xs-12`}>
							<div className='gameTeamHeaderDiv gameTeamHeaderDivHome'>
								<div className='gameTeamHeaderLogoDiv gameTeamHeaderLogoRightDiv'>
									<p className='gameTeamHeaderName gameTeamHeaderNameHome'>{game.home_team_city + ' ' + game.home_team_name}</p>
									<img src={game.home_team_logo} alt='Team Logo' className='gameTeamLogo' />
								</div>
								<p className={`gameTeamHeaderSpread gameTeamHeaderSpreadRight bold ${homeHighlightColor}`}>{homeHealthScore}</p>
							</div>
						</Col>
					</Row>
					{game.game_description ? (
						<Row className='gameDescriptionRow'>
							<Col lg={12} className='gameDescriptionCol'>
								<p className='gameDescription'>{game.game_description}</p>
							</Col>
						</Row>
					) : null}
					<TeamLegend />
					{user?.login ? (
						<>
							<div className='topFourPlayerDiv'>
								<h1 className='dashboardHowItWorksHeader'>Top 4 Impactful Players</h1>
							</div>
							<Row>
								<Col lg={6} className='gameMatchupHeaderCol gameMatchupHeaderCol1'>
									<div className='matchupHeaderDiv'>
										<hr className='hrStyleMobile' />
										<h3 className='matchupHeaderTopMobile'>Away Team</h3>
										<hr className='hrStyleMobile' />
										<Row>
											{topFourAway?.length &&
												topFourAway?.map((players, i) => {
													return (
														<Col lg={6} key={i}>
															<Link
																className='routerLinkMatchup hover-delay'
																title={`${players.player_name}`}
																to={{
																	pathname: `/nba/player/${players?.player_id}`,
																	playerID: players?.player_id
																}}
															>
																<div className='matchPlayerDiv'>
																	<div className='matchPlayerInfoDiv matchPlayerInfoTopDiv'>
																		<p className='matchPlayerInfoNameTop'>{players.nickname || players.player_name}</p>
																		<p className='matchPlayerInfoInjury'>
																			<span className='grey'>POSITION:</span>
																			<span className='matchPlayerInfo'>{players.position}</span>
																		</p>
																		<p className='matchPlayerInfoInjury'>
																			<span className='grey'>HEALTH SCORE:</span>
																			<span className='matchPlayerInfo'>{players.gaming_score || '10'}</span>
																		</p>
																	</div>
																</div>
															</Link>
														</Col>
													);
												})}
										</Row>
									</div>
								</Col>
								<Col lg={6} className='gameMatchupHeaderCol gameMatchupHeaderCol1'>
									<div className='matchupHeaderDiv'>
										<hr className='hrStyleMobile' />
										<h3 className='matchupHeaderTopMobile'>Home Team</h3>
										<hr className='hrStyleMobile' />
										<Row>
											{topFourHome?.length &&
												topFourHome?.map((players, i) => {
													return (
														<Col lg={6} key={i}>
															<Link
																className='routerLinkMatchup hover-delay'
																title={`${players.player_name}`}
																to={{
																	pathname: `/nba/player/${players?.player_id}`,
																	playerID: players?.player_id
																}}
															>
																<div className='matchPlayerDiv'>
																	{/* <img
																		src={game.home_team_logo}
																		alt="Player"
																		className="matchPlayerImg displayTableCell"
																	/> */}
																	<div className='matchPlayerInfoDiv matchPlayerInfoTopDiv'>
																		<p className='matchPlayerInfoNameTop'>{players.nickname || players.player_name}</p>
																		<p className='matchPlayerInfoInjury'>
																			<span className='grey'>POSITION:</span>
																			<span className='matchPlayerInfo'>{players.position}</span>
																		</p>
																		<p className='matchPlayerInfoInjury'>
																			<span className='grey'>HEALTH SCORE:</span>
																			<span className='matchPlayerInfo'>{players.gaming_score || '10'}</span>
																		</p>
																	</div>
																</div>
															</Link>
														</Col>
													);
												})}
										</Row>
									</div>
								</Col>
							</Row>
						</>
					) : null}

					<Row className='gameMatchupRow'>
						{/* Away Team */}
						<Col lg={12}>
							<h1 className='dashboardHowItWorksHeader'>Position Categories</h1>
						</Col>
						<Col lg={6} className='gameMatchupHeaderCol gameMatchupHeaderCol1'>
							<div className='matchupHeaderDiv'>
								<Row>
									<Col lg={8} className='matchupHeaderCol'>
										<hr className='hrStyleMobile' />
										<p className='matchupMobileHeader'>
											{game.away_team_city} {game.away_team_name}
										</p>
										<hr className='hrStyleMobile' />
									</Col>
									<Col lg={3} className='matchupHeaderCol'>
										<p className='matchupHeader'>Health Score</p>
									</Col>
									<Col lg={1} />
								</Row>
							</div>
							<React.Fragment>
								<div style={{ paddingBottom: '30px' }}>
									<PlayerPanel id={4} healthScoreStatus={reboundersAway} players={reboundersAway.players} panelTitle='REBOUNDERS' tooltip={offenseDesc} sportsName='nba' teamLogo={game.away_team_logo} hideDropdown={true} />
									<PlayerPanel id={5} healthScoreStatus={scorersAway} players={scorersAway.players} panelTitle='SCORERS' tooltip={offenseDesc} sportsName='nba' teamLogo={game.away_team_logo} hideDropdown={true} />
									<PlayerPanel id={6} healthScoreStatus={defenseAway} players={defenseAway.players} panelTitle='DEFENSE' tooltip={defenseDesc} sportsName='nba' teamLogo={game.away_team_logo} hideDropdown={true} />
									<PlayerPanel
										id={1}
										healthScoreStatus={checkHealthScoreStatus(game.away_team_gaming_score, awayRoster, 'team')}
										players={awayRoster}
										panelTitle='ALL ACTIVE PLAYERS'
										tooltip={offenseDefenseDesc}
										openAll={showAwayRoster}
										toggleRosterView={() => toggleRosterView('away')}
										sportsName='nba'
										teamLogo={game.away_team_logo}
										hideDropdown={true}
										ignoreCategories={true}
									/>
								</div>
							</React.Fragment>
						</Col>

						{/* Home Team */}

						<Col lg={6} className='gameMatchupHeaderCol gameMatchupHeaderCol2'>
							<div className='matchupHeaderDiv'>
								<Row>
									<Col lg={8} className='matchupHeaderCol'>
										<hr className='hrStyleMobile' />
										<p className='matchupMobileHeader'>
											{game.home_team_city} {game.home_team_name}
										</p>
										<hr className='hrStyleMobile' />
									</Col>
									<Col lg={3} className='matchupHeaderCol'>
										<p className='matchupHeader'>Health Score</p>
									</Col>
									<Col lg={1} />
								</Row>
							</div>
							<React.Fragment>
								<div style={{ paddingBottom: '30px' }}>
									<PlayerPanel id={4} healthScoreStatus={reboundersHome} players={reboundersHome.players} panelTitle='REBOUNDERS' tooltip={offenseDesc} sportsName='nba' teamLogo={game.home_team_logo} hideDropdown={true} />
									<PlayerPanel id={5} healthScoreStatus={scorersHome} players={scorersHome.players} panelTitle='SCORERS' tooltip={offenseDesc} sportsName='nba' teamLogo={game.home_team_logo} hideDropdown={true} />
									<PlayerPanel id={6} healthScoreStatus={defenseHome} players={defenseHome.players} panelTitle='DEFENSE' tooltip={defenseDesc} sportsName='nba' teamLogo={game.home_team_logo} hideDropdown={true} />
									<PlayerPanel
										id={8}
										healthScoreStatus={checkHealthScoreStatus(game.home_team_gaming_score, homeRoster, 'team')}
										players={homeRoster}
										panelTitle='ALL ACTIVE PLAYERS'
										tooltip={offenseDefenseDesc}
										openAll={showHomeRoster}
										toggleRosterView={() => toggleRosterView('home')}
										sportsName='nba'
										teamLogo={game.home_team_logo}
										hideDropdown={true}
										ignoreCategories={true}
									/>
								</div>
							</React.Fragment>
						</Col>
					</Row>
				</div>
			</div>
		</section>
	);
}

const mapStateToProps = (state) => ({
	isLoading: state.isLoading,
	isLoaded: state.isLoaded,
	healthScores: state.healthScores,
	match: state.match,
	schedules: state.schedules,
	teams: state.teams,
	user: state.user
});

export default connect(mapStateToProps)(NBAMatchup);
