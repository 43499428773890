import React, { Component } from 'react';
import './style.css';

export default class Loading extends Component {
	render() {
		const { inline } = this.props;
		<div style={{ display: !inline ? 'block' : 'flex', height: '100%' }}>
			<div id='loading' />
		</div>;
		return (
			<div
				style={{
					display: inline ? 'flex' : '',
					textAlign: inline ? 'center' : '',
					height: inline ? '100%' : ''
				}}
				id={!inline ? `fullHeight` : ''}
			>
				<div id='loading' />
			</div>
		);
	}
}
