import { put, select } from 'redux-saga/effects';
import { actions } from 'react-redux-form';
import {
	ADMIN_DASHBOARD_SEARCH_SUCCESS,
	ADMIN_DASHBOARD_SEARCH_ERROR,
	ADMIN_DASHBOARD_CHANGE_SUCCESS,
	ADMIN_DASHBOARD_CHANGE_ERROR,
	ADMIN_DASHBOARD_INSERT_SUCCESS,
	ADMIN_DASHBOARD_INSERT_ERROR,
	GET_DASHBOARD_OPTIONS_SUCCESS,
	GET_DASHBOARD_OPTIONS_ERROR,
	// GET_ADMIN_CSV,
	GET_ADMIN_CSV_SUCCESS,
	GET_ADMIN_CSV_ERROR,
	RUN_CALCULATIONS_SUCCESS,
	RUN_CALCULATIONS_ERROR,
	GET_TEAM_HEALTH_SCORE_NFL_SUCCESS,
	GET_TEAM_HEALTH_SCORE_NFL_ERROR,
	SEARCH_GAMES,
	SEARCH_GAMES_SUCCESS,
	SEARCH_GAMES_ERROR,
	SUBMIT_ADMIN_CHANGES_SUCCESS,
	SUBMIT_ADMIN_CHANGES_ERROR
} from './constants';
import { request, toLocalTime, formatDateWithTime } from '../components/utils';
import * as selectors from './selectors';
import { ADMIN_TOKEN } from '../components/utils/config';
var _ = require('lodash');

export function* adminDashboardOptions() {
	const user = yield select((state) => state.user);
	try {
		const result = yield request({}, `/admin/fetchdashboardoptions/${ADMIN_TOKEN}`, 'GET', user?.token);
		if (result.data.status) {
			// yield put({ type: GET_ADMIN_CSV });
			yield put({
				type: GET_DASHBOARD_OPTIONS_SUCCESS,
				payload: {
					teams: result.data?.data?.teams,
					positions: result?.data?.data?.positions,
					bodyParts: result?.data?.data?.bodyParts,
					users: result?.data?.data?.users
				}
			});
		} else {
			yield put({
				type: GET_DASHBOARD_OPTIONS_ERROR
			});
		}
	} catch (err) {
		console.log(err);
		yield put({
			type: GET_DASHBOARD_OPTIONS_ERROR
		});
	}
}

export function* adminDashboardCSV(action) {
	const user = yield select((state) => state.user);
	try {
		const result = yield request({}, `/admin/dashboardCrawlerInfo/${ADMIN_TOKEN}`, 'GET', user?.token);
		if (result.data.status) {
			yield put({
				type: GET_ADMIN_CSV_SUCCESS,
				payload: {
					csvData: result?.data?.data
				}
			});
		} else {
			yield put({
				type: GET_ADMIN_CSV_ERROR
			});
		}
	} catch (err) {
		console.log(err);
		yield put({
			type: GET_ADMIN_CSV_ERROR
		});
	}
}

export function* adminDashboardSearch() {
	const user = yield select(selectors.user);
	const adminDashboardSearch = yield select(selectors.adminDashboardSearch);
	try {
		console.log(adminDashboardSearch);
		const data = { };
		if (adminDashboardSearch.playerName) {
			data.playerName = adminDashboardSearch.playerName;
		}
		if (adminDashboardSearch.teamId) {
			data.teamId = adminDashboardSearch.teamId;
		}
		if (adminDashboardSearch.sportsID) {
			data.sportsID = adminDashboardSearch.sportsID;
		}
		if (adminDashboardSearch.position) {
			data.position = adminDashboardSearch.position;
		}
		const result = yield request(data, `/admin/searchinfo/${ADMIN_TOKEN}`, 'POST', user?.token);
		if (result.data.status && (result?.data?.data?.info?.length || result?.data?.data?.details || (result?.data?.data?.ircTable?.length && result?.data?.data?.hpfTable?.length))) {
			yield put({
				type: ADMIN_DASHBOARD_SEARCH_SUCCESS,
				payload: {
					info: result?.data?.data?.info,
					details: result?.data?.data?.details,
					ircTable: result?.data?.data?.ircTable,
					hpfTable: result?.data?.data?.hpfTable,
					rawCrawler: result?.data?.data?.rawCrawler,
					convertedCrawler: result?.data?.data?.convertedCrawler,
					teamTable: result?.data?.data?.teamTable,
					schedulesTable: result?.data?.data?.schedulesTable,
					heatmapTable: result?.data?.data?.heatmapTable
				}
			});
		} else {
			yield put({
				type: ADMIN_DASHBOARD_SEARCH_ERROR,
				payload: {
					message: !result?.data?.data?.info?.length && !result?.data?.data?.ircTable?.length && !result?.data?.data?.hpfTable.length ? 'Info not found' : 'There was an error retrieving the information'
				}
			});
		}
	} catch (err) {
		console.log(err);
		yield put({
			type: ADMIN_DASHBOARD_SEARCH_ERROR,
			payload: { message: 'There was an error retrieving the information' }
		});
	}
}

export function* adminDashboardChange() {
	const user = yield select(selectors.user);
	const adminDashboardSearch = yield select(selectors.adminDashboardSearch);
	const playerID = adminDashboardSearch.info[0].player_id;
	try {
		const data = {
			sportsID: adminDashboardSearch.sportsID,
			playerID: playerID,
			adminDashboardChange: adminDashboardSearch.updates
		};
		const result = yield request(data, `/admin/changeinfo/${ADMIN_TOKEN}`, 'POST', user?.token);
		if (result.data.status) {
			yield put({
				type: ADMIN_DASHBOARD_CHANGE_SUCCESS,
				payload: { message: 'Changes submitted successfully!' }
			});
		} else {
			yield put({
				type: ADMIN_DASHBOARD_CHANGE_ERROR,
				paylod: { message: 'There was an error submitting your changes' }
			});
		}
	} catch (err) {
		console.log(err);
		yield put({
			type: ADMIN_DASHBOARD_CHANGE_ERROR,
			paylod: { message: 'There was an error submitting your changes' }
		});
	}
}

export function* adminDashboardInsert(action) {
	const { playerID } = action.payload;
	const user = yield select(selectors.user);
	const adminDashboardSearch = yield select(selectors.adminDashboardSearch);
	try {
		const data = {
			sportsID: adminDashboardSearch.sportsID,
			adminDashboardInsert: adminDashboardSearch.insert,
			playerID
		};
		const result = yield request(data, `/admin/insertinfo/${ADMIN_TOKEN}`, 'POST', user?.token);
		if (result.data.status) {
			yield put({
				type: ADMIN_DASHBOARD_INSERT_SUCCESS,
				payload: { message: 'New injury has successfully been created!' }
			});
		} else {
			yield put({
				type: ADMIN_DASHBOARD_INSERT_ERROR,
				payload: { message: 'There was an error creating a new injury' }
			});
		}
	} catch (err) {
		console.log(err);
		yield put({
			type: ADMIN_DASHBOARD_INSERT_ERROR,
			payload: { message: 'There was an error creating a new injury' }
		});
	}
}

export function* adminDashboardRunCalculations(action) {
	const user = yield select(selectors.user);
	const adminDashboardSearch = yield select(selectors.adminDashboardSearch);
	const { player } = action.payload;
	try {
		const data = {
			sportsID: adminDashboardSearch.sportsID,
			playerID: player && adminDashboardSearch.info[0].player_id
		};
		const result = yield request(data, `/admin/runcalculations/${ADMIN_TOKEN}`, 'POST', user?.token);
		if (result.data.status) {
			yield put({
				type: RUN_CALCULATIONS_SUCCESS,
				payload: { message: 'Calculations are being run in the background!' }
			});
		} else {
			yield put({
				type: RUN_CALCULATIONS_ERROR,
				payload: { message: 'There was an error starting the calculations' }
			});
		}
	} catch (err) {
		console.log(err);
		yield put({
			type: RUN_CALCULATIONS_ERROR,
			payload: { message: 'There was an error starting the calculations' }
		});
	}
}

export function* adminTeamHealthScoreNFL(action) {
	const user = yield select(selectors.user);
	try {
		const { filterDateStart = '', filterDateEnd = '', teamId = '', limit = 10, offset = 0, sortOrder = 'desc', sportsID = 1 } = action.payload;
		const data = {
			filterDateStart,
			filterDateEnd,
			teamId,
			limit,
			offset,
			sortOrder,
			sportsID: Number(sportsID)
		};
		const result = yield request(data, `/admin/fetch_teams_score/${ADMIN_TOKEN}`, 'POST', user?.token);
		if (result.data.status) {
			yield put({
				type: GET_TEAM_HEALTH_SCORE_NFL_SUCCESS,
				payload: {
					message: 'Team Health Score fetched!',
					records: result?.data?.data?.records,
					total_records: result?.data?.data?.total_records
				}
			});
		} else {
			yield put({
				type: GET_TEAM_HEALTH_SCORE_NFL_ERROR,
				payload: { message: 'There was an error fetching team health score!' }
			});
		}
	} catch (err) {
		console.log(err);
		yield put({
			type: GET_TEAM_HEALTH_SCORE_NFL_ERROR,
			payload: { message: 'There was an error fetching team health score!' }
		});
	}
}

export function* searchGames(action) {
	let result;
	const { season, season_type, week } = action.payload;
	const user = yield select(selectors.user);
	try {
		result = yield request(null, `/searchGames/${season}/${season_type}/${week}`, 'GET', user?.token);
		if (result.data.status) {
			let nfl_schedules = result?.data?.nfl?.games;
			if (nfl_schedules?.length) {
				result.data.data.nfl.games = nfl_schedules?.map((s) => {
					const localTime = toLocalTime(s?.date_time);
					s.date_time = formatDateWithTime(localTime?.date, true, localTime?.timezone);
					return s;
				});
			}
			yield put({
				type: SEARCH_GAMES_SUCCESS,
				payload: {
					message: result?.data?.message,
					nfl: result?.data?.data?.nfl
				}
			});
		} else {
			yield put({
				type: SEARCH_GAMES_ERROR,
				payload: { message: result?.data?.message }
			});
			yield put(actions.change('alertMessage.error', result?.data?.message));
		}
	} catch (error) {
		console.log(error);
		yield put({
			type: SEARCH_GAMES_ERROR,
			payload: { message: result?.data?.message }
		});
		yield put(actions.change('alertMessage.error', result?.data?.message));
	}
}

export function* submitAdminChanges(action) {
	let result;
	const user = yield select(selectors.user);
	const userActions = yield select(selectors.userActions);
	try {
		const data = {
			...action.payload
		};
		result = yield request(data, `/adminOverrides`, 'POST', user?.token);
		if (result.data.status) {
			const { season, week } = userActions?.admin_actions?.search;
			yield put({
				type: SEARCH_GAMES,
				payload: { season, week }
			});
			yield put({
				type: SUBMIT_ADMIN_CHANGES_SUCCESS
			});
			yield put(actions.change('alertMessage.message', result?.data?.message));
		} else {
			yield put({
				type: SUBMIT_ADMIN_CHANGES_ERROR,
				payload: { message: result?.data?.error }
			});
			yield put(actions.change('alertMessage.error', result?.data?.message));
		}
	} catch (error) {
		console.log(error);
		yield put({
			type: SEARCH_GAMES_ERROR,
			payload: { message: result?.data?.error }
		});
		yield put(actions.change('alertMessage.error', result?.data?.error));
	}
}
