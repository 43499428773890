import React, { Component } from 'react';
import { Col } from 'react-bootstrap';
import { highlightColumnColor } from '../../utils/dashboardCalculations';

class teamCard extends Component {
	componentDidMount() {}

	render() {
		const { team, userAllowed, sportsID } = this.props;
		const highlightColor = highlightColumnColor(team.team_gaming_score, 'cardHighlight');
		return (
			<div id='cardTeamRow' className='cardTeamRow'>
				<Col lg={12} className={`cardHighlight ${userAllowed && highlightColor} cardAdminTeamCol col-xs-12`}>
					<div className='cardTeamDiv'>
						<img src={team.team_logo} alt='' className='cardTeamLogo' />
						<p className='cardTeamName'>
							{sportsID !== 3 ? (
								<>
									{team.team_city}
									<br />
								</>
							) : null}
							{team.team_name}
						</p>
					</div>
				</Col>
			</div>
		);
	}
}

export default teamCard;
