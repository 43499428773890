import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Modal } from 'react-bootstrap';
import PlayerHealthScores from './playerHealthScores';
import PlayerColumn from './playerColumns';
import Logo from '../../../global/img/logo_full_white.png';
import InputFields from '../../InputFields';
import { Form, Control } from 'react-redux-form';
import '../style.css';

function TeamCard(props) {
	const { user, players, sportsName, nbaPage, sources, dispatch } = props;

	const [showModal, setShowModal] = useState(false);
	const [searchString, setSearchString] = useState('');
	const [filteredPlayers, setFilteredPlayers] = useState([]);

	// Pagination State
	const [visiblePlayers, setVisiblePlayers] = useState(50); // initially show 50 players

	useEffect(() => {
		setFilteredPlayers(players);
	}, [players]);

	// Infinite scrolling logic
	const loadMorePlayers = () => {
		if (visiblePlayers < filteredPlayers.length) {
			setVisiblePlayers((prevVisible) => prevVisible + 50); // Load 50 more players
		}
	};

	// Handle scroll event to detect when user reaches the bottom
	useEffect(() => {
		const handleScroll = () => {
			const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
			const windowHeight = window.innerHeight;
			const docHeight = document.documentElement.scrollHeight;
	
			// If the user has scrolled to the bottom of the document
			if (scrollTop + windowHeight >= docHeight) {
				loadMorePlayers(); // Load more players when bottom is reached
			}
		};
	
		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	}, [visiblePlayers, filteredPlayers]);

	// Handle search filter
	useEffect(() => {
		let result = players;
		if (searchString) {
			result = players?.filter((player) =>
				player.player_name.toLowerCase().includes(searchString.toLowerCase())
			);
		}
		setFilteredPlayers(result);
		setVisiblePlayers(50); // Reset visible players to initial count when searching
	}, [searchString, players]);

	const handleLinkClick = (e) => {
		if (!user?.login && nbaPage) {
			e.preventDefault();
			setShowModal(true);
		}
	};

	const handleSignup = (data) => {
		dispatch({
			type: 'SIGNUP',
			payload: data
		});
	};

	return (
		<React.Fragment>
			<div className='searchBox'>
				<input
					type='text'
					placeholder='Search players...'
					onChange={(e) => setSearchString(e.target.value)}
					className='playerSearchInput'
				/>
			</div>

			{/* Render visible players only */}
			{filteredPlayers && filteredPlayers.slice(0, visiblePlayers).map((player, i) => (
				<Col
					id={player.team_id}
					lg={4}
					key={i}
					className='cardCol col-xl-5ths col-md-6 col-sm-6 col-xs-12'
					style={{ height: '300px' }}
				>
					<div className='cardColDiv'>
						<Col lg={12} className='cardViewGameCol'>
							<>
								{user?.login ? <PlayerHealthScores player={player} sportsName={sportsName} /> : <div style={{ padding: '27.5px 0' }} />}
							</>
							<PlayerColumn player={player} userAllowed={user?.login} sportsID={user?.sportsID} />
							<Link
								className='cardViewGameButton hover-delay'
								title='View Player'
								to={{
									pathname: `/${sportsName || 'nfl'}/player/${player.player_id}`
								}}
								onClick={handleLinkClick}
							>
								VIEW PLAYER
							</Link>
						</Col>
					</div>
				</Col>
			))}

			<Modal show={showModal} onHide={() => setShowModal(false)} centered size='lg'>
				<Modal.Body>
					<section className='signupMainSection'>
						<div className='signupMainDiv'>
							<div className='text-center'>
								<img src={Logo} alt='Inside Injuries Logo' className='signupLogo' />
								<p className='loginFlowText'>
									Already have an account?
									<a href='/login' className='loginFlowLink ps-2'>
										Login here
									</a>
								</p>
							</div>
							<div className='signupFormDiv'>
								<Form model='userActions.signup' onSubmit={handleSignup}>
									<div className='inputCol'>
										<InputFields model='.first_name' inputType='text' type='text' placeholder='Enter your first name' label='First Name' />
									</div>
									<div className='inputCol'>
										<InputFields model='.email' inputType='text' type='email' placeholder='Enter your email' label='Email' />
									</div>
									<div className='inputCol'>
										<InputFields model='.password' inputType='text' type='password' placeholder='Enter your password' label='Password' />
									</div>
									<div className='w-100'>
										<Control.button model='userActions.signup' className='loginButton' type='submit'>
											SIGN UP
										</Control.button>
									</div>
								</Form>
								<p className='signupHeaderText'>Join for free and get access to all injury data.</p>
							</div>
						</div>
					</section>
				</Modal.Body>
			</Modal>
		</React.Fragment>
	);
}

const mapStateToProps = (state) => ({
	isLoading: state.isLoading,
	isLoaded: state.isLoaded,
	schedules: state.schedules,
	match: state.match,
	user: state.user,
	purchasePlan: state.purchasePlan,
	sources: state.source
});

export default connect(mapStateToProps)(TeamCard);
