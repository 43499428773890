import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import '../Dashboard/matchup.css';
import { Alert, Row, Col } from 'react-bootstrap';
import PlayerPanel from '../Dashboard/playerPanel';
import UserHeader from '../UserHeader';
import TeamLegend from '../Dashboard/teamLegend';
import { highlightColumnColor, checkHealthScoreStatus, getPlayerCategories, sortTopNBAPlayers } from '../utils/dashboardCalculations';
import { formatDate } from '../utils';
import NBAMatchup from '../Matchup/NBAMatchup';

function NBATeam(props) {
	let { id: teamID } = useParams();

	useEffect(() => {
		window.scrollTo(0, 0);
		let { dispatch, healthScores, assignCurrentSport } = props;
		assignCurrentSport();
		dispatch({ type: 'VIEW_TEAM', payload: { teamID } });
		dispatch({ type: 'TEAM_SCHEDULE', payload: { teamID } });
		if (!healthScores.categoryAverages) {
			dispatch({ type: 'GET_HEALTH_SCORES', payload: { teamID } });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [showRoster, handleShowRoster] = useState(true);
	const [showSchedule, handleShowSchedule] = useState(false);
	const [propID, handlePropID] = useState(null);

	const toggleRosterView = () => {
		handleShowRoster(!showRoster);
	};

	const toggleScheduleView = (gameID) => {
		handleShowSchedule(!showSchedule);
		handlePropID(gameID);
	};

	const { user, teams, teamSchedule, healthScores } = props;
	if (teams.isLoading || !teams?.teamInfo || teamSchedule.isLoading || (teams && !teams.players) || healthScores.isLoading) {
		return (
			<div id='fullHeight'>
				<div id='loading' />
			</div>
		);
	}

	const teamInfo = teams && teams.teamInfo[0];
	const scheduleInfo = teamSchedule?.data?.teamInfo;
	const title = 'Health Scores for Team';
	if (!teamInfo) {
		return (
			<React.Fragment>
				<div className='gameMainDiv'>
					<UserHeader matchup={true} backgroundColor={'#f7f7f7'} title={title} />
					<h1 className='bold text-center'>Sorry, It looks like we weren't able to retrieve team information! Please try again later</h1>
				</div>
			</React.Fragment>
		);
	}

	const playerInfo = teams && teams.players;

	const rebounders = playerInfo && getPlayerCategories(playerInfo, 'rebounders', healthScores, teamInfo);
	const scorers = playerInfo && getPlayerCategories(playerInfo, 'scorers', healthScores, teamInfo);
	const defense = playerInfo && getPlayerCategories(playerInfo, 'defense', healthScores, teamInfo);

	const topFour = playerInfo && sortTopNBAPlayers(playerInfo);

	const roster =
		playerInfo &&
		// eslint-disable-next-line array-callback-return
		playerInfo.filter((x) => {
			if (x.active === 1) {
				return x;
			}
		});

	let healthScore = teamInfo && teamInfo.team_gaming_score;
	const statusText = checkHealthScoreStatus(healthScore, false, 'team');

	const highlightColor = highlightColumnColor(healthScore, 'teamHighlight');
	const offenseDesc = 'This category reflects the avg health score of all core offensive players injuries on a specific team. It assesses their injury impact on performance as a group.';
	const defenseDesc = "This category reflects the avg health score of all core defensive players' injuries on a specific team. It assesses their injury impact on performance as a group.";
	const offenseDefenseDesc = "This category reflects the avg health score of all core players' injuries on a specific team. It assesses their injury impact on performance as a group.";
	if (showSchedule) {
		return (
			<React.Fragment>
				<NBAMatchup gameID={propID} hideUserHeader={true} toggleScheduleView={() => toggleScheduleView()} />
			</React.Fragment>
		);
	}
	return (
		<section className='gameMainSection'>
			<div className='gameMainDiv'>
				<UserHeader matchup={true} backgroundColor={'#f7f7f7'} title={title} />
				<div className='gameBoxShadow'>
					<Row className='gameSpreadHeaderRow'>
						<Col lg={12} className={`gameSpreadHeaderCol ${highlightColor}`}>
							<div className='gameSpreadHeaderDiv'>
								<React.Fragment>
									<p className='gameSpreadHeaderSpread'>
										<span style={{ paddingRight: '20px', fontWeight: 'bold' }}>{statusText.status}</span>
										{healthScore}
									</p>
									<p className='gameSpreadHeaderDescription'>Team Health Score</p>
								</React.Fragment>
							</div>
						</Col>
					</Row>
					<Row className='gameTeamHeaderRow'>
						<Col lg={12} className={`gameTeamHeaderCol teamShadowHighlight col-xs-12`}>
							<div className='gameTeamHeaderDiv displayLogoTable'>
								<p className={`gameTeamHeaderSpread bold ${highlightColor}`}>{healthScore}</p>
								<div className='gameTeamHeaderLogoDiv'>
									<img src={teamInfo.team_logo} alt='Team Logo' className='gameTeamLogo' />
									<p className='gameTeamHeaderName gameTeamHeaderNameAway'>{teamInfo.team_city + ' ' + teamInfo.team_name}</p>
								</div>
							</div>
						</Col>
					</Row>
					<TeamLegend />

					<Row className='gameMatchupRow'>
						{/* Team */}
						<Col lg={6} className='gameMatchupHeaderCol gameMatchupHeaderCol1'>
							<>
								<div className='topFourPlayerDiv'>
									<h1 className='dashboardHowItWorksHeader text-center'>Top 4 Impactful Players</h1>
								</div>
								<div className='matchupHeaderDiv'>
									<Row>
										{topFour?.length &&
											topFour.map((players, i) => {
												return (
													<Col lg={6} key={i}>
														<Link
															className='routerLinkMatchup hover-delay'
															title={`${players.player_name}`}
															to={{
																pathname: `/nba/player/${players.player_id}`,
																playerID: players.player_id
															}}
														>
															<div className='matchPlayerDiv'>
																<div className='matchPlayerInfoDiv matchPlayerInfoTopDiv'>
																	<p className='matchPlayerInfoNameTop'>{players.nickname || players.player_name}</p>
																	<p className='matchPlayerInfoInjury'>
																		<span className='grey'>POSITION:</span>
																		<span className='matchPlayerInfo'>{players.position || players.position_category}</span>
																	</p>
																	<p className='matchPlayerInfoInjury'>
																		<span className='grey'>HEALTH SCORE:</span>
																		<span className='matchPlayerInfo'>{players.gaming_score || '10'}</span>
																	</p>
																</div>
															</div>
														</Link>
													</Col>
												);
											})}
									</Row>
								</div>
							</>
							<>
								<div className='matchupHeaderDiv'>
									<Row>
										<Col lg={12}>
											<h1 className='dashboardHowItWorksHeader'>Position Categories</h1>
										</Col>
										<Col lg={8} className='matchupHeaderCol'>
											<hr className='hrStyleMobile' />
											<p className='matchupMobileHeader'>
												{teamInfo.team_city} {teamInfo.team_name}
											</p>
											<hr className='hrStyleMobile' />
										</Col>
										<Col lg={3} className='matchupHeaderCol'>
											<p className='matchupHeader'>Health Score</p>
										</Col>
										<Col lg={1} />
									</Row>
								</div>
								<React.Fragment>
									<div style={{ paddingBottom: '30px' }}>
										<PlayerPanel id={4} healthScoreStatus={rebounders} players={rebounders.players} panelTitle='REBOUNDERS' tooltip={offenseDesc} sportsName='nba' teamLogo={teamInfo.team_logo} hideDropdown={true} />
										<PlayerPanel id={5} healthScoreStatus={scorers} players={scorers.players} panelTitle='SCORERS' tooltip={offenseDesc} sportsName='nba' teamLogo={teamInfo.team_logo} hideDropdown={true} />
										<PlayerPanel id={6} healthScoreStatus={defense} players={defense.players} panelTitle='DEFENSE' tooltip={defenseDesc} sportsName='nba' teamLogo={teamInfo.team_logo} hideDropdown={true} />
										<PlayerPanel
											id={1}
											healthScoreStatus={checkHealthScoreStatus(teamInfo.team_gaming_score, roster, 'team')}
											players={roster}
											panelTitle='ALL ACTIVE PLAYERS'
											tooltip={offenseDefenseDesc}
											openAll={showRoster}
											toggleRosterView={() => toggleRosterView()}
											sportsName='nba'
											teamLogo={teamInfo.team_logo}
											hideDropdown={true}
											ignoreCategories={true}
										/>
									</div>
								</React.Fragment>
							</>
						</Col>

						{/* Schedules Section */}

						<Col lg={6} className='gameMatchupHeaderCol gameMatchupHeaderCol1'>
							<div className='matchupHeaderDiv'>
								<Row>
									<Col lg={12} className='matchupHeaderCol'>
										<h1 className='dashboardHowItWorksHeader text-center'>Season Schedules</h1>
										<p className='matchupMobileHeader'>
											{teamInfo.team_city} {teamInfo.team_name}
										</p>
									</Col>
									<Col lg={1} />
								</Row>
							</div>
							<div style={{ paddingBottom: '30px' }}>
								<Row>
									{scheduleInfo?.length ? (
										scheduleInfo.map((x, i) => {
											return (
												<React.Fragment key={i}>
													<Col lg={6} className='scheduleLinkCol'>
														<div className='scheduleLinkDiv'>
															<div
																// href="#/"
																// href={`/${user.sportsName}/matchup/${x.game_id}`}
																className='scheduleLink hover-delay'
																// onClick={() =>
																//   user?.login && toggleScheduleView(x.game_id)
																// }
															>
																<p className='scheduleLinkTeam'>
																	<span className='bold'>{teamInfo.team_id !== x.away_team_id ? x.away_team_city + ' ' + x.away_team_name : x.home_team_city + ' ' + x.home_team_name}</span>
																	<br />
																	<span
																		style={{
																			fontSize: '14px',
																			display: 'block',
																			marginTop: '0'
																		}}
																	>
																		{formatDate(true, x.date_time.split('T')[0], null, true)}
																	</span>
																	<span>
																		<b>{x.status}</b>
																	</span>
																</p>
															</div>
														</div>
													</Col>
												</React.Fragment>
											);
										})
									) : (
										<h1>No Team Schedules Found</h1>
									)}
								</Row>
							</div>
						</Col>
					</Row>
				</div>
			</div>
		</section>
	);
}

const mapStateToProps = (state) => ({
	isLoading: state.isLoading,
	isLoaded: state.isLoaded,
	healthScores: state.healthScores,
	teams: state.teams,
	teamSchedule: state.teamSchedule,
	user: state.user
});

export default connect(mapStateToProps)(NBATeam);
