import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Form, Control, actions } from 'react-redux-form';
import { Row, Col, Button, Alert } from 'react-bootstrap';
import InputFields from '../InputFields';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import './style.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ToolkitProvider, { ColumnToggle } from 'react-bootstrap-table2-toolkit';
import CsvDownloader from 'react-csv-downloader';
import AdminOverrides from './overrides';

function Admin(props) {
	const { adminDashboardOptions, adminDashboardSearch, adminDashboardCSV, adminDashboardCalculations, dispatch } = props;

	useEffect(() => {
		dispatch({ type: 'GET_DASHBOARD_OPTIONS' });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [startDate, handleStartDate] = useState(null);
	const [hideIrc, handleHideIrc] = useState(true);
	const [hideHpf, handleHideHpf] = useState(true);
	const [hideRaw, handleHideRaw] = useState(true);
	const [hideConverted, handleHideConverted] = useState(true);
	const [hideTeam, handleHideTeam] = useState(true);
	const [hideSchedules, handleHideSchedules] = useState(true);
	const [hideHeatmap, handleHideHeatmap] = useState(true);

	const handleSearch = () => {
		dispatch({ type: 'ADMIN_DASHBOARD_SEARCH_SUBMIT' });
	};

	const handleChanges = () => {
		dispatch({ type: 'ADMIN_DASHBOARD_CHANGE_SUBMIT' });
	};

	const handleInsert = () => {
		dispatch({
			type: 'ADMIN_DASHBOARD_INSERT_SUBMIT',
			payload: { playerID: adminDashboardSearch?.info[0]?.player_id }
		});
	};

	const handleDateChange = (date) => {
		dispatch(actions.change(`adminDashboardSearch.updates.injuryDate`, date));
		handleStartDate(date);
	};

	const handleDateChangeInsert = (date) => {
		dispatch(actions.change(`adminDashboardSearch.insert.injuryDate`, date));
		handleStartDate(date);
	};

	let columns = [];
	let ircColumns = [];
	let hpfColumns = [];
	let rawColumns = [];
	let convertedColumns = [];
	let teamColumns = [];
	let schedulesColumns = [];
	let heatmapColumns = [];
	const { ToggleList } = ColumnToggle;

	const showColumn = ['ID', 'PLAYER_ID', 'PLAYER_NAME', 'TEAM_NAME', 'POSITION', 'BODY_PART', 'INJURY_DATE', 'FINAL_INJURY_GRADE', 'ADMIN_INJURY_GRADE', 'OPTIMAL_RECOVERY_TIME', 'DAYS_PAST_OPTIMAL_RECOVERY_TIME', 'GRID_STATUS', 'GRID_SCORE_AVERAGE', 'IRC_TOTAL', 'HPF_TOTAL', 'CIS_TOTAL', 'GAMING_SCORE'];

	const showSchedulesColumn = [
		'ID',
		'SEASON',
		'WEEK',
		'STATUS',
		'DAY',
		'DATE_TIME',
		'AWAY_TEAM',
		'HOME_TEAM',
		'AWAY_TEAM_NAME',
		'HOME_TEAM_NAME',
		'AWAY_TEAM_GAMING_SCORE',
		'AWAY_OFFENSIVE_GAMING_AVG',
		'AWAY_DEFENSIVE_GAMING_AVG',
		'HOME_TEAM_GAMING_SCORE',
		'HOME_OFFENSIVE_GAMING_AVG',
		'HOME_DEFENSIVE_GAMING_AVG',
		'OFFENSIVE_LINE_AVG_MAX',
		'OFFENSIVE_LINE_AVG_MIN',
		'OFFENSIVE_SKILLS_AVG_MAX',
		'OFFENSIVE_SKILLS_AVG_MIN',
		'DEFENSIVE_LINE_AVG_MAX',
		'DEFENSIVE_LINE_AVG_MIN',
		'DEFENSIVE_SECONDARY_AVG_MAX',
		'DEFENSIVE_SECONDARY_AVG_MIN',
		'AWAY_TEAM_SCORE',
		'HOME_TEAM_SCORE',
		'AWAY_TEAM_REBOUNDERS_HPF',
		'AWAY_TEAM_SCORING_HPF',
		'AWAY_TEAM_DEFENSE_HPF',
		'HOME_TEAM_REBOUNDERS_HPF',
		'HOME_TEAM_SCORING_HPF',
		'HOME_TEAM_DEFENSE_HPF'
	];

	let results, ircResults, hpfResults, rawCrawlerResults, convertedCrawlerResults, teamResults, schedulesResult, heatmapResult;

	if (adminDashboardSearch?.info) {
		results = adminDashboardSearch?.info[0];
		for (const property in results) {
			columns.push({
				dataField: property,
				text: property.toUpperCase(),
				sort: true,
				hidden: showColumn?.includes(property?.toUpperCase()) ? false : true
			});
		}
	}
	if (adminDashboardSearch?.ircTable) {
		ircResults = adminDashboardSearch?.ircTable[0];
		for (const property in ircResults) {
			ircColumns.push({
				dataField: property,
				text: property.toUpperCase(),
				sort: true
			});
		}
	}
	if (adminDashboardSearch?.hpfTable) {
		hpfResults = adminDashboardSearch?.hpfTable[0];
		for (const property in hpfResults) {
			hpfColumns.push({
				dataField: property,
				text: property.toUpperCase(),
				sort: true
			});
		}
	}
	if (adminDashboardSearch?.rawCrawler) {
		rawCrawlerResults = adminDashboardSearch?.rawCrawler[0];
		for (const property in rawCrawlerResults) {
			rawColumns.push({
				dataField: property,
				text: property.toUpperCase(),
				sort: true
			});
		}
	}
	if (adminDashboardSearch?.convertedCrawler) {
		convertedCrawlerResults = adminDashboardSearch?.convertedCrawler[0];
		for (const property in convertedCrawlerResults) {
			convertedColumns.push({
				dataField: property,
				text: property.toUpperCase(),
				sort: true
			});
		}
	}
	if (adminDashboardSearch?.teamTable) {
		teamResults = adminDashboardSearch?.teamTable[0];
		for (const property in teamResults) {
			teamColumns.push({
				dataField: property,
				text: property.toUpperCase(),
				sort: true
			});
		}
	}
	if (adminDashboardSearch?.schedulesTable) {
		schedulesResult = adminDashboardSearch?.schedulesTable[0];
		for (const property in schedulesResult) {
			schedulesColumns.push({
				dataField: property,
				text: property.toUpperCase(),
				sort: true,
				hidden: showSchedulesColumn?.includes(property?.toUpperCase()) ? false : true
			});
		}
	}
	if (adminDashboardSearch?.heatmapTable) {
		heatmapResult = adminDashboardSearch?.heatmapTable[0];
		for (const property in heatmapResult) {
			heatmapColumns.push({
				dataField: property,
				text: property.toUpperCase(),
				sort: true
			});
		}
	}

	if (adminDashboardOptions?.isLoading || adminDashboardSearch?.isLoading || adminDashboardCSV.isLoading || adminDashboardCalculations?.isLoading) {
		return (
			<div id='fullHeight'>
				<div id='loading' />
			</div>
		);
	}
	let teamOptions = adminDashboardOptions?.data?.teams;
	let positionOptions = adminDashboardOptions?.data?.positions;
	let idOptions = [];

	if (adminDashboardSearch?.info) {
		adminDashboardSearch?.info?.map((x) =>
			idOptions.push({
				label: x.id,
				value: x.id
			})
		);
	}

	if (adminDashboardSearch?.sportsID) {
		teamOptions = adminDashboardOptions?.data?.teams?.filter((x) => x.sportsID === adminDashboardSearch?.sportsID);
		positionOptions = adminDashboardOptions?.data?.positions?.filter((x) => x.sportsID === adminDashboardSearch?.sportsID);
	}

	if (adminDashboardSearch?.info?.length) {
		// dispatch(
		//   actions.change(
		//     "adminDashboardSearch.insert.playerID",
		//     adminDashboardSearch?.info[0].player_id
		//   )
		// );
	}

	let usersColumns = [],
		usersData = [];

	if (adminDashboardOptions?.data?.users) {
		const userColumnsObject = adminDashboardOptions.data.users[0];
		for (const property in userColumnsObject) {
			usersColumns.push({ id: property, displayName: property });
		}
		usersData = adminDashboardOptions.data.users;
	}

	return (
		<div className='adminDiv'>
			{(adminDashboardSearch?.success || adminDashboardSearch?.error) && (
				<div style={{ display: 'flex' }}>
					<Alert className='statusLabel successLabel text-center hideDiv'>{adminDashboardSearch?.message}</Alert>
				</div>
			)}
			{(adminDashboardCalculations?.success || adminDashboardCalculations?.error) && (
				<div style={{ display: 'flex' }}>
					<Alert className='statusLabel successLabel text-center hideDiv'>{adminDashboardCalculations?.message}</Alert>
				</div>
			)}
			<div className='adminHeaderDiv'>
				<h1 className='secondaryFontBold'>Admin Dashboard</h1>
			</div>
			<hr />
			<Row>
				<Col lg={12}>
					<h1 className='secondaryFontBold'>Download Users</h1>
					<CsvDownloader className='generalButton hover-delay' filename='users' extension='.csv' separator=',' wrapColumnChar='' columns={usersColumns} datas={usersData} text='DOWNLOAD' />
					{/* eslint-disable-next-line react/jsx-pascal-case */}
					{/* <Button
            href="#/"
            type="submit"
            className="generalButton hover-delay"
            onClick={() => downloadCSV("users")}
          ></Button> */}
				</Col>
			</Row>
			<hr />
			<AdminOverrides />
			<Form model='adminDashboardSearch' onSubmit={() => handleSearch()}>
				<Row className='adminDashboardRow'>
					<h1 className='text-center'>Search</h1>
					<Col lg={3} className='adminDashboardCol'>
						<p className='loginLabel'>
							Choose Sport
							<span style={{ color: 'red', fontWeight: '900' }}>*</span>
						</p>
						<InputFields
							// label="Sport"
							type='select'
							inputType='select'
							defaultOption='Select a sport'
							options={[
								{ label: 'NFL', value: 1 },
								{ label: 'NBA', value: 2 },
								{ label: 'SOCCER', value: 3 }
							]}
							model='.sportsID'
							className='loginInput hoverDelay'
							ignore={true}
						/>
					</Col>
					<Col lg={3} className='adminDashboardCol'>
						<p className='loginLabel'>Player Name</p>
						<InputFields
							// label="Player Name"
							type='text'
							inputType='text'
							model='.playerName'
							className='loginInput hoverDelay'
							ignore={true}
							value={adminDashboardSearch?.playerName || null}
						/>
					</Col>
					<Col lg={3} className='adminDashboardCol'>
						<p className='loginLabel'>Team Names</p>
						{teamOptions && (
							<InputFields
								// label="Team"
								type='select'
								inputType='select'
								defaultOption='Select a team'
								options={teamOptions}
								model='.teamId'
								className='loginInput hoverDelay'
								ignore={true}
							/>
						)}
					</Col>
					<Col lg={3} className='adminDashboardCol'>
						<p className='loginLabel'>Position</p>
						{positionOptions && (
							<InputFields
								// label="Position"
								type='select'
								inputType='select'
								defaultOption='Select position'
								options={positionOptions}
								model='.position'
								className='loginInput hoverDelay'
								ignore={true}
							/>
						)}
					</Col>
				</Row>
				<Row className='adminDashboardButtonRow'>
					<Col lg={3} className='adminDashboardCol'>
						{/* eslint-disable-next-line react/jsx-pascal-case */}
						<Control.button href='#/' model='adminDashboardSearch' type='submit' className='generalButton adminButton hover-delay' disabled={!adminDashboardSearch?.sportsID}>
							SEARCH
						</Control.button>
					</Col>
				</Row>
			</Form>
			{/* --------------------------------------------------------------------------------------------------- */}
			{/* --------------------------------------------------------------------------------------------------- */}
			{/* ------------------------------------ MAKE CHANGES SECTION ----------------------------------------- */}
			{/* --------------------------------------------------------------------------------------------------- */}
			{/* --------------------------------------------------------------------------------------------------- */}
			{adminDashboardSearch?.info?.length ? (
				<Form model='adminDashboardSearch.updates' onSubmit={() => handleChanges()}>
					<Row className='adminDashboardRow'>
						<h1 className='text-center'>UPDATE</h1>
						<Col lg={3} className='adminDashboardCol'>
							<p className='loginLabel'>
								ID
								<span style={{ color: 'red', fontWeight: '900' }}>*</span>
							</p>
							<InputFields
								// label="ID"
								type='select'
								inputType='select'
								defaultOption='Select an ID'
								options={idOptions}
								model='.id'
								className='loginInput hoverDelay'
								ignore={true}
							/>
						</Col>
						<Col lg={3} className='adminDashboardCol'>
							<p className='loginLabel'>Injury Grade</p>
							<InputFields
								// label="Injury Grade"
								type='select'
								inputType='select'
								defaultOption='Select an injury grade'
								options={[
									{ label: 0.5, value: 0.5 },
									{ label: 1, value: 1 },
									{ label: 2, value: 2 },
									{ label: 3, value: 3 },
									{ label: 4, value: 4 }
								]}
								model='.injuryGrade'
								className='loginInput hoverDelay'
								ignore={true}
							/>
						</Col>
						{adminDashboardOptions?.data?.bodyParts && (
							<Col lg={3} className='adminDashboardCol'>
								<p className='loginLabel'>Body Part</p>
								<InputFields
									// label="Body Parts"
									type='select'
									inputType='select'
									defaultOption='Select a body part'
									options={adminDashboardOptions?.data?.bodyParts}
									model='.bodyPart'
									className='loginInput hoverDelay'
									ignore={true}
								/>
							</Col>
						)}
						<Col lg={3} className='adminDashboardCol'>
							<p className='loginLabel'>Injury Date</p>
							<DatePicker className='loginInput hoverDelay' selected={startDate} maxDate={new Date()} onChange={handleDateChange} />
						</Col>
						<Col lg={3} className='adminDashboardCol'>
							<p className='loginLabel'>New Injury</p>
							<InputFields
								// label="New Injury"
								type='select'
								inputType='select'
								defaultOption='New Injury?'
								options={[
									{ label: 'True', value: 1 },
									{ label: 'False', value: 0 }
								]}
								model='.newInjury'
								className='loginInput hoverDelay'
								ignore={true}
							/>
						</Col>
					</Row>
					<Row className='adminDashboardButtonRow'>
						<Col lg={3} className='adminDashboardCol'>
							{/* eslint-disable-next-line react/jsx-pascal-case */}
							<Control.button href='#/' model='adminDashboardSearch.updates' type='submit' className='generalButton hover-delay' disabled={!adminDashboardSearch?.updates?.id}>
								SUBMIT CHANGES
							</Control.button>
						</Col>
					</Row>
				</Form>
			) : null}
			{/* --------------------------------------------------------------------------------------------------- */}
			{/* --------------------------------------------------------------------------------------------------- */}
			{/* ------------------------------------ INSERT NEW INJURY SECTION ------------------------------------ */}
			{/* --------------------------------------------------------------------------------------------------- */}
			{/* --------------------------------------------------------------------------------------------------- */}
			{adminDashboardSearch?.info?.length ? (
				<Form model='adminDashboardSearch.insert' onSubmit={() => handleInsert()}>
					<Row className='adminDashboardRow'>
						<h1 className='text-center'>INSERT</h1>
						<Col lg={3} className='adminDashboardCol'>
							<p className='loginLabel'>
								Player ID
								<span style={{ color: 'red', fontWeight: '900' }}>*</span>
							</p>
							<InputFields
								// label="ID"
								type='text'
								inputType='text'
								defaultOption='Select an ID'
								options={idOptions}
								model='.player_id'
								className='loginInput hoverDelay'
								disabled={true}
								value={adminDashboardSearch?.info[0]?.player_id}
								ignore={true}
							/>
						</Col>
						<Col lg={3} className='adminDashboardCol'>
							<p className='loginLabel'>Injury Grade</p>
							<InputFields
								// label="Injury Grade"
								type='select'
								inputType='select'
								defaultOption='Select an injury grade'
								options={[
									{ label: 0.5, value: 0.5 },
									{ label: 1, value: 1 },
									{ label: 2, value: 2 },
									{ label: 3, value: 3 },
									{ label: 4, value: 4 }
								]}
								model='.injuryGrade'
								className='loginInput hoverDelay'
								ignore={true}
							/>
						</Col>
						{adminDashboardOptions?.data?.bodyParts && (
							<Col lg={3} className='adminDashboardCol'>
								<p className='loginLabel'>Body Part</p>
								<InputFields
									// label="Body Parts"
									type='select'
									inputType='select'
									defaultOption='Select a body part'
									options={adminDashboardOptions?.data?.bodyParts}
									model='.bodyPart'
									className='loginInput hoverDelay'
									ignore={true}
								/>
							</Col>
						)}
						<Col lg={3} className='adminDashboardCol'>
							<p className='loginLabel'>Injury Date</p>
							<DatePicker
								className='loginInput hoverDelay'
								selected={startDate}
								maxDate={new Date()}
								onChange={handleDateChangeInsert}
								// required
							/>
						</Col>
					</Row>
					<Row className='adminDashboardButtonRow'>
						<Col lg={3} className='adminDashboardCol'>
							{/* eslint-disable-next-line react/jsx-pascal-case */}
							<Control.button
								href='#/'
								model='adminDashboardSearch.insert'
								type='submit'
								className='generalButton hover-delay'
								// disabled={{ valid: false }}
							>
								SUBMIT NEW INJURY
							</Control.button>
						</Col>
					</Row>
				</Form>
			) : null}
			<hr className='hrStyle' />
			<h1 className='text-center'>Run Calculations</h1>
			<Row className='calculationsRow'>
				<Col lg={6}>
					<Button
						href='#/'
						model='adminDashboardSearch.insert'
						type='submit'
						className='generalButton hover-delay adminCalculationsButton1'
						onClick={() =>
							dispatch({
								type: 'RUN_CALCULATIONS',
								payload: { player: false }
							})
						}
						style={{ width: '300px', margin: '-30px auto auto auto' }}
						// disabled={{ valid: false }}
					>
						RUN IRC/HPF CALCULATIONS
					</Button>
				</Col>
				<Col lg={6}>
					<Button
						href='#/'
						model='adminDashboardSearch.insert'
						type='submit'
						className='generalButton hover-delay adminCalculationsButton2'
						onClick={() =>
							dispatch({
								type: 'RUN_CALCULATIONS',
								payload: { player: true }
							})
						}
						style={{ width: '300px', margin: '-30px auto auto auto' }}
						// disabled={{ valid: false }}
					>
						RUN PLAYER IRC/HPF CALCULATIONS
					</Button>
				</Col>
			</Row>
			<hr className='hrStyle' />
			<Row className='adminDashboardTablesRow'>
				<h1 className='text-center'>Show Tables</h1>
				{adminDashboardSearch?.ircTable?.length ? (
					<Col lg={3} className='showTablesCol'>
						<Button
							href='#/'
							type='submit'
							className={`adminButton ${!hideIrc ? 'adminButtonActive' : ''} hover-delay`}
							onClick={() => handleHideIrc(!hideIrc)}
							// disabled={{ valid: false }}
						>
							SHOW IRC TABLE
						</Button>
					</Col>
				) : null}
				{adminDashboardSearch?.hpfTable?.length ? (
					<Col lg={3} className='showTablesCol'>
						<Button
							href='#/'
							type='submit'
							className={`adminButton ${!hideHpf ? 'adminButtonActive' : ''} hover-delay`}
							onClick={() => handleHideHpf(!hideHpf)}
							// disabled={{ valid: false }}
						>
							SHOW HPF TABLE
						</Button>
					</Col>
				) : null}
				{adminDashboardSearch?.rawCrawler?.length ? (
					<Col lg={3} className='showTablesCol'>
						<Button
							href='#/'
							type='submit'
							className={`adminButton ${!hideRaw ? 'adminButtonActive' : ''} hover-delay`}
							onClick={() => handleHideRaw(!hideRaw)}
							// disabled={{ valid: false }}
						>
							SHOW RAW CRAWLER TABLE
						</Button>
					</Col>
				) : (
					''
				)}
				{adminDashboardSearch?.convertedCrawler?.length ? (
					<Col lg={3} className='showTablesCol'>
						<Button
							href='#/'
							type='submit'
							className={`adminButton ${!hideConverted ? 'adminButtonActive' : ''} hover-delay`}
							onClick={() => handleHideConverted(!hideConverted)}
							// disabled={{ valid: false }}
						>
							SHOW CONVERTED CRAWLER TABLE
						</Button>
					</Col>
				) : null}
				{adminDashboardSearch?.teamTable?.length ? (
					<Col lg={3} className='showTablesCol'>
						<Button
							href='#/'
							type='submit'
							className={`adminButton ${!hideTeam ? 'adminButtonActive' : ''} hover-delay`}
							onClick={() => handleHideTeam(!hideTeam)}
							// disabled={{ valid: false }}
						>
							SHOW TEAM TABLE
						</Button>
					</Col>
				) : (
					''
				)}
				{adminDashboardSearch?.schedulesTable?.length ? (
					<Col lg={3} className='showTablesCol'>
						<Button
							href='#/'
							type='submit'
							className={`adminButton ${!hideSchedules ? 'adminButtonActive' : ''} hover-delay`}
							onClick={() => handleHideSchedules(!hideSchedules)}
							// disabled={{ valid: false }}
						>
							SHOW SCHEDULES TABLE
						</Button>
					</Col>
				) : null}
				{adminDashboardSearch?.heatmapTable?.length ? (
					<Col lg={3} className='showTablesCol'>
						<Button
							href='#/'
							type='submit'
							className={`adminButton ${!hideHeatmap ? 'adminButtonActive' : ''} hover-delay`}
							onClick={() => handleHideHeatmap(!hideHeatmap)}
							// disabled={{ valid: false }}
						>
							SHOW IRC/HPF HEATMAP TABLE
						</Button>
					</Col>
				) : null}
			</Row>
			{ircColumns?.length > 0 && !adminDashboardSearch?.error && !hideIrc && (
				<Row className='adminDashboardTableRow'>
					<>
						<h1 className='text-center'>IRC TABLE</h1>
						<Col lg={12} className='adminDashboardTableCol'>
							<ToolkitProvider keyField='id' className='adminDashboardTable' data={adminDashboardSearch?.ircTable} columns={ircColumns} columnToggle>
								{(props) => (
									<div>
										<ToggleList {...props.columnToggleProps} />
										<hr />
										<BootstrapTable {...props.baseProps} />
									</div>
								)}
							</ToolkitProvider>
						</Col>
					</>
				</Row>
			)}
			{hpfColumns?.length > 0 && !adminDashboardSearch?.error && !hideHpf && (
				<Row className='adminDashboardTableRow'>
					<>
						<h1 className='text-center'>HPF TABLE</h1>
						<Col lg={12} className='adminDashboardTableCol'>
							<ToolkitProvider keyField='id' className='adminDashboardTable' data={adminDashboardSearch?.hpfTable} columns={hpfColumns} columnToggle>
								{(props) => (
									<div>
										<ToggleList {...props.columnToggleProps} />
										<hr />
										<BootstrapTable {...props.baseProps} />
									</div>
								)}
							</ToolkitProvider>
						</Col>
					</>
				</Row>
			)}
			{rawColumns?.length > 0 && !adminDashboardSearch?.error && !hideRaw && (
				<Row className='adminDashboardTableRow'>
					<>
						<h1 className='text-center'>RAW CRAWLER TABLE</h1>
						<Col lg={12} className='adminDashboardTableCol'>
							<ToolkitProvider keyField='id' className='adminDashboardTable' data={adminDashboardSearch?.rawCrawler} columns={rawColumns} columnToggle>
								{(props) => (
									<div>
										<ToggleList {...props.columnToggleProps} />
										<hr />
										<BootstrapTable {...props.baseProps} />
									</div>
								)}
							</ToolkitProvider>
						</Col>
					</>
				</Row>
			)}
			{convertedColumns?.length > 0 && !adminDashboardSearch?.error && !hideConverted && (
				<Row className='adminDashboardTableRow'>
					<>
						<h1 className='text-center'>CONVERTED CRAWLER TABLE</h1>
						<Col lg={12} className='adminDashboardTableCol'>
							<ToolkitProvider keyField='id' className='adminDashboardTable' data={adminDashboardSearch?.convertedCrawler} columns={convertedColumns} columnToggle>
								{(props) => (
									<div>
										<ToggleList {...props.columnToggleProps} />
										<hr />
										<BootstrapTable {...props.baseProps} />
									</div>
								)}
							</ToolkitProvider>
						</Col>
					</>
				</Row>
			)}
			{teamColumns?.length > 0 && !adminDashboardSearch?.error && !hideTeam && (
				<Row className='adminDashboardTableRow'>
					<>
						<h1 className='text-center'>TEAM TABLE</h1>
						<Col lg={12} className='adminDashboardTableCol'>
							<ToolkitProvider keyField='id' className='adminDashboardTable' data={adminDashboardSearch?.teamTable} columns={teamColumns} columnToggle>
								{(props) => (
									<div>
										<ToggleList {...props.columnToggleProps} />
										<hr />
										<BootstrapTable {...props.baseProps} />
									</div>
								)}
							</ToolkitProvider>
						</Col>
					</>
				</Row>
			)}
			{schedulesColumns?.length > 0 && !adminDashboardSearch?.error && !hideSchedules && (
				<Row className='adminDashboardTableRow'>
					<>
						<h1 className='text-center'>SCHEDULES TABLE</h1>
						<Col lg={12} className='adminDashboardTableCol'>
							<ToolkitProvider keyField='id' className='adminDashboardTable' data={adminDashboardSearch?.schedulesTable} columns={schedulesColumns} columnToggle>
								{(props) => (
									<div>
										<ToggleList {...props.columnToggleProps} />
										<hr />
										<BootstrapTable {...props.baseProps} />
									</div>
								)}
							</ToolkitProvider>
						</Col>
					</>
				</Row>
			)}
			{heatmapColumns?.length > 0 && !adminDashboardSearch?.error && !hideHeatmap && (
				<Row className='adminDashboardTableRow'>
					<>
						<h1 className='text-center'>IRC/HPF HEATMAP TABLE</h1>
						<Col lg={12} className='adminDashboardTableCol'>
							<ToolkitProvider keyField='id' className='adminDashboardTable' data={adminDashboardSearch?.heatmapTable} columns={heatmapColumns} columnToggle>
								{(props) => (
									<div>
										<ToggleList {...props.columnToggleProps} />
										<hr />
										<BootstrapTable {...props.baseProps} />
									</div>
								)}
							</ToolkitProvider>
						</Col>
					</>
				</Row>
			)}
			<Row className='adminDashboardTableRow'>
				{columns?.length > 0 && !adminDashboardSearch?.error ? (
					<>
						<h1 className='text-center'>MAIN INJURY HISTORY TABLE</h1>
						<p className='text-center' style={{ fontSize: '18px' }}>
							<b>IRC</b>: {adminDashboardSearch?.details[0]?.irc_total}
							<br />
							<b>HPF</b>: {adminDashboardSearch?.details[0]?.hpf_total}
							<br />
							<b>CIS</b>: {adminDashboardSearch?.details[0]?.cis_total}
							<br />
							{adminDashboardSearch?.details[0]?.recovery_time_days && (
								<>
									<b>OPTIMAL RECOVERY TIME DAYS</b>: {adminDashboardSearch?.details[0]?.recovery_time_days}
									<br />
								</>
							)}
							{adminDashboardSearch?.details[0]?.days_past_optimal_recovery_time && (
								<>
									<b>DAYS PAST OPTIMAL RECOVERY TIME</b>: {adminDashboardSearch?.details[0]?.days_past_optimal_recovery_time}
									<br />
								</>
							)}
							<b>Health Score:</b> {adminDashboardSearch?.details[0]?.gaming_score}
						</p>
						<Col lg={12} className='adminDashboardTableCol'>
							<ToolkitProvider keyField='id' className='adminDashboardTable' data={adminDashboardSearch?.info} columns={columns} columnToggle>
								{(props) => (
									<div>
										<ToggleList {...props.columnToggleProps} />
										<hr />
										<BootstrapTable {...props.baseProps} />
									</div>
								)}
							</ToolkitProvider>
						</Col>
					</>
				) : (
					<h1 className='text-center'>No Info Available</h1>
				)}
			</Row>
		</div>
	);
}

const mapStateToProps = (state) => ({
	user: state.user,
	userActions: state.userActions,
	sendemail: state.sendemail,
	adminDashboardOptions: state.adminDashboardOptions,
	adminDashboardSearch: state.adminDashboardSearch,
	adminDashboardCSV: state.adminDashboardCSV,
	adminDashboardCalculations: state.adminDashboardCalculations
});

export default connect(mapStateToProps)(Admin);
