import React, { Component } from 'react';
import './style.css';

import { Row, Col } from 'react-bootstrap';

class Legend extends Component {
	render() {
		return (
			<React.Fragment>
				<Col lg={3} className='dashboardLegendCol dashboardLegendCol2 mobileLegendCol'>
					<div className='dashboardLegendsMainDiv'>
						<div className='dashboardLegendDiv'>
							<div className='dashboardLegendsMainKeyDiv'>
								<div className='dashboardLegendKeysDiv'>
									<div className='dashboardLegendKeysColor cardSpreadDarkGreen' />
									<p className='dashboardLegendKeysText'>Optimal Health (The Edge): 7-10</p>
								</div>
								<p className='dashboardLegendKeysSubText'>Completely healthy, or close to healthy, with key players contributing at expected levels of production.</p>
								<div className='dashboardLegendKeysDiv'>
									<div className='dashboardLegendKeysColor cardSpreadGreen' />
									<p className='dashboardLegendKeysText'>Good Health (Take The Points): 4-7</p>
								</div>
								<p className='dashboardLegendKeysSubText'>Relatively healthy, resulting in contributions slightly lower than projected levels.</p>
								<div className='dashboardLegendKeysDiv'>
									<div className='dashboardLegendKeysColor cardSpreadYellow' />
									<p className='dashboardLegendKeysText'>Moderate Health (Even Money): 2-4</p>
								</div>
								<p className='dashboardLegendKeysSubText'>Some injury(ies) leading to underperformance when compared to expectations or projections.</p>
								<div className='dashboardLegendKeysDiv'>
									<div className='dashboardLegendKeysColor cardSpreadRed' />
									<p className='dashboardLegendKeysText'>Poor Health (Off The Board): 0-2</p>
								</div>
								<p className='dashboardLegendKeysSubText'>Significant injury(ies) resulting in substantial underperformance compared to expected levels of production.</p>
							</div>
						</div>
					</div>
				</Col>
				<Col lg={12} className='dashboardLegendCol dashboardLegendCol2 desktopLegendCol'>
					<div className='dashboardLegendsMainDiv'>
						<Row className='dashboardLegendsMainRow'>
							{/* <div className="dashboardLegendDiv"> */}
							<Col className='col-lg-3'>
								<div className='dashboardLegendKeysDiv'>
									<div className='dashboardLegendKeysColor cardSpreadDarkGreen' />
									<p className='dashboardLegendKeysText'>Optimal Health (The Edge): 7-10</p>
								</div>
								<p className='dashboardLegendKeysSubText'>Completely healthy, or close to healthy, with key players contributing at expected levels of production.</p>
							</Col>
							<Col className='col-lg-3'>
								<div className='dashboardLegendKeysDiv'>
									<div className='dashboardLegendKeysColor cardSpreadGreen' />
									<p className='dashboardLegendKeysText'>Good Health (Take The Points): 4-7</p>
								</div>
								<p className='dashboardLegendKeysSubText'>Relatively healthy, resulting in contributions slightly lower than projected levels.</p>
							</Col>
							<Col className='col-lg-3'>
								<div className='dashboardLegendKeysDiv'>
									<div className='dashboardLegendKeysColor cardSpreadYellow' />
									<p className='dashboardLegendKeysText'>Moderate Health (Even Money): 2-4</p>
								</div>
								<p className='dashboardLegendKeysSubText'>Some injury(ies) leading to underperformance when compared to expectations or projections.</p>
							</Col>
							<Col className='col-lg-3'>
								<div className='dashboardLegendKeysDiv'>
									<div className='dashboardLegendKeysColor cardSpreadRed' />
									<p className='dashboardLegendKeysText'>Poor Health (Off The Board): 0-2</p>
								</div>
								<p className='dashboardLegendKeysSubText'>Significant injury(ies) resulting in substantial underperformance compared to expected levels of production.</p>
							</Col>
						</Row>
					</div>
				</Col>
			</React.Fragment>
		);
	}
}

export default Legend;
