import React from 'react';
import { connect } from 'react-redux';
import './style.css';
import { Row, Col } from 'react-bootstrap';
import WhitepaperImg from './img/whitepaperImg.png';
import WhitepaperStats from './pdf/inside-injuries-final-white-paper-nfl-2022-23.pdf';
// import ReactPlayer from "react-player";
import GeneralCover from '../GeneralCover';

function Whitepaper(props) {
	const { user } = props;
	if (user.isLoading) {
		return (
			<div id='fullHeight'>
				<div id='loading' />
			</div>
		);
	}
	return (
		<div>
			<GeneralCover page='whitepaper' arrowScrollID='#whitepaperSection' customHeaderContent='Review Our Bets' />
			<section id='whitepaperSection' className='whitepaperSection'>
				<h1 className='whitepaperHeader'>Review Our Bets</h1>
				<Row className='whitepaperRow'>
					<Col lg={6}>
						<h4 className='whitepaperText'>
							Throughout the course of the 2022-23 NFL Season, Inside Injuries correctly predicted <span className='brandColor'>78% of our bets</span>. Breaking it down further, we successfully predicted the <span className='brandColor'>spread in 77.5%</span> of our bets and the <span className='brandColor'>over-under in 78.6%</span> of our bets. Review
							every pick and why we made it below.
						</h4>
					</Col>
					<Col lg={6}>
						<img src={WhitepaperImg} alt='Whitepaper Stats' className='whitepaperImg' />
						<a href={WhitepaperStats} target='_blank' rel='noreferrer' className='whitepaperButton' title='Review Our Bets'>
							REVIEW OUR BETS
						</a>
					</Col>
				</Row>
			</section>
		</div>
	);
}

const mapStateToProps = (state) => ({
	user: state.user,
	freeAccess: state.freeAccess,
	sources: state.sources,
	prices: state.prices
});

export default connect(mapStateToProps)(Whitepaper);
