import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import '../style.css';
import { Col } from 'react-bootstrap';
import TeamHealthScore from './teamHealthScores';
import TeamColumn from './teamColumns';
import { Modal } from 'react-bootstrap';
import Logo from '../../../global/img/logo_full_white.png';
import InputFields from '../../InputFields';
import { Form, Control } from 'react-redux-form';

function TeamCard(props) {
	const { user, teams, sportsName, dispatch, nbaPage } = props;
	const [showModal, setShowModal] = useState(false);

	const handleLinkClick = (e) => {
		if (!user?.login && nbaPage) {
		  e.preventDefault();
		  setShowModal(true)
		}
	};

	const handleSignup = (data) => {
		dispatch({
			type: 'SIGNUP',
			payload: data
		});
	};

	return (
		<React.Fragment>
			{teams &&
				teams.map((team, i) => (
					<Col id={team.team_id} lg={4} key={i} className='cardCol col-xl-5ths col-md-6 col-sm-6 col-xs-12' style={{ height: '300px' }}>
						<div className='cardColDiv'>
							<div className='cardSpreadDiv'>{user?.login ? <TeamHealthScore team={team} /> : <div style={{ padding: '27.5px 0' }} />}</div>
							<TeamColumn team={team} userAllowed={user?.login} sportsID={user?.sportsID} />
							<Col lg={12} className='cardViewGameCol'>
								<Link
									className='cardViewGameButton hover-delay'
									title='View Team'
									to={{
										pathname: `/${sportsName || 'nfl'}/team/${team.team_id}`
									}}
									onClick={handleLinkClick}
								>
									VIEW TEAM
								</Link>
							</Col>
						</div>
					</Col>
				))}

			<Modal show={showModal} onHide={() => setShowModal(false)} centered size='lg'>
				<Modal.Body>
					<section className='signupMainSection'>
						<div className='signupMainDiv'>
							<div className='text-center'>
								<img src={Logo} alt='Inside Injuries Logo' className='signupLogo' />
								<p className='loginFlowText'>
									Already have an account?
									<a href='/login' className='loginFlowLink ps-2'>
										Login here
									</a>
								</p>
							</div>
							<div className='signupFormDiv'>
 
								<>
								<Form model="userActions.signup" onSubmit={handleSignup}>
								<div className="inputCol">
									<InputFields model=".first_name" inputType="text" type="text" placeholder="Enter your first name" label="First Name" />
								</div>
								<div className="inputCol">
									<InputFields model=".email" inputType="text" type="email" placeholder="Enter your email" label="Email" />
								</div>
								<div className="inputCol">
									<InputFields model=".password" inputType="text" type="password" placeholder="Enter your password" label="Password" />
								</div>
								<div className="w-100">
									{/* eslint-disable-next-line react/jsx-pascal-case */}
									<Control.button model="userActions.signup" className="loginButton" type="submit">
										SIGN UP
									</Control.button>
								</div>
							</Form>
							<p className='signupHeaderText'>Join for free and get access to all injury data.</p>

								</>
								
							</div>
						</div>
					</section>
				</Modal.Body>
			</Modal>
		</React.Fragment>
	);
}

const mapStateToProps = (state) => ({
	isLoading: state.isLoading,
	isLoaded: state.isLoaded,
	schedules: state.schedules,
	match: state.match,
	user: state.user,
	purchasePlan: state.purchasePlan,
	sources: state.source
});

export default connect(mapStateToProps)(TeamCard);
