import React from 'react';
import { connect } from 'react-redux';
import './style.css';

import { Row, Col, Button } from 'react-bootstrap';
import UserHeader from '../UserHeader';
import { Event } from '../Tracking';

function SoccerUpdates(props) {
	const { user, freeAccess, sources, dispatch } = props;
	const title = `Soccer Updates`;

	const unlockUpdates = user?.login;

	return (
		<div>
			<div className='desktopUserheader'>{user?.name ? <UserHeader username={user.name} title={title} /> : null}</div>
			<Row className='updatesRow container-fluid'>
				<Col lg={12}>
					<h1 className='updatesHeader'>Recent Player Updates</h1>
				</Col>
				<Col lg={12} style={{ textAlign: 'center' }}>
					<div className={!unlockUpdates ? 'blurDiv' : ''} />
					<div className='iframeDiv'>
						{/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
						<iframe className='updatesWindow responsive-iframe' src='https://docs.google.com/document/d/e/2PACX-1vTnip5JZecvvUoha-5fPeGFF86GWsTeg0McW4tmnTaPrMLxXptS4JnmvX5a879J8ltOdBKnkoNhq1bH/pub?embedded=true' frameBorder='0' scrolling={!unlockUpdates ? 'no' : 'yes'}></iframe>
						{!unlockUpdates ? (
							<div className='updatesSignupDiv'>
								<p className='updatesSignupText'>Please sign up or sign in to view player updates</p>
								<Button
									className='updatesSignupButton'
									onClick={() => {
										if (!freeAccess?.valid) {
											this.setState({ loginShow: true });
										} else {
											this.setState({ freeAccessShow: true });
										}
										dispatch({ type: 'AUDIT', payload: { action: 'SIGNUP' } });
										Event(sources?.source, 'CLICK_SIGNUP', 'DASHBOARD_SIGNUP_BUTTON');
									}}
								>
									SIGN UP
								</Button>
							</div>
						) : null}
					</div>
				</Col>
			</Row>
		</div>
	);
}

const mapStateToProps = (state) => ({
	freeAccess: state.freeAccess,
	user: state.user,
	sources: state.sources
});

export default connect(mapStateToProps)(SoccerUpdates);
