import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
// import thunk from "redux-thunk";
import rootReducer from './reducer';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// import storageSession from 'redux-persist/lib/storage/session'
import rootSaga from './saga';
// import { logger } from "redux-logger";
require('dotenv').config();

const persistConfig = {
	key: 'authType',
	storage: storage,
	whitelist: ['user', 'freeAccess', 'prices', 'sources'] // which reducer want to store
};

const pReducer = persistReducer(persistConfig, rootReducer);
const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];
// const withDevTools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const withDevTools = (process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

// const store = createStore(
//   rootReducer,
//   applyMiddleware(sagaMiddleware, logger),
//   withDevTools(applyMiddleware(...middleware))
// );

const store = createStore(
	pReducer,
	withDevTools(
		// applyMiddleware(sagaMiddleware, logger),
		applyMiddleware(sagaMiddleware),
		applyMiddleware(...middleware)
	)
);

sagaMiddleware.run(rootSaga);

const persistor = persistStore(store);

export { persistor, store };

// const action = type => store.dispatch({ type });
