import { sortArrayOfObj, monthDifference } from '../utils/utils';

export function highlightColumn(pointSpread, injurySpread) {
	if (pointSpread > 0) {
		if (injurySpread > 0) {
			if (pointSpread > injurySpread) {
				return 'home';
			} else {
				return 'away';
			}
		} else {
			return 'home';
		}
	} else if (pointSpread > injurySpread) {
		return 'home';
	} else {
		return 'away';
	}
}

export function highlightColumnColor(percent, highlightClass, type, strokeColor) {
	if (type === 'teamhealth' || !type) {
		if (percent >= 7 || !percent) {
			return !strokeColor ? `${highlightClass}DarkGreen` : '#04B876';
		} else if (percent < 7 && percent >= 4) {
			return !strokeColor ? `${highlightClass}Green` : '#74ad06';
		} else if (percent < 4 && percent >= 2) {
			return !strokeColor ? `${highlightClass}Yellow` : '#d6c605';
		} else {
			return !strokeColor ? `${highlightClass}Red` : '#c71717';
		}
	} else if (type === 'playerhealth') {
		if (percent >= 7 || !percent) {
			return !strokeColor ? `${highlightClass}DarkGreen` : '#04B876';
		} else if (percent < 7 && percent >= 4) {
			return !strokeColor ? `${highlightClass}Green` : '#74ad06';
		} else if (percent < 4 && percent >= 2) {
			return !strokeColor ? `${highlightClass}Yellow` : '#d6c605';
		} else {
			return !strokeColor ? `${highlightClass}Red` : '#c71717';
		}
	} else if (type === 'irc') {
		if (percent >= 0 && percent < 14) {
			return !strokeColor ? `${highlightClass}Green` : '#74ad06';
		} else if (percent >= 14 && percent < 25) {
			return !strokeColor ? `${highlightClass}Yellow` : '#d6c605';
		} else {
			return !strokeColor ? `${highlightClass}Red` : '#c71717';
		}
	} else if (type === 'hpf') {
		if (percent >= 81 && percent <= 100) {
			return !strokeColor ? `${highlightClass}Green` : '#74ad06';
		} else if (percent >= 66 && percent < 81) {
			return !strokeColor ? `${highlightClass}DarkGreen` : '#04B876';
		} else if (percent >= 41 && percent < 66) {
			return !strokeColor ? `${highlightClass}Yellow` : '#d6c605';
		} else {
			return !strokeColor ? `${highlightClass}Red` : '#c71717';
		}
	}
}

export function checkHealthScoreStatus(percent, categories, type) {
	if (type === 'player' || type === 'team' || !type) {
		if (percent >= 7) {
			return {
				status: 'OPTIMAL',
				background: 'cardSpreadDarkGreen',
				players: categories
			};
		} else if (percent < 7 && percent >= 4) {
			return {
				status: 'GOOD',
				background: 'cardSpreadGreen',
				players: categories
			};
		} else if (percent < 4 && percent >= 2) {
			return {
				status: 'MODERATE',
				background: 'cardSpreadYellow',
				players: categories
			};
		} else {
			return {
				status: 'POOR',
				background: 'cardSpreadRed',
				players: categories
			};
		}
	}
}

export function getPlayerCategories(playerArr, type, healthScores, team) {
	let categories;
	if (type === 'offensiveskills') {
		categories = playerArr.filter(
			(x) =>
				// Comment out for now to include all players in the position group
				// (x.depth_chart_order === 1 ||
				// x.status.toUpperCase() === "INJURED RESERVE") &&
				x.position === 'QB' || x.position === 'WR' || x.position === 'RB' || x.position === 'TE'
		);
		categories = categories.sort((a, b) => (a.depth_chart_order < b.depth_chart_order ? 1 : b.depth_chart_order < a.depth_chart_order ? -1 : 0));
		return checkHealthScoreStatus(team?.offensive_skills_avg_rounded, categories);
	} else if (type === 'offensiveline') {
		categories = playerArr.filter(
			(x) =>
				// Comment out for now to include all players in the position group
				// (x.depth_chart_order === 1 ||
				//   x.status.toUpperCase() === "INJURED RESERVE") &&
				x.position === 'G' || x.position === 'C' || x.position === 'T'
		);
		categories = categories.sort((a, b) => (a.depth_chart_order < b.depth_chart_order ? 1 : b.depth_chart_order < a.depth_chart_order ? -1 : 0));
		return checkHealthScoreStatus(team?.offensive_line_avg_rounded, categories);
	} else if (type === 'defensiveline') {
		categories = playerArr.filter(
			(x) =>
				// Comment out for now to include all players in the position group
				// (x.depth_chart_order === 1 ||
				//   x.status.toUpperCase() === "INJURED RESERVE") &&
				x.position === 'DT' || x.position === 'LB' || x.position === 'OLB' || x.position === 'MLB' || x.position === 'DE'
		);
		categories = categories.sort((a, b) => (a.depth_chart_order < b.depth_chart_order ? 1 : b.depth_chart_order < a.depth_chart_order ? -1 : 0));
		return checkHealthScoreStatus(team?.defensive_line_avg_rounded, categories);
	} else if (type === 'defensivesecondary') {
		categories = playerArr.filter(
			(x) =>
				// Comment out for now to include all players in the position group
				// (x.depth_chart_order === 1 ||
				//   x.status.toUpperCase() === "INJURED RESERVE") &&
				x.position === 'SS' || x.position === 'FS' || x.position === 'CB'
		);
		categories = categories.sort((a, b) => (a.depth_chart_order < b.depth_chart_order ? 1 : b.depth_chart_order < a.depth_chart_order ? -1 : 0));
		return checkHealthScoreStatus(team?.defensive_secondary_avg_rounded, categories);
	} else if (type === 'rebounders') {
		categories = [];
		return checkHealthScoreStatus(team?.rebounders_avg_adjusted_nba, categories);
	} else if (type === 'scorers') {
		categories = [];
		return checkHealthScoreStatus(team?.scoring_avg_adjusted_nba, categories);
	} else if (type === 'defense') {
		categories = [];
		return checkHealthScoreStatus(team?.defense_avg_adjusted_nba, categories);
	} else {
		return categories;
	}
}

// Unused Function
export function getHealthScoreAvg(playerArr, type, healthScores, avg) {
	let playerAvg = avg;
	if (!playerAvg) {
		playerAvg = playerArr.reduce(function (previousValue, currentValue) {
			return previousValue + currentValue.gaming_score;
		}, 0);
		playerAvg = playerAvg / playerArr.length;
	}
	let maxValue, minValue;
	if (!healthScores) {
		return 0;
	}
	const scoreType = healthScores?.categoryAverages;
	if (scoreType) {
		if (type === 'offensiveskills') {
			maxValue = scoreType.offensiveSkills.max;
			minValue = scoreType.offensiveSkills.min;
		} else if (type === 'offensiveline') {
			maxValue = scoreType.offensiveLine.max;
			minValue = scoreType.offensiveLine.min;
		} else if (type === 'defensiveline') {
			maxValue = scoreType.defensiveLine.max;
			minValue = scoreType.defensiveLine.min;
		} else if (type === 'defensivesecondary') {
			maxValue = scoreType.defensiveSecondary.max;
			minValue = scoreType.defensiveSecondary.min;
		} else if (type === 'rebounders') {
			maxValue = scoreType?.rebounders?.max;
			minValue = scoreType?.rebounders?.min;
		} else if (type === 'scorers') {
			maxValue = scoreType?.scorers?.max;
			minValue = scoreType?.scorers?.min;
		} else if (type === 'defense') {
			maxValue = scoreType?.defense?.max;
			minValue = scoreType?.defense?.min;
		}
	} else {
		return;
	}
	const range = maxValue - minValue;
	playerAvg = ((range - (maxValue - playerAvg)) / range) * 10;
	return playerAvg.toFixed(2);
}

export function sortPlayersByGamingScore(players) {

	let sortedPlayers = players;
	let qb, wr, rb, te;

	for (let i = 0; i < sortedPlayers.length; i++) {
		if (sortedPlayers[i].depth_chart_order) {
			if (sortedPlayers[i].position === 'QB' && (!qb || (qb && qb.depth_chart_order > sortedPlayers[i].depth_chart_order))) {
				qb = sortedPlayers[i];
			} else if (sortedPlayers[i].position === 'WR' && (!wr || (wr && wr.depth_chart_order > sortedPlayers[i].depth_chart_order))) {
				wr = sortedPlayers[i];
			} else if (sortedPlayers[i].position === 'RB' && (!rb || (rb && rb.depth_chart_order > sortedPlayers[i].depth_chart_order))) {
				rb = sortedPlayers[i];
			} else if (sortedPlayers[i].position === 'TE' && (!te || (te && te.depth_chart_order > sortedPlayers[i].depth_chart_order))) {
				te = sortedPlayers[i];
			}
		}
	}
	sortedPlayers = [];
	sortedPlayers.push(qb);
	sortedPlayers.push(wr);
	sortedPlayers.push(rb);
	sortedPlayers.push(te);
	return sortedPlayers;
}

export function sortTopNBAPlayers(players) {
	let sortedPlayers = players;
	let ppg, ppg_2, rpg, assists;
	let playerName = [];
	// 1. Top Scorer (PPG)
	// 2. Top rebounder (RPG)
	// 3. Top assists (APG)
	// 4. 2nd scorer

	sortedPlayers.sort(sortArrayOfObj('ppg_avg', 'desc'));
	sortedPlayers.map((x) => {
		if (!ppg || ppg < x.ppg_avg) {
			ppg = x;
			playerName.push(x.nickname || x.player_name);
		}
	});
	sortedPlayers.map((x) => {
		if (!playerName.includes(x.nickname || x.player_name) && (!ppg_2 || ppg_2 < x.ppg_avg)) {
			ppg_2 = x;
			playerName.push(x.nickname || x.player_name);
		}
	});
	sortedPlayers.sort(sortArrayOfObj('rpg_avg', 'desc'));
	sortedPlayers.map((x) => {
		if (!playerName.includes(x.nickname || x.player_name) && (!rpg || rpg < x.rpg_avg)) {
			rpg = x;
			playerName.push(x.nickname || x.player_name);
		}
	});
	sortedPlayers.sort(sortArrayOfObj('assists_avg', 'desc'));
	sortedPlayers.map((x) => {
		if (!playerName.includes(x.nickname || x.player_name) && (!assists || assists < x.assists_avg)) {
			assists = x;
			playerName.push(x.nickname || x.player_name);
		}
	});

	sortedPlayers = [];
	sortedPlayers.push(ppg);
	sortedPlayers.push(ppg_2);
	sortedPlayers.push(rpg);
	sortedPlayers.push(assists);
	return sortedPlayers;
}

export function sortTopSoccerPlayers(players) {
	let sortedPlayers = players;
	let scorer, scorer_2, assists, starting_goalie;
	let playerName = [];
	// 1. Starting Goalie
	// 2. Top Goal Scorer
	// 3. Second Goal Scorer
	// 4. Top Assists

	sortedPlayers.sort(sortArrayOfObj('soccer_goals_avg', 'desc'));
	sortedPlayers.map((x) => {
		if (!scorer || scorer < x.soccer_goals_avg) {
			scorer = x;
			playerName.push(x.nickname || x.player_name);
		}
	});
	sortedPlayers.map((x) => {
		if (!playerName.includes(x.nickname || x.player_name) && (!scorer_2 || scorer_2 < x.soccer_goals_avg)) {
			scorer_2 = x;
			playerName.push(x.nickname || x.player_name);
		}
	});
	sortedPlayers.sort(sortArrayOfObj('soccer_assists_avg', 'desc'));
	sortedPlayers.map((x) => {
		if (!playerName.includes(x.nickname || x.player_name) && (!assists || assists < x.assists_avg)) {
			assists = x;
			playerName.push(x.nickname || x.player_name);
		}
	});
	sortedPlayers.sort(sortArrayOfObj('started', 'desc'));
	sortedPlayers.map((x) => {
		if (!playerName.includes(x.nickname || x.player_name) && (x.position.toUpperCase() === 'G' || x.position.toUpperCase() === 'GK') && (!starting_goalie || starting_goalie < x.started)) {
			starting_goalie = x;
			playerName.push(x.nickname || x.player_name);
		}
	});

	sortedPlayers = [];
	sortedPlayers.push(scorer);
	sortedPlayers.push(scorer_2);
	sortedPlayers.push(assists);
	sortedPlayers.push(starting_goalie);
	return sortedPlayers;
}

export function betOnGame(pointSpread, injurySpread) {
	let pointDiff;

	if (pointSpread >= 0 && injurySpread >= 0 && pointSpread > injurySpread) {
		pointDiff = pointSpread - injurySpread;
	} else if (pointSpread >= 0 && injurySpread >= 0 && pointSpread < injurySpread) {
		pointDiff = injurySpread - pointSpread;
	} else if (pointSpread < 0 && injurySpread < 0 && pointSpread > injurySpread) {
		pointDiff = pointSpread + -1 * injurySpread;
	} else if (pointSpread < 0 && injurySpread < 0 && pointSpread < injurySpread) {
		pointDiff = injurySpread + -1 * pointSpread;
	''} else if (pointSpread > 0 && injurySpread < 0 && pointSpread > 0.5) {
		pointDiff = 1;
	} else if (pointSpread < 0 && injurySpread > 0 && injurySpread > 0.5) {
		pointDiff = 1;
	} else if (pointSpread > 0 && injurySpread < 0) {
		pointDiff = injurySpread + pointSpread;
	}
	if (pointDiff < 0.6) {
		return false;
	}
	return true;
}

export function calculatePointSpread(pointSpread) {
	let newPointSpread = pointSpread % 1;

	if (newPointSpread >= 0 && newPointSpread < 0.26) {
		newPointSpread = Math.floor(pointSpread);
	} else if (newPointSpread >= 0.26 && newPointSpread < 0.75) {
		newPointSpread = pointSpread - newPointSpread + 0.5;
	} else if (newPointSpread >= 0.75) {
		newPointSpread = Math.ceil(pointSpread);
	} else if (newPointSpread <= 0 && newPointSpread > -0.26) {
		newPointSpread = Math.ceil(pointSpread);
	} else if (newPointSpread <= -0.26 && newPointSpread > -0.75) {
		newPointSpread = pointSpread - newPointSpread - 0.5;
	} else if (newPointSpread <= -0.74) {
		newPointSpread = Math.floor(pointSpread);
	}
	if (newPointSpread > 0) {
		return '-' + newPointSpread;
	}
	return newPointSpread;
}

export function calculateInjuryPointSpread(pointSpread, injurySpread) {
	let newInjurySpread = injurySpread % 1;
	if (newInjurySpread >= 0 && newInjurySpread < 0.26) {
		newInjurySpread = Math.floor(injurySpread);
	} else if (newInjurySpread >= 0.26 && newInjurySpread < 0.75) {
		newInjurySpread = injurySpread - newInjurySpread + 0.5;
	} else if (newInjurySpread >= 0.75) {
		newInjurySpread = Math.ceil(injurySpread);
	} else if (newInjurySpread <= 0 && newInjurySpread > -0.26) {
		newInjurySpread = Math.ceil(injurySpread);
	} else if (newInjurySpread <= -0.26 && newInjurySpread > -0.75) {
		newInjurySpread = injurySpread - newInjurySpread - 0.5;
	} else if (newInjurySpread <= -0.74) {
		newInjurySpread = Math.floor(injurySpread);
	}

	const teamHighlight = highlightColumn(pointSpread, injurySpread);

	if (!betOnGame(pointSpread, injurySpread)) {
		if (newInjurySpread >= 0) {
			return newInjurySpread * -1;
		} else {
			return newInjurySpread;
		}
	}

	if (newInjurySpread === 0) {
		return newInjurySpread;
	} else if (teamHighlight === 'home' && pointSpread >= 0 && injurySpread < 0) {
		return '*' + newInjurySpread;
	} else if (teamHighlight === 'home' && pointSpread < 0 && injurySpread >= 0) {
		return '*' + newInjurySpread * -1;
	} else if (teamHighlight === 'home' && pointSpread < 0 && injurySpread >= 0) {
		return '+' + newInjurySpread * -1;
	} else if (teamHighlight === 'home' && pointSpread >= 0) {
		return '+' + newInjurySpread;
	} else if (teamHighlight === 'home' && pointSpread < 0) {
		return newInjurySpread;
	} else if (teamHighlight === 'away' && pointSpread < 0 && injurySpread >= 0) {
		return '*' + newInjurySpread * -1;
	} else if (teamHighlight === 'away' && pointSpread >= 0 && injurySpread < 0) {
		return '*' + newInjurySpread;
	} else if (teamHighlight === 'away' && pointSpread >= 0) {
		return '-' + newInjurySpread;
	} else if (teamHighlight === 'away' && pointSpread < 0) {
		return '+' + newInjurySpread * -1;
	}
}

export function showHealthyToReturnDate(date, months) {
	const currentDate = new Date();
	if (monthDifference(new Date(date), currentDate) > months && new Date(date) < currentDate) {
		return false;
	}
	return true;
}

export function calculateHeight(height) {
	let feet = Math.floor(height / 12);
	let inches = Math.floor(height % 12);
	return isNaN(feet) || isNaN(inches) ? height : `${feet}'${inches}"`;
}

export function getUserSubscription(user, type) {
	if (!user || !type) {
		return false;
	}
	if (user.login && user.paid && user.package_plan) {
		return user.package_plan.toUpperCase() === type;
	} else {
		return false;
	}
}
