import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { Row, Col, Button, Alert } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';

import '../Admin/style.css';
import ToolkitProvider, { ColumnToggle } from 'react-bootstrap-table2-toolkit';
import { Control, Form } from 'react-redux-form';
import InputFields from '../InputFields';
import { connect } from 'react-redux';
import { GET_DASHBOARD_OPTIONS, GET_TEAM_HEALTH_SCORE_NFL } from '../../store/constants';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import moment from 'moment/moment';

function TeamHealthScore(props) {
	const [isDateSelected, setIsDateSelected] = useState(false);

	const columns = [
		{
			dataField: 'team_name',
			text: 'Team Name',
			formatter: (value) => {
				return <div>{value || ''}</div>;
			}
		},
		{
			dataField: 'id',
			text: 'Id',
			formatter: (value) => {
				return <div>{value || ''}</div>;
			}
		},
		{
			dataField: 'team_id',
			text: 'Team Id',
			formatter: (value) => {
				return <div>{value || ''}</div>;
			}
		},
		{
			dataField: 'sportsID',
			text: 'Sports Id',
			formatter: (value) => {
				return <div>{value || ''}</div>;
			}
		},
		{
			dataField: 'original_team_gaming_score',
			text: 'Original Team Gaming Score',
			formatter: (value) => {
				return <div>{value || ''}</div>;
			}
		},
		{
			dataField: 'team_gaming_score',
			text: 'Team Gaming Score',
			formatter: (value) => {
				return <div>{value || ''}</div>;
			}
		},
		{
			dataField: 'original_offensive_gaming_avg',
			text: 'Original Offensive Gaming Avg',
			formatter: (value) => {
				return <div>{value || ''}</div>;
			}
		},
		{
			dataField: 'offensive_gaming_avg',
			text: 'Offensive Gaming Avg',
			formatter: (value) => {
				return <div>{value || ''}</div>;
			}
		},
		{
			dataField: 'original_defensive_gaming_avg',
			text: 'Original Defensive Gaming Avg',
			formatter: (value) => {
				return <div>{value || ''}</div>;
			}
		},
		{
			dataField: 'offensive_line_avg',
			text: 'Offensive Line Avg',
			formatter: (value) => {
				return <div>{value || ''}</div>;
			}
		},
		{
			dataField: 'offensive_line_avg_rounded',
			text: 'Offensive Line Avg Rounded',
			formatter: (value) => {
				return <div>{value || ''}</div>;
			}
		},
		{
			dataField: 'offensive_skills_avg_rounded',
			text: 'Offensive Skills Avg Rounded',
			formatter: (value) => {
				return <div>{value || ''}</div>;
			}
		},
		{
			dataField: 'defensive_line_avg',
			text: 'Defensive Line Avg',
			formatter: (value) => {
				return <div>{value || ''}</div>;
			}
		},
		{
			dataField: 'defensive_line_avg_rounded',
			text: 'Defensive Line Avg Rounded',
			formatter: (value) => {
				return <div>{value || ''}</div>;
			}
		},
		{
			dataField: 'defensive_secondary_avg',
			text: 'Defensive Secondary Avg',
			formatter: (value) => {
				return <div>{value || ''}</div>;
			}
		},

		{
			dataField: 'created_at',
			text: 'Created At',
			formatter: (value) => {
				return <div>{value || ''}</div>;
			}
		},
		{
			dataField: 'cis_total',
			text: 'Cis Total',
			formatter: (value) => {
				return <div>{value || ''}</div>;
			}
		},
		{
			dataField: 'defensive_gaming_avg',
			text: 'Defensive Gaming Avg',
			formatter: (value) => {
				return <div>{value || ''}</div>;
			}
		},
		{
			dataField: 'irc_total',
			text: 'Irc Total',
			formatter: (value) => {
				return <div>{value || ''}</div>;
			}
		},
		{
			dataField: 'offensive_skills_avg',
			text: 'Offensive Skills Avg',
			formatter: (value) => {
				return <div>{value || ''}</div>;
			}
		},
		{
			dataField: 'updated_at',
			text: 'Updated At',
			formatter: (value) => {
				return <div>{value || ''}</div>;
			}
		},
		{
			dataField: 'hpf_total',
			text: 'Hpf Total',
			formatter: (value) => {
				return <div>{value || ''}</div>;
			}
		},
		{
			dataField: 'defensive_secondary_avg_rounded',
			text: 'Defensive Secondary Avg Rounded',
			formatter: (value) => {
				return <div>{value || ''}</div>;
			}
		}
	];
	const { ToggleList } = ColumnToggle;
	const { adminDashboardOptions, adminTeamHealthScoreNFL, dispatch } = props;

	const records = adminTeamHealthScoreNFL?.records || [];
	const total_records = adminTeamHealthScoreNFL?.total_records || 0;

	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);

	const [selectedDate, setSelectedDate] = useState({
		startDate: null,
		endDate: null,
		key: 'selection'
	});

	let teamOptions = adminDashboardOptions?.data?.teams?.filter((e) => e?.sportsID === '1');

	if (adminTeamHealthScoreNFL?.sportsID) {
		teamOptions = adminDashboardOptions?.data?.teams?.filter((x) => x.sportsID === adminTeamHealthScoreNFL?.sportsID);
	}
	useEffect(() => {
		dispatch({
			type: GET_DASHBOARD_OPTIONS
		});
	}, []);

	const sports = [
		{
			label: 'NBA',
			value: 2
		},
		{
			label: 'Soccer',
			value: 3
		}
	];

	return (
		<div className='adminDiv'>
			{(adminTeamHealthScoreNFL?.success || adminTeamHealthScoreNFL?.error) && (
				<div style={{ display: 'flex' }}>
					<Alert className='statusLabel successLabel text-center hideDiv'>{adminTeamHealthScoreNFL?.message}</Alert>
				</div>
			)}
			{(adminTeamHealthScoreNFL?.success || adminTeamHealthScoreNFL?.error) && (
				<div style={{ display: 'flex' }}>
					<Alert className='statusLabel successLabel text-center hideDiv'>{adminTeamHealthScoreNFL?.message}</Alert>
				</div>
			)}

			<Form
				model='adminTeamHealthScoreNFL'
				onSubmit={() => {
					dispatch({
						type: GET_TEAM_HEALTH_SCORE_NFL,
						payload: {
							filterDateStart: selectedDate?.startDate || new Date(),
							filterDateEnd: selectedDate?.endDate || new Date(),
							teamId: adminTeamHealthScoreNFL?.teamId,
							limit: pageSize,
							offset: (currentPage - 1) * pageSize,
							sportsID: adminTeamHealthScoreNFL?.sportsID
						}
					});
				}}
			>
				<Row className='adminDashboardRow'>
					{adminDashboardOptions?.isLoading ? (
						<div id='fullWidthLoaderContainer'>
							<div id='loading' />
						</div>
					) : null}
					<h1 className='text-center'>SEARCH</h1>
					<Col lg={3} className='adminDashboardCol'>
						<p className='loginLabel'>Team Names</p>
						{teamOptions && <InputFields label='Team' type='select' inputType='select' defaultOption='Select a team' options={teamOptions} model='.teamId' classname='loginInput hoverDelay' ignore={true} />}
					</Col>
					<Col lg={3} className='adminDashboardCol'>
						<p className='loginLabel'>Select Sport</p>
						{sports && <InputFields label='Sport' type='select' inputType='select' defaultOption='NFL' options={sports} model='.sportsID' classname='loginInput hoverDelay' ignore={true} />}
					</Col>
					<Col lg={3} className='adminDashboardCol'>
						<p className='loginLabel'>Date</p>
						<div
							className='loginInput hoverDelay'
							onClick={() => {
								setIsDateSelected((prev) => !prev);
							}}
						>
							{!selectedDate.startDate && !selectedDate.endDate ? 'Select Date Range' : `${moment(selectedDate.startDate).format('DD/MM/YYYY')} - ${moment(selectedDate.endDate).format('DD/MM/YYYY')}`}
						</div>
						{isDateSelected ? (
							<DateRangePicker
								ranges={[selectedDate]}
								onChange={(range) => {
									if (range.selection) {
										setSelectedDate(range.selection);
									}
									const startDate = range?.selection?.startDate;
									const endDate = range?.selection?.endDate;

									if (moment(startDate).isBefore(moment(endDate))) {
										setIsDateSelected(false);
									}
								}}
							/>
						) : null}
					</Col>
				</Row>

				<Row className='adminDashboardButtonRow'>
					<Col lg={3} className='adminDashboardCol'>
						{/* eslint-disable-next-line react/jsx-pascal-case */}
						<Control.button href='#/' model='adminTeamHealthScoreNFL' type='submit' className='generalButton adminButton hover-delay' disabled={!adminTeamHealthScoreNFL?.teamId || adminTeamHealthScoreNFL?.isLoading}>
							SEARCH
						</Control.button>
					</Col>
				</Row>
			</Form>
			<Row className='adminDashboardTableRow'>
				<>
					<h1 className='text-center'>TEAM HEALTH SCORE TABLE</h1>

					<Col lg={12} className='adminDashboardTableCol'>
						<ToolkitProvider keyField='id' className='adminDashboardTable' data={records} columns={columns} columnToggle>
							{(props) => {
								return (
									<div>
										<ToggleList {...props.columnToggleProps} />
										<hr />
										<BootstrapTable {...props.baseProps} />
										<ReactPaginate
											breakLabel='...'
											nextLabel='next >'
											onPageChange={(selectedItem) => {
												const updatedCurrentPage = selectedItem.selected + 1;
												setCurrentPage(updatedCurrentPage);
												// setPageSize(sizePerPage);
												dispatch({
													type: GET_TEAM_HEALTH_SCORE_NFL,
													payload: {
														filterDate: adminTeamHealthScoreNFL?.date,
														teamId: adminTeamHealthScoreNFL?.teamId,
														limit: pageSize,
														offset: (currentPage - 1) * pageSize
													}
												});
											}}
											forcePage={currentPage - 1}
											pageRangeDisplayed={5}
											previousLabel='< previous'
											renderOnZeroPageCount={null}
											pageClassName='page-item'
											pageLinkClassName='page-link'
											previousClassName='page-item'
											previousLinkClassName='page-link'
											nextClassName='page-item'
											nextLinkClassName='page-link'
											breakClassName='page-item'
											breakLinkClassName='page-link'
											containerClassName='pagination'
											activeClassName='active'
											pageCount={Math.ceil(+(total_records || 1) / +(pageSize || 1))}
										/>
										{adminTeamHealthScoreNFL?.isLoading ? (
											<div id='fullWidthLoaderContainer'>
												<div id='loading' />
											</div>
										) : null}
									</div>
								);
							}}
						</ToolkitProvider>
					</Col>
				</>
			</Row>
		</div>
	);
}

const mapStateToProps = (state) => ({
	adminTeamHealthScoreNFL: state.adminTeamHealthScoreNFL,
	adminDashboardOptions: state.adminDashboardOptions
});

export default connect(mapStateToProps, null)(TeamHealthScore);
