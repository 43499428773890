import ReactGA from 'react-ga';

export const initGA = (trackingID) => {
	ReactGA.initialize(trackingID);
};

/**
 * Event - Add custom tracking event.
 * @param {string} category
 * @param {string} action
 * @param {string} label
 */
export const Event = (category, action, label) => {
	// console.log(category);
	// console.log(action);
	// console.log(label);
	ReactGA.event({
		category: category,
		action: action,
		label: label
	});
};
