export const user = (state) => state.user;
export const userActions = (state) => state.userActions;
export const options = (state) => state.options;
export const signup = (state) => state.signup;
export const passwordReset = (state) => state.passwordReset;
export const adminDashboardSearch = (state) => state.adminDashboardSearch;
export const sendemail = (state) => state.sendemail;
export const purchasePlan = (state) => state.purchasePlan;
export const freeAccess = (state) => state.freeAccess;
export const prices = (state) => state.prices;
export const sources = (state) => state.sources;
