export function addDays(date, days, minus) {
	let today = new Date(date);
	let newdate = new Date(today);
	if (minus) {
		newdate.setDate(today.getDate() - days);
	} else {
		newdate.setDate(today.getDate() + days);
	}
	return newdate;
}

export function formatDate(dateOnly, date = null, source = null) {
	var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

	let today;
	if (date) {
		today = new Date(date);
	} else {
		today = new Date();
	}

	const injuryDate = today;
	const currentDate = new Date();
	// const currentYear = currentDate.getFullYear();
	let monthDiff = monthDifference(injuryDate, currentDate);
	// monthDiff = addDays(monthDiff, 1, true);
	if (monthDiff > 18) {
		return 'No Recent Injury';
	}

	let dd = today.getDate();
	let mm = today.getMonth();
	let hh = today.getHours();
	// dd = dd + 1;
	if (source) {
		if (dd < 10) {
			dd = `0${dd}`;
		}
		mm += 1;

		if (mm < 10) {
			mm = `0${mm}`;
		}

		const yyyy = today.getFullYear();
		return `${mm}/${dd}/${yyyy}`;
	}
	let h = hh;
	if (h > 12) {
		h = hh - 12;
		// dd = "PM";
	}
	if (h === 0) {
		h = 12;
	}
	let minutes = today.getMinutes();
	if (minutes < 10) {
		minutes = `0${minutes}`;
	}
	mm = months[mm];
	const yyyy = today.getFullYear();
	if (dd < 10) {
		dd = `0${dd}`;
	}

	if (mm < 10) {
		mm += 1;
		mm = `0${mm}`;
	}
	if (dateOnly === true) {
		return (today = `${mm} ${dd}, ${yyyy}`);
	} else {
		return dd > 0 ? (today = `${mm} ${dd}, ${yyyy} ${h}:${minutes}${hh >= 12 ? ' PM' : ' AM'}`) : 'TBD';
	}
}

export function monthDifference(dt2, dt1) {
	let diff = (dt2.getTime() - dt1.getTime()) / 1000;
	diff /= 60 * 60 * 24 * 7 * 4;
	return Math.abs(Math.round(diff));
}

export function formatTime(time) {
	let hour = time.slice(0, 2);
	let minute = time.slice(3, 6);
	let timeFormat;
	if (hour >= 12) {
		timeFormat = 'PM';
		hour = hour - 12;
		if (hour === 0) {
			hour = 12;
		}
	} else {
		timeFormat = 'AM';
	}
	// hour = hour - 5;
	if (hour < 0) {
		timeFormat = 'PM';
		hour += 12;
	}
	return hour + ':' + minute + ' ' + timeFormat;
}

/**
 *  Parses the JSON returned by a network request
 *
 * @param {object} response A response from a network request
 */
function parseJSON(response) {
	return response.json();
}

export function checkStatus(response) {
	if (response.status >= 200 && response.status < 300) {
		return response;
	}
}

export function sortArrayOfObj(key, order = 'asc') {
	return function innerSort(a, b) {
		if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
			// property doesn't exist on either object
			return 0;
		}

		const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
		const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

		let comparison = 0;
		if (varA > varB) {
			comparison = 1;
		} else if (varA < varB) {
			comparison = -1;
		}
		return order === 'desc' ? comparison * -1 : comparison;
	};
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           An object containing either "data" or "err"
 */
export function request(url, options) {
	return fetch(url, options)
		.then(checkStatus)
		.then(parseJSON)
		.then((data) => ({
			data
		}))
		.catch((err) => ({
			err
		}));
}
