import React, { Component } from 'react';
import { connect } from 'react-redux';
import { actions } from 'react-redux-form';
import { Row, Col } from 'react-bootstrap';
import './style.css';

import logo from './img/footerLogo.png';
// import facebookIcon from "./img/facebookIcon.png";
// import twitterIcon from "./img/twitterIcon.png";
import terms from '../Terms/terms.pdf';
import privacy from '../Terms/privacy.pdf';
// import refund from "../Terms/return.pdf";
// import master from "../Login/img/master.png";
// import visa from "../Login/img/visa.jpg";
// import discover from "../Login/img/discover.png";
// import amex from "../Login/img/amex.png";

function Footer() {
	return (
		<div className='footerSection container-fluid'>
			<Row className='footerRow'>
				<Col lg='6' className='footerLogoCol'>
					<div>
						<a href='/' className='footerLogoLink'>
							<img src={logo} className='footerLogo' alt='Inside Injuries Logo' />
						</a>
						<a href='https://sportsdata.io/' title='SportsData IO' target='_blank' rel='noreferrer' className='footerLogoLink'>
							<img src='https://sportsdata.io/assets/images/badges/sportsdataio_light_ss_300.png' alt='Powered by SportsDataIO' className='sportsDataLogo' />
						</a>
					</div>
				</Col>
				<Col lg='6'>
					<div className='footerLinksDiv'>
						{/* <a
              href="/nfl/dashboard"
              className="footerLink"
              title="Links"
              onClick={() => {
                dispatch(actions.change("user.sportsID", 1));
                dispatch(actions.change("user.sportsName", "nfl"));
              }}
            >
              NFL
            </a>
            <a
              href="/nba/dashboard"
              className="footerLink"
              title="Links"
              onClick={() => {
                dispatch(actions.change("user.sportsID", 2));
                dispatch(actions.change("user.sportsName", "nba"));
              }}
            >
              NBA
            </a>
            <a
              href="/soccer/dashboard"
              className="footerLink"
              title="Links"
              onClick={() => {
                dispatch(actions.change("user.sportsID", 3));
                dispatch(actions.change("user.sportsName", "soccer"));
              }}
            >
              SOCCER
            </a> */}
						{/* <a href="/about" className="footerLink" title="Links">
              ABOUT US
            </a> */}
						<a href={terms} className='footerLink' title='Links' target='_blank' rel='noreferrer'>
							TERMS AND CONDITIONS
						</a>
						<br className='mobileLineBreak' />
						<a href={privacy} className='footerLink' title='Links' target='_blank' rel='noreferrer'>
							PRIVACY POLICY
						</a>
					</div>
				</Col>
				<Col lg={12}>
					<div>
						<hr />
						<p id='footerAddress' className='footerAddress'>
							info@insideinjuries.com
							<br />
							3340 Peachtree Road, Suite 2025
							<br />
							Atlanta, GA 30326
						</p>
					</div>
				</Col>
			</Row>
		</div>
	);
}

Footer.propTypes = {};

const mapStateToProps = (state) => ({
	isLoading: state.isLoading,
	isLoaded: state.isLoaded,
	user: state.user
});

export default connect(mapStateToProps)(Footer);
