import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import '../Dashboard/matchup.css';
import { Alert, Row, Col } from 'react-bootstrap';
import PlayerPanel from '../Dashboard/playerPanel';
import UserHeader from '../UserHeader';
import TeamLegend from '../Dashboard/teamLegend';
import Logo from '../../global/img/logo_full.png';
import { highlightColumn, highlightColumnColor, checkHealthScoreStatus, getPlayerCategories, sortPlayersByGamingScore } from '../utils/dashboardCalculations';
import './style.css';
// import InputFields from "../InputFields/index";

function NFLMatchup(props) {
	let { id: gameID } = useParams();
	useEffect(() => {
		window.scrollTo(0, 0);
		let { dispatch, match, healthScores, assignCurrentSport } = props;
		assignCurrentSport();
		if (!match.match || gameID) {
			dispatch({ type: 'GET_MATCH', payload: { gameID } });
			if (!healthScores.categoryAverages) {
				dispatch({ type: 'GET_HEALTH_SCORES', payload: { gameID } });
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [showAwayRoster, handleShowAwayRoster] = useState(false);
	const [showHomeRoster, handleShowHomeRoster] = useState(false);

	const toggleRosterView = (type) => {
		if (type === 'away') {
			handleShowAwayRoster(!showAwayRoster);
		} else if (type === 'home') {
			handleShowHomeRoster(!showHomeRoster);
		}
	};

	const { match, schedules, user, hideUserHeader, toggleScheduleView, healthScores, teams } = props;
	const title = 'Health Scores for Team Matchups';

	if (match.isLoading || healthScores.isLoading || user.isLoading)
		return (
			<div id='fullHeight'>
				<div id='loading' />
			</div>
		);
	if (!match.awayPlayers || !match.homePlayers) {
		return (
			<React.Fragment>
				<div className='gameMainDiv'>
					<UserHeader matchup={true} backgroundColor={'#f7f7f7'} title={title} />
					<h1 className='bold text-center'>{match.error}</h1>
				</div>
			</React.Fragment>
		);
	}
	const game = match?.match[0];
	let { awayPlayers, homePlayers } = match;

	const awayTeam = teams?.data?.teamInfo?.find((x) => x.team_id === game?.away_team_id);
	const homeTeam = teams?.data?.teamInfo?.find((x) => x.team_id === game?.home_team_id);

	const {
		away_team_city,
		away_team_name,
		home_team_city,
		home_team_name,
		over_under_prediction_outcome,
		away_team_logo,
		home_team_logo,
		over_under,
		point_spread,
		disable_over_under_bet,
		disable_spread_bet,
		// date_time,
		point_spread_away_team_money_line,
		point_spread_home_team_money_line
		// away_team_score,
		// home_team_score,
		// status,
		// over_under_prediction_correct,
		// spread_prediction_correct,
	} = game;
	// {point_spread < 0 ? point_spread : `-${point_spread}`}
	let favoredTeamBySpread, awayTeamPointSpread, homeTeamPointSpread;
	let pointSpreadLogo;
	if (point_spread >= 0) {
		awayTeamPointSpread = `-${point_spread}`;
		homeTeamPointSpread = `+${point_spread}`;
	} else {
		awayTeamPointSpread = `+${Math.abs(point_spread)}`;
		homeTeamPointSpread = point_spread;
	}

	if (game?.favored_team_by_predicted_spread?.split(':')[0]?.toUpperCase() === 'AWAY') {
		pointSpreadLogo = <img src={away_team_logo} alt='Team Logo' className='matchupPointSpreadLogo' />;
		favoredTeamBySpread = `${away_team_city} ${away_team_name}`;
	} else if (game?.favored_team_by_predicted_spread?.split(':')[0]?.toUpperCase() === 'HOME') {
		pointSpreadLogo = <img src={home_team_logo} alt='Team Logo' className='matchupPointSpreadLogo' />;
		favoredTeamBySpread = `${home_team_city} ${home_team_name}`;
	} else {
		favoredTeamBySpread = "Unpredictable. Don't Bet.";
	}

	const overUnderPrediction = over_under_prediction_outcome?.toUpperCase() === 'UNPREDICTABLE' ? "Unpredictable. Don't Bet." : over_under_prediction_outcome;

	const offensiveSkillsAway = awayPlayers && getPlayerCategories(awayPlayers, 'offensiveskills', healthScores, awayTeam);
	const offensiveLineAway = awayPlayers && getPlayerCategories(awayPlayers, 'offensiveline', healthScores, awayTeam);
	const defensiveLineAway = awayPlayers && getPlayerCategories(awayPlayers, 'defensiveline', healthScores, awayTeam);
	const defensiveSecondaryAway = awayPlayers && getPlayerCategories(awayPlayers, 'defensivesecondary', healthScores, awayTeam);
	const offensiveSkillsHome = homePlayers && getPlayerCategories(homePlayers, 'offensiveskills', healthScores, homeTeam);
	const offensiveLineHome = homePlayers && getPlayerCategories(homePlayers, 'offensiveline', healthScores, homeTeam);
	const defensiveLineHome = homePlayers && getPlayerCategories(homePlayers, 'defensiveline', healthScores, homeTeam);
	const defensiveSecondaryHome = homePlayers && getPlayerCategories(homePlayers, 'defensivesecondary', healthScores, homeTeam);

	let topFourAway = awayPlayers && sortPlayersByGamingScore(awayPlayers);
	let topFourHome = homePlayers && sortPlayersByGamingScore(homePlayers);

	// const awayRoster = awayPlayers;
	// const homeRoster = homePlayers;

	const awayRoster = awayPlayers?.filter((x) => x.active === 1);
	const homeRoster = homePlayers?.filter((x) => x.active === 1);

	const homeHealthScore = game && game.home_team_gaming_score;
	const awayHealthScore = game && game.away_team_gaming_score;
	const homeStatusText = checkHealthScoreStatus(homeHealthScore, false, 'team');
	const awayStatusText = checkHealthScoreStatus(awayHealthScore, false, 'team');

	let teamHighlightShadowAway;
	let teamHighlightShadowHome;
	const highlightCol = highlightColumn(homeHealthScore, awayHealthScore);
	if (highlightCol === 'away') {
		teamHighlightShadowAway = 'teamShadowHighlight';
	} else if (highlightCol === 'home') {
		teamHighlightShadowHome = 'teamShadowHighlight';
	}
	const homeHighlightColor = highlightColumnColor(homeHealthScore, 'teamHighlight');
	const awayHighlightColor = highlightColumnColor(awayHealthScore, 'teamHighlight');
	const offenseDesc = 'This category reflects the avg health score of all core offensive players injuries on a specific team. It assesses their injury impact on performance as a group.';
	const defenseDesc = "This category reflects the avg health score of all core defensive players' injuries on a specific team. It assesses their injury impact on performance as a group.";
	const offenseDefenseDesc = "This category reflects the avg health score of all core players' injuries on a specific team. It assesses their injury impact on performance as a group.";

	return (
		<section className='gameMainSection'>
			<div className='gameMainDiv'>
				<UserHeader matchup={true} backgroundColor={'#f7f7f7'} title={title} hideUserHeader={hideUserHeader} toggleScheduleView={() => toggleScheduleView()} game={game} />
				<div className='gameBoxShadow'>
					<Row className='gameSpreadHeaderRow'>
						<Col lg={6} className={`gameSpreadHeaderCol ${awayHighlightColor}`}>
							<div className='gameSpreadHeaderDiv'>
								<React.Fragment>
									<p className='gameSpreadHeaderSpread'>
										<span style={{ paddingRight: '20px' }}>{awayStatusText.status}</span>
										{awayHealthScore}
									</p>
									<p className='gameSpreadHeaderDescription'>Team Health Score</p>
								</React.Fragment>
							</div>
						</Col>
						<Col lg={6} className={`gameSpreadHeaderCol ${homeHighlightColor}`}>
							<div className='gameSpreadHeaderDiv'>
								<React.Fragment>
									<p className='gameSpreadHeaderDescription'>Team Health Score</p>
									<p className='gameSpreadHeaderSpread pull-right'>
										<span style={{ paddingRight: '20px' }}>{homeStatusText.status}</span>
										{homeHealthScore}
									</p>
								</React.Fragment>
							</div>
						</Col>
					</Row>
					<Row className='gameTeamHeaderRow'>
						<Col lg={6} className={`gameTeamHeaderCol ${teamHighlightShadowAway} col-xs-12`}>
							<div className='gameTeamHeaderDiv'>
								<p className={`gameTeamHeaderSpread bold ${awayHighlightColor}`}>{awayHealthScore}</p>
								<div className='gameTeamHeaderLogoDiv gameTeamHeaderLogoAwayDiv'>
									<img src={game.away_team_logo} alt='Team Logo' className='gameTeamLogo' />
									<p className='gameTeamHeaderName gameTeamHeaderNameAway'>{game.away_team_city + ' ' + game.away_team_name}</p>
								</div>
							</div>
						</Col>
						<Col lg={6} className={`gameTeamHeaderCol ${teamHighlightShadowHome} col-xs-12`}>
							<div className='gameTeamHeaderDiv gameTeamHeaderDivHome'>
								<div className='gameTeamHeaderLogoDiv gameTeamHeaderLogoRightDiv'>
									<p className='gameTeamHeaderName gameTeamHeaderNameHome'>{game.home_team_city + ' ' + game.home_team_name}</p>
									<img src={game.home_team_logo} alt='Team Logo' className='gameTeamLogo' />
								</div>
								<p className={`gameTeamHeaderSpread gameTeamHeaderSpreadRight bold ${homeHighlightColor}`}>{homeHealthScore}</p>
							</div>
						</Col>
					</Row>
					<Row className='bettingRow'>
						<Col lg={12} className='bettingCol colAlignContentCenter'>
							<h2>SPREAD</h2>
						</Col>
						<Col lg={12} className='bettingCol'>
							<div className='bettingInfoDiv'>
								<p className='bettingInfo bettingInfoLeft'>
									<span className='bettingInfoTeamName'>
										{game.away_team_city + ' ' + game.away_team_name}
										<br />
									</span>
									<span>{awayTeamPointSpread}</span>
									<br />
									<span>{point_spread_away_team_money_line}</span>
								</p>
								<p className='bettingInfo bettingInfoRight'>
									<span className='bettingInfoTeamName'>
										{game.home_team_city + ' ' + game.home_team_name}
										<br />
									</span>
									<span>{homeTeamPointSpread}</span>
									<br />
									<span>{point_spread_home_team_money_line}</span>
								</p>
							</div>
						</Col>
					</Row>
					<hr className='matchupInfoHrSeparator' />
					<Row className='bettingRow'>
						<Col lg={12} className='colAlignContentCenter'>
							<h2>OVER/UNDER: {over_under}</h2>
						</Col>
					</Row>
					<hr className='matchupInfoHrSeparator' />
					<Row className='bettingRow bettingPredictionRow'>
						<Col lg={12} className='bettingCol colAlignContentCenter'>
							<div className='expertPicksHeaderDiv'>
								<img src={Logo} alt='Inside Injuries Logo' className='matchupLogo' />
								<h2 className='brandColor expertPicksHeader'>EXPERT PICKS</h2>
							</div>
						</Col>
						<Col lg={12} className='bettingCol'>
							<div className='bettingPredictionDiv'>
								<p className='bettingPredictionInfo bettingInfoLeft'>
									<span>OVER/UNDER PREDICTION</span>
									<br />
									<span className='brandColor'>{disable_over_under_bet ? "Unpredictable. Don't Bet." : overUnderPrediction}</span>
								</p>
								<p className='bettingPredictionInfo bettingInfoRight'>
									<span>FAVORED TEAM BY SPREAD</span>
									<br />
									<span className='brandColor'>
										{disable_spread_bet ? (
											"Unpredictable. Don't Bet."
										) : (
											<>
												{pointSpreadLogo} {favoredTeamBySpread}
											</>
										)}
									</span>
								</p>
							</div>
						</Col>
					</Row>
					{game.game_description && (
						<Row className='gameDescriptionRow'>
							<Col lg={12} className='gameDescriptionCol'>
								<p className='gameDescription'>{game.game_description}</p>
							</Col>
						</Row>
					)}
					<TeamLegend />
					{user?.login && (
						<>
							<div className='topFourPlayerDiv'>
								<h1 className='dashboardHowItWorksHeader'>Top 4 Impactful Players</h1>
							</div>
							<Row>
								<Col lg={6} className='gameMatchupHeaderCol gameMatchupHeaderCol1'>
									<div className='matchupHeaderDiv'>
										<hr className='hrStyleMobile' />
										<h3 className='matchupHeaderTopMobile'>Away Team</h3>
										<hr className='hrStyleMobile' />
										<Row>
											{topFourAway.map((players, i) => {
												return (
													<Col lg={6} key={i}>
														<Link
															className='routerLinkMatchup hover-delay'
															title={`${players.player_name}`}
															to={{
																pathname: `/nfl/player/${players?.player_id}`,
																playerID: players?.player_id
															}}
														>
															<div className='matchPlayerDiv'>
																<div className='matchPlayerInfoDiv matchPlayerInfoTopDiv'>
																	<p className='matchPlayerInfoNameTop'>{players.nickname || players.player_name}</p>
																	<p className='matchPlayerInfoInjury'>
																		<span className='grey'>POSITION:</span>
																		<span className='matchPlayerInfo'>{players.position}</span>
																	</p>
																	<p className='matchPlayerInfoInjury'>
																		<span className='grey'>HEALTH SCORE:</span>
																		<span className='matchPlayerInfo'>{players.gaming_score || '10'}</span>
																	</p>
																</div>
															</div>
														</Link>
													</Col>
												);
											})}
										</Row>
									</div>
								</Col>
								<Col lg={6} className='gameMatchupHeaderCol gameMatchupHeaderCol1'>
									<div className='matchupHeaderDiv'>
										<hr className='hrStyleMobile' />
										<h3 className='matchupHeaderTopMobile'>Home Team</h3>
										<hr className='hrStyleMobile' />
										<Row>
											{topFourHome.map((players, i) => {
												return (
													<Col lg={6} key={i}>
														<Link
															className='routerLinkMatchup hover-delay'
															title={`${players.player_name}`}
															to={{
																pathname: `/nfl/player/${players?.player_id}`,
																playerID: players?.player_id
															}}
														>
															<div className='matchPlayerDiv'>
																{/* <img
																		src={game.home_team_logo}
																		alt="Player"
																		className="matchPlayerImg displayTableCell"
																	/> */}
																<div className='matchPlayerInfoDiv matchPlayerInfoTopDiv'>
																	<p className='matchPlayerInfoNameTop'>{players.nickname || players.player_name}</p>
																	<p className='matchPlayerInfoInjury'>
																		<span className='grey'>POSITION:</span>
																		<span className='matchPlayerInfo'>{players.position}</span>
																	</p>
																	<p className='matchPlayerInfoInjury'>
																		<span className='grey'>HEALTH SCORE:</span>
																		<span className='matchPlayerInfo'>{players.gaming_score || '10'}</span>
																	</p>
																</div>
															</div>
														</Link>
													</Col>
												);
											})}
										</Row>
									</div>
								</Col>
							</Row>
						</>
					)}

					<Row className='gameMatchupRow'>
						{/* Away Team */}
						<Col lg={12}>
							<h1 className='dashboardHowItWorksHeader'>Position Categories</h1>
						</Col>
						<Col lg={6} className='gameMatchupHeaderCol gameMatchupHeaderCol1'>
							<div className='matchupHeaderDiv'>
								<Row>
									<Col lg={8} className='matchupHeaderCol'>
										<hr className='hrStyleMobile' />
										<p className='matchupMobileHeader'>
											{game.away_team_city} {game.away_team_name}
										</p>
										<hr className='hrStyleMobile' />
										<a href='#/' className='matchupViewAll hover-delay' onClick={() => toggleRosterView('away')}>
											{!showAwayRoster ? 'VIEW AWAY TEAM ROSTER' : 'VIEW POSITION CATEGORIES'}
										</a>
									</Col>
									<Col lg={3} className='matchupHeaderCol'>
										<p className='matchupHeader'>Health Score</p>
									</Col>
									<Col lg={1} />
								</Row>
							</div>
							{!showAwayRoster ? (
								<React.Fragment>
									<div style={{ paddingBottom: '30px' }}>
										<PlayerPanel id={4} healthScoreStatus={offensiveLineAway} players={offensiveLineAway.players} panelTitle='OFFENSIVE LINE' tooltip={offenseDesc} sportsName='nfl' teamLogo={game.away_team_logo} />
										<PlayerPanel id={5} healthScoreStatus={offensiveSkillsAway} players={offensiveSkillsAway.players} panelTitle='OFFENSIVE SKILLS PLAYERS' tooltip={offenseDesc} sportsName='nfl' teamLogo={game.away_team_logo} />
										<PlayerPanel id={6} healthScoreStatus={defensiveLineAway} players={defensiveLineAway.players} panelTitle='DEFENSIVE LINE' tooltip={defenseDesc} sportsName='nfl' teamLogo={game.away_team_logo} />
										<PlayerPanel id={7} healthScoreStatus={defensiveSecondaryAway} players={defensiveSecondaryAway.players} panelTitle='DEFENSIVE SECONDARY' tooltip={defenseDesc} sportsName='nfl' teamLogo={game.away_team_logo} />
									</div>
								</React.Fragment>
							) : (
								<React.Fragment>
									<PlayerPanel id={1} healthScoreStatus={checkHealthScoreStatus(game.away_team_gaming_score, awayRoster, 'team')} players={awayRoster} panelTitle='ALL ACTIVE PLAYERS' tooltip={offenseDefenseDesc} openAll={showAwayRoster} toggleRosterView={() => toggleRosterView('away')} sportsName='nfl' teamLogo={game.away_team_logo} />
								</React.Fragment>
							)}
						</Col>

						{/* Home Team */}

						<Col lg={6} className='gameMatchupHeaderCol gameMatchupHeaderCol2'>
							<div className='matchupHeaderDiv'>
								<Row>
									<Col lg={8} className='matchupHeaderCol'>
										<hr className='hrStyleMobile' />
										<p className='matchupMobileHeader'>
											{game.home_team_city} {game.home_team_name}
										</p>
										<hr className='hrStyleMobile' />
										<a href='#/' className='matchupViewAll hover-delay' onClick={() => toggleRosterView('home')}>
											{!showHomeRoster ? 'VIEW HOME TEAM ROSTER' : 'VIEW POSITION CATEGORIES'}
										</a>
									</Col>
									<Col lg={3} className='matchupHeaderCol'>
										<p className='matchupHeader'>Health Score</p>
									</Col>
									<Col lg={1} />
								</Row>
							</div>
							{!showHomeRoster ? (
								<React.Fragment>
									<div style={{ paddingBottom: '30px' }}>
										<PlayerPanel id={4} healthScoreStatus={offensiveLineHome} players={offensiveLineHome.players} panelTitle='OFFENSIVE LINE' tooltip={offenseDesc} sportsName='nfl' teamLogo={game.home_team_logo} />
										<PlayerPanel id={5} healthScoreStatus={offensiveSkillsHome} players={offensiveSkillsHome.players} panelTitle='OFFENSIVE SKILLS PLAYERS' tooltip={offenseDesc} sportsName='nfl' teamLogo={game.home_team_logo} />
										<PlayerPanel id={6} healthScoreStatus={defensiveLineHome} players={defensiveLineHome.players} panelTitle='DEFENSIVE LINE' tooltip={defenseDesc} sportsName='nfl' teamLogo={game.home_team_logo} />
										<PlayerPanel id={7} healthScoreStatus={defensiveSecondaryHome} players={defensiveSecondaryHome.players} panelTitle='DEFENSIVE SECONDARY' tooltip={defenseDesc} sportsName='nfl' teamLogo={game.home_team_logo} />
									</div>
								</React.Fragment>
							) : (
								<React.Fragment>
									<PlayerPanel id={8} healthScoreStatus={checkHealthScoreStatus(game.home_team_gaming_score, homeRoster, 'team')} players={homeRoster} panelTitle='ALL ACTIVE PLAYERS' tooltip={offenseDefenseDesc} openAll={showHomeRoster} toggleRosterView={() => toggleRosterView('home')} sportsName='nfl' teamLogo={game.home_team_logo} />
								</React.Fragment>
							)}
						</Col>
					</Row>
				</div>
			</div>
		</section>
	);
}

const mapStateToProps = (state) => ({
	isLoading: state.isLoading,
	isLoaded: state.isLoaded,
	healthScores: state.healthScores,
	match: state.match,
	schedules: state.schedules,
	teams: state.teams,
	user: state.user
});

export default connect(mapStateToProps)(NFLMatchup);
