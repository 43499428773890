import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Form, Control } from 'react-redux-form';
import { Country, State, City } from 'country-state-city';
import InputFields from '../InputFields';
import './style.css';

const Profile = (props) => {
	const { user, dispatch } = props;

	const [profile, setProfile] = useState({
		first_name: '',
		last_name: '',
		country: '',
		state: '',
		city: '',
		address: '',
		zipcode: '',
		email: ''
	});

	const [isEditMode, setIsEditMode] = useState(false);

	useEffect(() => {
		if (user) {
			setProfile({
				first_name: user.first_name,
				last_name: user.last_name,
				country: user.country,
				state: user.state,
				city: user.city,
				address: user.address,
				zipcode: user.zipcode,
				email: user.email
			});
		}
	}, [user]);

	const [countries, setCountries] = useState([]);
	const [states, setStates] = useState([]);
	const [cities, setCities] = useState([]);
	const [selectedCountry, setSelectedCountry] = useState('');
	const [selectedState, setSelectedState] = useState('');

	useEffect(() => {
		setCountries(Country.getAllCountries());
	}, []);

	const handleCountryChange = (event) => {
		const countryCode = countries.find(country => country.name === event.target.value);
		setSelectedCountry(countryCode?.isoCode);
		setStates(State.getStatesOfCountry(countryCode?.isoCode));
		setProfile({ ...profile, country: event.target.value, state: '', city: '' });
		setSelectedState('');
		setCities([]);
	};

	const handleStateChange = (event) => {
		const stateCode = states.find(state => state.name === event.target.value);
		setSelectedState(stateCode?.isoCode);
		setCities(City.getCitiesOfState(selectedCountry, stateCode?.isoCode));
		setProfile({ ...profile, state: event.target.value, city: '' });
	};

	const handleCityChange = (event) => {
		setProfile({ ...profile, city: event.target.value });
	};

	const handleInputChange = (name, value) => {
		setProfile((prevProfile) => ({
			...prevProfile,
			[name]: value,
		}));
	};

	const handleSubmit = () => {
		delete profile.email;

		for (const key in profile) {
			if (!profile[key]) {
				delete profile[key];
			}
		}

		dispatch({ type: 'UPDATE_PROFILE', payload: profile });
		setIsEditMode(false);
	};

	return (
		<div className="my-4 my-lg-5 profile-container">
			<div className="container">
				<div className="title_h2">
					<h2 className='h2 mb-0'>User Profile</h2>
					<p>Update your personal information.</p>
				</div>
				{!isEditMode ? (
					<div className="profile-view">
						<div className='profile_info mb-3'>
							<div className="text-start position-relative">
								<h6 className='h6 text-captialize'>Personal Information</h6>
									<button className="edit-button btn-primary" onClick={() => setIsEditMode(true)}>
									Edit Profile
								</button>
							</div>
					
							<div className="row">
								<div className="col-md-6 text-start">
									<label>First Name:</label> 
									<p>{profile.first_name}</p>
								</div>
								<div className="col-md-6 text-start">
									<label>Last Name:</label> 
									<p>{profile.last_name}</p>
								</div>
								<div className="col-md-6 text-start">
									<label>Email:</label> 
									<p> {profile.email}</p>
								</div> 
							</div>

						</div>

						<div className='profile_info mb-3'>
							<div className="text-start position-relative">
								<h6 className='h6 text-captialize'>Address</h6> 
							</div>
							<div className="row">
								<div className="col-md-6 text-start">
									<label>Address:</label> 
									<p>{profile.address}</p>
								</div>
								<div className="col-md-6 text-start"> 
									<label>City:</label> 
									<p>{profile.city}</p>
								</div>
								<div className="col-md-6 text-start"> 
									<label>State:</label> 
									<p>{profile.state}</p>
								</div>

								<div className="col-md-6 text-start"> 
									<label>Country:</label> 
									<p>{profile.country}</p>
								</div>
								<div className="col-md-6 text-start"> 
									<label>ZIP Code:</label> 
									<p>{profile.zipcode}</p>
								</div>
							</div>
						</div>  
					</div>
				) : (
					<div className="profile-view">
						<div className='profile-form'>
							<Form model='userActions.signup' onSubmit={handleSubmit}>
								<div className='inputCol'>
									<InputFields model='.first_name' ignore={true} inputType='text' type='text' placeholder='Enter your first name' label='First Name' value={profile.first_name} onChange={(e) => handleInputChange('first_name', e.target.value)} 
									/>
								</div>
								<div className='inputCol'>
									<InputFields model='.last_name' ignore={true} inputType='text' type='text' placeholder='Enter your last name' label='Last Name' value={profile.last_name} onChange={(e) => handleInputChange('last_name', e.target.value)} />
								</div>
								<div className='inputCol'>
									<InputFields model='.address' ignore={true} inputType='text' type='text' placeholder='Enter your address' label='Address' value={profile.address} onChange={(e) => handleInputChange('address', e.target.value)} />
								</div>
								<div className='inputCol'>
									<label className='formInputsLabel'>Country</label>
									<Control.select model='.country' onChange={handleCountryChange} value={profile.country} required>
										<option value=''>Select a country</option>
										{countries.map((country) => (
											<option key={country.isoCode} value={country.name}>
												{country.name}
											</option>
										))}
									</Control.select>
								</div>
								<div className='inputCol'>
									<label className='formInputsLabel'>State</label>
									<Control.select model='.state' onChange={handleStateChange} value={profile.state} disabled={!selectedCountry} required>
										<option value=''>Select a state</option>
										{states.map((state) => (
											<option key={state.isoCode} value={state.name}>
												{state.name}
											</option>
										))}
									</Control.select>
								</div>
								<div className='inputCol'>
									<label className='formInputsLabel'>City</label>
									<Control.select model='.city' onChange={handleCityChange} value={profile.city} disabled={!selectedState} required>
										<option value=''>Select a city</option>
										{cities.map((city) => (
											<option key={city.name} value={city.name}>
												{city.name}
											</option>
										))}
									</Control.select>
								</div>
								<div className='inputCol'>
									<InputFields model='.zipcode' inputType='text' type='text' placeholder='Enter your zip code' label='ZIP Code' value={profile.zipcode} onChange={(e) => handleInputChange('zipcode', e.target.value)} />
								</div>
								<div className='w-100'>
									<Control.button model='userActions.signup' className='loginButton' type='submit'>
										Update Profile
									</Control.button>
								</div>
							</Form>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	user: state.user,
});

export default connect(mapStateToProps)(Profile);
