import React from 'react';
import './tooltip.css';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
// import TooltipIcon from "../../global/img/tooltipIcon.svg";

function DashboardTip(props) {
	// const renderTooltip = (props) => (
	//   <Tooltip id="button-tooltip" className="tooltipBody" {...props}>
	//     <div>
	//       <p class="tooltipTitle">test</p>
	//       <br />
	//       <p class="tooltipDescription">test</p>
	//       <div class="playerPercentDiv">
	//         <div class="playerPercentInfoDiv displayTable">
	//           <div class="playerPercentInfoSquare playerPercentInfoSquareHigh displayTableCell"></div>
	//           <p class="playerPercentInfoLabel displayTableCell">Low</p>
	//           <p class="playerPercentInfoPercent displayTableCell">(0 - 13%)</p>
	//         </div>
	//         <div class="playerPercentInfoDiv displayTable">
	//           <div class="playerPercentInfoSquare playerPercentInfoSquareElevated displayTableCell"></div>
	//           <p class="playerPercentInfoLabel displayTableCell">Elevated</p>
	//           <p class="playerPercentInfoPercent displayTableCell">(13 - 24%)</p>
	//         </div>
	//         <div class="playerPercentInfoDiv displayTable">
	//           <div class="playerPercentInfoSquare playerPercentInfoSquarePoor displayTableCell"></div>
	//           <p class="playerPercentInfoLabel displayTableCell">High</p>
	//           <p class="playerPercentInfoPercent displayTableCell">(24 - 100%)</p>
	//         </div>
	//       </div>
	//     </div>
	//   </Tooltip>
	// );
	// return (
	//   <>
	//     <OverlayTrigger
	//       placement="auto"
	//       delay={{ show: 250, hide: 400 }}
	//       overlay={renderTooltip}
	//     >
	//       <img
	//         src={TooltipIcon}
	//         alt="Tooltip"
	//         className="tooltipIcon"
	//         // show={true}
	//       />
	//     </OverlayTrigger>
	//   </>
	// );
	const { title, description, component } = props;
	let content = (
		<div>
			<p class='tooltipTitle'>{title}</p>
			<p class='tooltipDescription'>{description}</p>
		</div>
	);
	component === 'player_irc' &&
		(content = (
			<div style={{ width: '100%' }}>
				<p class='tooltipTitle'>{title}</p>
				<p class='tooltipDescription'>{description}</p>
				<div class='playerPercentDiv'>
					<div class='playerPercentInfoDiv displayTable'>
						<div class='playerPercentInfoSquare playerPercentInfoSquareHigh displayTableCell'></div>
						<p class='playerPercentInfoLabel displayTableCell'>Low</p>
						<p class='playerPercentInfoPercent displayTableCell'>(0 - 13%)</p>
					</div>
					<div class='playerPercentInfoDiv displayTable'>
						<div class='playerPercentInfoSquare playerPercentInfoSquareElevated displayTableCell'></div>
						<p class='playerPercentInfoLabel displayTableCell'>Elevated</p>
						<p class='playerPercentInfoPercent displayTableCell'>(13 - 24%)</p>
					</div>
					<div class='playerPercentInfoDiv displayTable'>
						<div class='playerPercentInfoSquare playerPercentInfoSquarePoor displayTableCell'></div>
						<p class='playerPercentInfoLabel displayTableCell'>High</p>
						<p class='playerPercentInfoPercent displayTableCell'>(24 - 100%)</p>
					</div>
				</div>
			</div>
		));
	component === 'player_hpf' &&
		(content = (
			<div style={{ width: '100%' }}>
				<p class='tooltipTitle'>{title}</p>
				<p class='tooltipDescription'>{description}</p>
				<div class='playerPercentDiv'>
					<div class='playerPercentInfoDiv displayTable'>
						<div class='playerPercentInfoSquare playerPercentInfoSquarePoor displayTableCell'></div>
						<p class='playerPercentInfoLabel displayTableCell'>Poor</p>
						<p class='playerPercentInfoPercent displayTableCell'>(0 - 40%)</p>
					</div>
					<div class='playerPercentInfoDiv displayTable'>
						<div class='playerPercentInfoSquare playerPercentInfoSquareElevated displayTableCell'></div>
						<p class='playerPercentInfoLabel displayTableCell'>Below average</p>
						<p class='playerPercentInfoPercent displayTableCell'>(40 - 65%)</p>
					</div>
					<div class='playerPercentInfoDiv displayTable'>
						<div class='playerPercentInfoSquare playerPercentInfoSquareElevated displayTableCell'></div>
						<p class='playerPercentInfoLabel displayTableCell'>Above average</p>
						<p class='playerPercentInfoPercent displayTableCell'>(65 - 80%)</p>
					</div>
					<div class='playerPercentInfoDiv displayTable'>
						<div class='playerPercentInfoSquare playerPercentInfoSquareHigh displayTableCell'></div>
						<p class='playerPercentInfoLabel displayTableCell'>Peak</p>
						<p class='playerPercentInfoPercent displayTableCell'>(80 - 100%)</p>
					</div>
				</div>
			</div>
		));

	const renderTooltip = (props) => (
		<Tooltip id='button-tooltip' className='customTooltip' {...props}>
			<div>{content}</div>
		</Tooltip>
	);
	return (
		<OverlayTrigger placement='auto' delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
			<div className='generalTooltipIcon'>?</div>
		</OverlayTrigger>
	);
}

export default DashboardTip;
