import React, { useState } from 'react';
import { connect } from 'react-redux';
import dropdownArrow from './img/dropdownArrow.png';
// import DashboardTip from "../Tooltip/dashboardTip";
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { formatDate, monthDifference } from '../utils/utils';
import { showHealthyToReturnDate, calculateHeight } from '../utils/dashboardCalculations';

function PlayerPanel(props) {
	const [isOpen, handleIsOpen] = useState(false);
	const [rotateArrow, handleRotateArrow] = useState(false);

	const toggleOpen = () => {
		handleRotateArrow(!rotateArrow);
		handleIsOpen(!isOpen);
	};

	const { id, user, healthScoreStatus, players, panelTitle, openAll, sportsName, toggleRosterView, teamLogo, ignoreCategories, hideDropdown } = props;

	const menuClass = `dropdown-menu${isOpen || openAll ? ' show' : ''}`;
	const arrowClass = `${rotateArrow || isOpen || openAll ? 'matchupTitleDropdownArrowRotate' : ''}`;

	return (
		<div className='matchMainDiv'>
			<div id='matchGroup' className='dropdown' onClick={() => !hideDropdown && toggleOpen(id)}>
				{/* Dropdown Panel Button */}

				<button
					className='dropdown-toggle dropdownMenuButton'
					type='button'
					id={`dropdownMenuButton${id}`}
					data-toggle='dropdown'
					data-parent='#matchGroup'
					aria-haspopup='true'
					disabled={hideDropdown}
					onClick={() => {
						!hideDropdown && handleIsOpen(!isOpen);
						!hideDropdown && toggleRosterView && toggleRosterView();
					}}
				>
					<Row className='matchupButtonRow'>
						<Col lg={8} className='matchupTitleDropdownCol'>
							<div className='matchupTitleDropdown'>
								<p className='matchupTitle'>{panelTitle}</p>
								{!hideDropdown && <img src={dropdownArrow} alt='Dropdown Arrow' id='matchupTitleDropdownArrow' className={`matchupTitleDropdownArrow ${arrowClass}`} />}
							</div>
						</Col>
						<Col lg={3} className={`matchupStatusCol ${healthScoreStatus.background}`}>
							<div className='matchupInfoDiv'>
								<p className={`matchupStatus`}>{healthScoreStatus.status}</p>
							</div>
						</Col>
						{/* <Col lg={1}>
                <DashboardTip
                  tooltipClass="matchTooltip"
                  title={healthScoreStatus.status}
                  description={tooltip}
                />
              </Col> */}
					</Row>
				</button>
				<div className={`${menuClass}  matchMenuDiv`} aria-labelledby='dropdownMenuButton' data-parent='#matchGroup' id='menuID'>
					{/* Dropdown Items */}
					{!openAll ? (
						players.map((player, i) => {
							return (
								<div key={i} className='dropdown-item matchPlayerItem'>
									<Link
										className='routerLinkMatchup hover-delay'
										title={`${player.player_name}`}
										to={{
											pathname: `/${sportsName || 'nfl'}/player/${player.player_id}`,
											playerID: player.player_id
										}}
									>
										<Row className='matchPlayerItemRow'>
											<Col lg={12} className='matchPlayerItemCol'>
												<div className='matchPlayerDiv displayTable'>
													{<img src={teamLogo} alt='Player' className='matchPlayerImg displayTableCell' />}
													<div className='matchPlayerInfoDiv displayTableCell'>
														<p className='matchPlayerInfoName'>
															<span className='grey'>NAME:</span>
															<span className='matchPlayerInfo'>{player.nickname || player.player_name}</span>
														</p>
														<p className='matchPlayerInfoInjury'>
															<span className='grey'>POSITION:</span>
															<span className='matchPlayerInfo'>{player.position}</span>
														</p>
														<p className='matchPlayerInfoInjury'>
															<span className='grey'>TYPE OF INJURY:</span>
															<span className='matchPlayerInfo'>
																{/* {player.body_part || "No Recent Injury"} */}
																{monthDifference(new Date(player.injury_date), new Date()) < 18 ? player.body_part : 'No Recent Injury'}
															</span>
														</p>
														<p className='matchPlayerInfoHealth'>
															<span className='grey'>INJURY DATE:</span>
															<span className='matchPlayerInfo'>{player.injury_date && player.injury_date !== '0000-00-00 00:00:00' ? formatDate(true, player.injury_date, 'matchup') : 'No Recent Injury'}</span>
														</p>
														<p className='matchPlayerInfoHealth'>
															<span className='grey'>HEALTHY TO RETURN:</span>
															<span className='matchPlayerInfo'>{showHealthyToReturnDate(player?.recovery_return_date, 3) && player.recovery_return_date ? formatDate(true, player.recovery_return_date.split('T')[0], 'matchup') : 'No Recent Injury'}</span>
														</p>
														<p className='matchPlayerInfoInjury'>
															<span className='grey'>HEALTH SCORE:</span>
															<span className='matchPlayerInfo'>{player.gaming_score || 'N/A'}</span>
														</p>
													</div>
												</div>
											</Col>
										</Row>
									</Link>
								</div>
							);
						})
					) : (
						<div>
							<p className='fullRosterHpf'>
								{/* HPF is the impact that current and/or previous injuries have on the
                    performance of a player. The higher the number is, the less
                    impactful current/previous injuries will be on player performance. */}
								Health Score is the average impact that current and/or previous injuries have on the performance of a player. The higher the number is, the less impactful current/previous injuries will be on player performance.
							</p>
							{players &&
								players.map((player, i) => (
									<div key={i} className='fullRosterRowDiv'>
										<Link
											className='routerLinkMatchup hover-delay'
											to={{
												pathname: `/${sportsName || 'nfl'}/player/${player.player_id}`
											}}
											title={`${player.player_name}`}
										>
											<Row key={id} className='fullRosterRow'>
												<Col lg={2} className='fullRosterCol'>
													{<img src={teamLogo} alt={`${player.player_name}`} className='fullRosterImg' />}
												</Col>
												<Col lg={2} className='fullRosterCol'>
													<div className='fullRosterInfo'>
														<p className='grey matchPlayerLabel'>Name</p>
														<p className=''>
															<b>{player.nickname || player.player_name}</b>
														</p>
													</div>
												</Col>
												{!user.sportsID ? (
													<>
														<Col lg={2} className='fullRosterCol'>
															<div className='fullRosterInfo'>
																<p className='grey matchPlayerLabel'>Height</p>
																<p className=''>{calculateHeight(player.height)}</p>
															</div>
														</Col>
														<Col lg={2} className='fullRosterCol'>
															<div className='fullRosterInfo'>
																<p className='grey matchPlayerLabel'>Weight</p>
																<p className=''>{player.weight}lbs</p>
															</div>
														</Col>
													</>
												) : null}
												<Col lg={2} className='fullRosterCol'>
													<div className='fullRosterInfo'>
														<p className='grey matchPlayerLabel'>Age</p>
														<p className=''>{player.age}</p>
													</div>
												</Col>
												<Col lg={2} className='fullRosterCol'>
													<div className='fullRosterInfo'>
														<p className='grey matchPlayerLabel'>Health Score</p>
														<p className=''>{player.gaming_score || 'N/A'}</p>
													</div>
												</Col>
											</Row>
										</Link>
									</div>
								))}
							{!ignoreCategories && (
								<div className='fullRosterViewAllDiv'>
									<a href='#/' className='fullRosterViewAll hover-delay' onClick={() => toggleRosterView()}>
										VIEW POSITION CATEGORIES
									</a>
								</div>
							)}
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = (state) => ({
	isLoading: state.isLoading,
	isLoaded: state.isLoaded,
	teams: state.teams,
	playersInfo: state.playersInfo,
	match: state.match,
	user: state.user
});

export default connect(mapStateToProps)(PlayerPanel);
