import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { highlightColumnColor } from '../../utils/dashboardCalculations';

class playerHealthScore extends Component {
	componentDidMount() {}

	render() {
		const { player } = this.props;
		const highlightColor = highlightColumnColor(player.team_gaming_score, 'cardSpread');
		return (
			<div className={`cardSpreadDiv ${this.props.sportsName === 'nfl' && player.status === "Injured Reserve" ? 'injured-main' : '' }`}>
				<Row className="cardSpreadRow">
					<Col lg={12} className={`cardHighlight ${this.props.sportsName === 'nfl' && player.status === "Injured Reserve" ? 'injured' : 'nflclass' } ${highlightColor }`}>
						<div className='cardSpreadDiv'>
							<h4 className='cardSpread'>
								{
									this.props.sportsName === 'nfl' &&
									(
										player.status === "Injured Reserve" ?
										(	<>
											<span className='cardSpreadNoBetText'>INJURED</span>
											<div className='d-flex justify-content-center align-items-center quad_tag'>
												<span className='cardSpreadNumberTeam'>{player?.injury_body_part}</span> <span className='cardSpreadNumberTeam'>Did Not Pratice</span>
											</div>
											</>
										) : (
											<>
											<span className='cardSpreadNoBetText'>HEALTHY</span>
											</>
										)
									)
								}
								{
									this.props.sportsName !== 'nfl' &&
									(
										<>
											<span className='cardSpreadNoBetText'>Injury Risk</span>
											<span className='cardSpreadNumberTeam'>{player.irc_total} %</span>
										</>
									)
								}
							</h4>
						</div>
					</Col>
				</Row>
			</div>
		);
	}
}

export default playerHealthScore;
