import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import './style.css';
import { Row, Col, Container } from 'react-bootstrap';
import GeneralCover from '../GeneralCover';
import { FantasyScore, PowerOfInsideInjuries, ImpactOfInjuries, DoctorsEdge } from './articles';

function Articles(props) {
	const { user, carouselArticleImages } = props;
	const { FantasyScoresImg, PowerImg, ImpactImg, DoctorsEdgeImg } = carouselArticleImages;
	let { articlename } = useParams();
	if (user.isLoading) {
		return (
			<div id='fullHeight'>
				<div id='loading' />
			</div>
		);
	}

	let coverHeader, coverSubHeader, coverImg, Article;
	if (articlename?.toUpperCase() === 'FANTASYSCORE') {
		coverHeader = 'Improve Fantasy Scores and Maximize Sports Betting Earnings with Inside Injuries';
		coverImg = FantasyScoresImg;
		Article = FantasyScore;
	} else if (articlename?.toUpperCase() === 'POWEROFINSIDEINJURIES') {
		coverHeader = "The Power of Inside Injuries' Comprehensive Sports Injury Data";
		coverSubHeader = 'A Game-Changer for Sports Bettors';
		coverImg = PowerImg;
		Article = PowerOfInsideInjuries;
	} else if (articlename?.toUpperCase() === 'IMPACTOFINJURIES') {
		coverHeader = 'Understanding the Impact of Injuries on NFL Performance, Fantasy, and Sports Betting';
		coverImg = ImpactImg;
		Article = ImpactOfInjuries;
	} else if (articlename?.toUpperCase() === 'DOCTORSEDGE') {
		coverHeader = "Unveiling the Doctor's Edge";
		coverSubHeader = 'How Comprehensive NFL Injury Data Can Transform Sports Betting Strategies';
		coverImg = DoctorsEdgeImg;
		Article = DoctorsEdge;
	}

	return (
		<div>
			<GeneralCover page='articles' arrowScrollID='#articlesSection' customHeaderContent={coverHeader} customSubHeaderContent={coverSubHeader} customCoverImg={coverImg} />
			<div id='articlesSection' style={{ height: '60px' }}></div>
			<section className='articlesSection'>
				<div className='articlesDiv'>
					<Container>{articlename ? <Article /> : <h1>Article not Available</h1>}</Container>
				</div>
			</section>
		</div>
	);
}

const mapStateToProps = (state) => ({
	user: state.user
});

export default connect(mapStateToProps)(Articles);
