import React, { Component } from 'react';
import { Col } from 'react-bootstrap';
import { highlightColumn, highlightColumnColor } from '../../utils/dashboardCalculations';

class cardColumns extends Component {
	componentDidMount() {}

	render() {
		const { game, userAllowed, handleSubscription, sportsID } = this.props;
		const homeHealthScore = game?.home_team_gaming_score;
		const awayHealthScore = game?.away_team_gaming_score;
		const highlightCol = highlightColumn(homeHealthScore, awayHealthScore);
		const homeHighlightColor = highlightColumnColor(homeHealthScore, 'cardHighlight');
		const awayHighlightColor = highlightColumnColor(awayHealthScore, 'cardHighlight');

		return (
			<div id='cardTeamRow' className='cardTeamRow'>
				<Col lg={6} className={`${(userAllowed || handleSubscription) && highlightCol === 'away' && 'cardHighlight ' + awayHighlightColor} cardTeamCol cardTeamColLeft col-xs-6`}>
					<div className='cardTeamDiv cardTeamDivLeft'>
						<p className='cardTeamType'>Away Team</p>
						<img src={game.away_team_logo} alt='' className='cardTeamLogo' />
						<p className='cardTeamName'>
							{sportsID !== 3 ? (
								<>
									{game.away_team_city}
									<br />
								</>
							) : null}
							{game.away_team_name}
						</p>
					</div>
				</Col>
				<Col lg={6} className={`${(userAllowed || handleSubscription) && highlightCol === 'home' && 'cardHighlight ' + homeHighlightColor}  cardTeamCol cardTeamColRight col-xs-6`}>
					<div className='cardTeamDiv cardTeamDivRight'>
						<p className='cardTeamType'>Home Team</p>
						<img src={game.home_team_logo} alt='' className='cardTeamLogo' />
						<div className={`cardTeamSpreadDiv`}>
							<p className='cardTeamName'>
								{sportsID !== 3 ? (
									<>
										{game.home_team_city}
										<br />
									</>
								) : null}
								{game.home_team_name}
							</p>
						</div>
					</div>
				</Col>
			</div>
		);
	}
}

export default cardColumns;
