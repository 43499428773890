/* eslint-disable react/jsx-pascal-case */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Control, Errors, actions } from 'react-redux-form';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import 'react-phone-number-input/style.css';
// import PhoneInput from "react-phone-number-input";
import PhoneInput from 'react-phone-number-input/input';
import { isValidPhoneNumber } from 'react-phone-number-input';

import TooltipIcon from '../../global/img/tooltipIcon.svg';
import { currencyFormatter, calculateAge } from '../utils';
import validator from 'validator';
import './style.css';

class InputFields extends Component {
	constructor() {
		super();
		this.state = {
			isFocused: false,
			showPassword: false
		};
	}

	// handleFocus = () => this.setState({ isFocused: true });
	handleBlur = (m, v, option) => {
		// if (!v?.value) {
		//   this.setState({ isFocused: false, inputPadding: "15px" });
		// }
		if (option === 'currency' && v?.value) {
			// if (v?.value && !v?.value?.includes("$")) {
			this.props.dispatch(actions.change(v?.model, currencyFormatter.format(v?.value?.replace(/[^0-9.]/g, ''))));
		}
	};

	displayOptions(options) {
		const { defaultOption } = this.props;
		const optionArr = [
			<option key={0} value=''>
				{defaultOption}
			</option>
		];
		options.map((data, i) => {
			return optionArr.push(
				<option key={++i} value={data.value}>
					{data.label}
				</option>
			);
		});
		return optionArr;
	}

	formatCurrency = (input) => {
		if (!input) return;
		// New
		const parsedInput = input.replace(/[^0-9.]/g, '');
		return currencyFormatter.format(parsedInput);
	};

	normalizeAmount = (val) => {
		return val.replace(/,/g, '');
	};

	validateCreditCard = (value) => {
		if (!value) {
			return;
		}
		return validator.isCreditCard(value);
	};

	validatePassword = (value) => {
		if (
			value &&
			validator.isStrongPassword(value, {
				minLength: 8,
				minLowercase: 1,
				minUppercase: 1,
				minNumbers: 1,
				minSymbols: 1
			})
		) {
			return value;
		} else {
			return false;
		}
	};

	validateZipCode = (value) => {
		if (value && /^\d{5}$/.test(value)) {
			return value;
		} else {
			return false;
		}
	};

	validateLength = (val, minLength, maxLength) => {
		if (val?.length && ((minLength && maxLength && val?.length >= minLength && val?.length <= maxLength) || (minLength && !maxLength && val?.length >= minLength) || (maxLength && !minLength && val?.length <= maxLength) || (!minLength && !maxLength))) {
			return true;
		} else if (!val?.length) {
			return true;
		} else {
			return false;
		}
	};

	showErrors = (e) => {
		const { inputType } = this.props;

		if (inputType?.toUpperCase() === 'RADIO') {
			return false;
			// return {
			//   touched: true,
			//   focus: true,
			// };
		}
		return {
			touched: true,
			focus: false
		};
	};

	handleCurrencyLimits = (val, isMin, isMax) => {
		let { minValue, maxValue } = this.props;
		const amount = parseFloat(val?.replace(/[^0-9.]/g, ''));
		if (isMin && !isNaN(amount) && amount >= minValue) {
			return true;
		} else if (isMax && !isNaN(amount) && amount <= maxValue) {
			return true;
		}
		return false;
	};

	render() {
		const {
			model,
			standardLabel,
			label,
			tooltip,
			// errorLabel,
			placeholder,
			type,
			options,
			inputType,
			value,
			customLabel,
			customValue,
			textAreaHeight,
			ignore,
			disabled,
			// className,
			dispatch,
			onChange,
			onClick,
			onBlur,
			maxLength,
			minLength,
			maxValue,
			maxValueMessage,
			minValue,
			minValueMessage,
			acceptedValue,
			acceptedValueMessage,
			excludedValues,
			excludedValuesMessage,
			setPhoneErrors
		} = this.props;
		const { isFocused, showPassword } = this.state;

		const customLabelStyle = {
			position: 'absolute',
			right: customLabel && '15px',
			top: !isFocused && !standardLabel ? 30 : 0,
			fontSize: !isFocused && !standardLabel ? 20 : 14,
			color: '#b5bbce',
			transitionDuration: '.4s',
			textTransform: 'uppercase',
			fontWeight: 400,
			padding: customLabel && '5px',
			borderRadius: customLabel && '5px'
		};

		const email = (value) => value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(value);
		const phone = (value) => value && /^[2-9]\d{2}[2-9]\d{2}\d{4}$/.test(value);
		// const phone = (value) =>
		//   value && /^\+[0-9]{1,3}\.[0-9]{4,14}(?:x.+)?$/.test(value);
		const date = (value) => value && /^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/.test(value);

		const renderTooltip = (props) => (
			<Tooltip id='button-tooltip' className='tooltipBody' {...props}>
				{tooltip}
			</Tooltip>
		);

		return (
			<React.Fragment>
				{inputType === 'text' && (
					<React.Fragment>
						{label ? (
							<label className='formInputsLabel'>
								{label}
								{tooltip && (
									<OverlayTrigger placement='auto' delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
										<img
											src={TooltipIcon}
											alt='Tooltip'
											className='tooltipIcon'
											// show={true}
										/>
									</OverlayTrigger>
								)}
							</label>
						) : null}
						<Control.text
							className='formInputs hoverDelay'
							model={model}
							type={type === 'password' ? (showPassword ? 'text' : 'password') : type}
							// onFocus={this.handleFocus}
							// autoComplete="off"
							onBlur={(m, v) => {
								this.handleBlur(m, v);
								onBlur && onBlur();
							}}
							onChange={onChange}
							value={value}
							placeholder={placeholder}
							validators={{
								required: (val) => (type !== 'password' ? (!ignore ? val?.length : true) : true),
								isValidEmail: (val) => (type !== 'email' ? true : !val?.length ? true : email(val)),
								isValidPassword: (val) => (type !== 'password' ? true : this.validatePassword(val)),
								isValidZipCode: (val) => (type !== 'zipcode' ? true : this.validateZipCode(val)),
								isValidLength: (val) => this.validateLength(val, minLength, maxLength)
							}}
							disabled={disabled}
						/>
						{type === 'password' && (
							<a href='#/' onClick={() => this.setState({ showPassword: !showPassword })} className='showPasswordIcon' title={showPassword ? 'Hide Password' : 'Show Password'} alt={showPassword ? 'Hide Password' : 'Show Password'}>
								{showPassword ? (
									<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='#0050f0' className='bi bi-eye-slash-fill' viewBox='0 0 16 16'>
										<path d='m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z' />
										<path d='M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z' />
									</svg>
								) : (
									<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='#0050f0' className='bi bi-eye-fill' viewBox='0 0 16 16'>
										<path d='M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z' />
										<path d='M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z' />
									</svg>
								)}
							</a>
						)}
					</React.Fragment>
				)}
				{inputType === 'phone' && (
					<React.Fragment>
						{label ? <label className='formInputsLabel'>{label}</label> : null}
						<Control.text
							className='formInputs hoverDelay'
							model={model}
							type={type}
							// onFocus={this.handleFocus}
							onBlur={(m, v) => this.handleBlur(m, v)}
							value={value}
							validators={{
								required: (val) => (type !== 'password' ? val?.length : true),
								isValidPhone: (val) => (type !== 'tel' ? true : !val?.length ? true : phone(val))
							}}
							disabled={disabled}
						/>
					</React.Fragment>
				)}
				{inputType === 'customPhone' && (
					<React.Fragment>
						{/* <div style={{ marginTop: "-2px" }} /> */}
						{label ? <label className='formInputsLabel'>{label}</label> : null}
						<PhoneInput
							className='formInputs hoverDelay'
							// style={{ paddingBottom: inputPadding }}
							// placeholder="Enter phone number"
							// onFocus={this.handleFocus}
							onBlur={(m, v) => this.handleBlur(m, v)}
							country='US'
							defaultCountry='US'
							value={value}
							onChange={(phone) => dispatch(actions.change(model, phone))}
							error={value ? (isValidPhoneNumber(value) ? setPhoneErrors(true) : setPhoneErrors()) : 'Phone number required'}
						/>
					</React.Fragment>
				)}
				{inputType === 'date' && (
					<React.Fragment>
						{label ? <label className='formInputsLabel'>{label}</label> : null}
						<Control.text
							className='formInputs hoverDelay'
							// style={{
							//   paddingBottom: inputPadding,
							//   color: `${!isFocused ? "transparent" : "#000000"}`,
							// }}
							model={model}
							type={type}
							min='1900-01-01'
							// max="2030-12-31"
							// onFocus={this.handleFocus}
							required
							// onBlur={(m, v) => this.handleBlur(m, v)}
							value={value}
							validators={{
								required: (val) => (type !== 'password' ? val?.length : true),
								isValidDate: (val) => (!val?.length ? true : date(val)),
								isMinValue: (val) => (!val?.length || !minValue ? true : minValue <= calculateAge(val))
							}}
							disabled={disabled}
						/>
					</React.Fragment>
				)}
				{inputType === 'number' && (
					<React.Fragment>
						{label ? (
							<label className='formInputsLabel'>
								{label}
								{tooltip && (
									<OverlayTrigger placement='auto' delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
										<img
											src={TooltipIcon}
											alt='Tooltip'
											className='tooltipIcon'
											// show={true}
										/>
									</OverlayTrigger>
								)}
							</label>
						) : null}
						<Control.text
							className='formInputs hoverDelay'
							model={model}
							type={inputType}
							value={value}
							// onFocus={this.handleFocus}
							onBlur={(m, v) => {
								this.handleBlur(m, v);
								onBlur && onBlur();
							}}
							min={minLength || 0}
							placeholder={placeholder}
							// parser={this.checkNormalize}
							validators={{
								required: (val) => val?.length,
								isValidCreditCard: (val) => (type !== 'cc' || !val ? true : this.validateCreditCard(val)),
								// isValidMonth: (val) =>
								//   type !== "month" || !val
								//     ? true
								//     : this.validateLength(val, minLength, maxLength),
								// isValidYear: (val) =>
								//   type !== "year" || !val
								//     ? true
								//     : this.validateLength(val, minLength, maxLength),
								isValidLength: (val) => this.validateLength(val, minLength, maxLength)
							}}
							disabled={disabled}
						/>
					</React.Fragment>
				)}
				{inputType === 'currency' && (
					<React.Fragment>
						{label ? (
							<label className='formInputsLabel'>
								{label}
								{tooltip && (
									<OverlayTrigger placement='auto' delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
										<img
											src={TooltipIcon}
											alt='Tooltip'
											className='tooltipIcon'
											// show={true}
										/>
									</OverlayTrigger>
								)}
							</label>
						) : null}
						<Control.text
							className='formInputs hoverDelay'
							model={model}
							type={type}
							// parser={this.formatCurrency}
							onChange={onChange}
							// onFocus={this.handleFocus}
							// value={value}
							onBlur={(m, v) => {
								this.handleBlur(m, v, 'currency');
								onBlur && onBlur();
							}}
							validators={{
								required: (val) => val?.length,
								isMinValue: (val) => (minValue && val ? this.handleCurrencyLimits(val, true, false) : true),
								isMaxValue: (val) => (maxValue && val ? this.handleCurrencyLimits(val, false, true) : true)
							}}
							disabled={disabled}
						/>
					</React.Fragment>
				)}
				{inputType === 'textarea' && (
					<React.Fragment>
						{label ? <label className='formInputsLabel'>{label}</label> : null}
						<Control.textarea
							className='formInputsTextArea hoverDelay'
							style={{ height: textAreaHeight || '150px' }}
							model={model}
							type={type}
							// onFocus={this.handleFocus}
							onBlur={(m, v) => this.handleBlur(m, v)}
							placeholder={placeholder || 'Please enter your comments here...'}
							disabled={disabled}
							validators={{
								required: (val) => (ignore ? true : val?.length)
							}}
						/>
					</React.Fragment>
				)}
				{inputType === 'select' && (
					<React.Fragment>
						{!customLabel ? <label className='formInputsLabel'>{customLabel}</label> : null}
						<Control.select
							className='formInputsSelect hoverDelay'
							model={model}
							type={type}
							value={value}
							validators={{
								required: (val) => (ignore ? true : val?.length),
								isAcceptedValue: (val) => (acceptedValue && val ? val === acceptedValue : true),
								isExcludedValues: (val) => (excludedValues?.length && val ? !excludedValues?.includes(val) : true)
							}}
							disabled={disabled}
							onChange={onChange}
						>
							{this.displayOptions(options)}
						</Control.select>
						{customLabel && (
							<label style={customLabelStyle}>
								{customLabel}
								<span className='' style={{ color: '#b5bbce' }}>
									{' '}
									{customValue?.toFixed(1)}
								</span>
							</label>
						)}
					</React.Fragment>
				)}
				{inputType === 'checkbox' && (
					<React.Fragment>
						{label ? (
							<label className='checkboxContainer'>
								{label}
								<Control.checkbox
									id={value}
									model={model}
									value={value}
									disabled={disabled}
									validators={{
										required: (val) => (ignore ? true : val)
									}}
								/>
								<span className='checkmark' />
							</label>
						) : null}
					</React.Fragment>
				)}
				{inputType === 'radio' && (
					<React.Fragment>
						{label ? (
							<label className='radioContainer'>
								{label}
								<Control.radio
									id={value}
									name={model}
									model={model}
									value={value}
									disabled={disabled}
									validators={{
										required: (val) => (ignore ? true : val?.length),
										isAcceptedValue: (val) => (acceptedValue ? val === acceptedValue : true)
									}}
									onChange={onChange}
									onClick={onClick}
								/>
								<span className='radioCheckmark'></span>
							</label>
						) : null}
					</React.Fragment>
				)}
				<Errors
					className='formInputError'
					model={model}
					messages={{
						// required: `Please provide a ${errorLabel || label}`,
						required: `Required*`,
						isValidEmail: 'Please enter a valid email',
						isValidPhone: 'Please enter a valid phone number',
						isValidZipCode: 'Please enter a valid zip code',
						isValidPassword: 'Your password must include 8 characters, 1 uppercase letter, 1 number, and 1 symbol',
						isValidCreditCard: 'Credit Card number is invalid',
						isValidMonth: 'Please enter a correct month',
						isValidYear: 'Please enter a correct year',
						isValidLength: `${
							minLength && maxLength && minLength !== maxLength
								? 'Please enter a value between ' + minLength + '-' + maxLength + ' characters'
								: minLength && maxLength && minLength === maxLength
									? 'Please enter a value ' + minLength + ' characters long'
									: minLength
										? 'Please enter at least ' + minLength + ' characters'
										: maxLength
											? 'Please enter less than ' + maxLength + ' characters'
											: 'Invalid Length'
						}`,
						isAcceptedValue: acceptedValueMessage,
						isExcludedValues: excludedValuesMessage,
						isMinValue: minValueMessage,
						isMaxValue: maxValueMessage
					}}
					show={this.showErrors()}
					// show={{ touched: true, focus: false }}
				/>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	user: state.user,
	signup: state.signup
});

export default connect(mapStateToProps)(InputFields);
