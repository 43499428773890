import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import '../style.css';
import { Country, State, City } from 'country-state-city';
import { Col } from 'react-bootstrap';
import CardBets from './cardBets';
import CardColumns from './cardColumns';
import { Modal } from 'react-bootstrap';
import Logo from '../../../global/img/logo_full_white.png';
import InputFields from '../../InputFields';
import { Form, Control } from 'react-redux-form';
import { formatDate } from '../../utils/utils';

function MatchupCard(props) {
	const { schedules, match, user, purchasePlan, sportsName, dispatch, userActions, nbaPage } = props;

	const [showModal, setShowModal] = useState(false);

	const handleLinkClick = (e) => {
		if (!user?.login && nbaPage) {
		  e.preventDefault();
		  setShowModal(true)
		}
	};

	const handleSignup = (data) => {
		dispatch({
			type: 'SIGNUP',
			payload: data
		});
	};

	const handleSubscription = (gameArray, gameId) => {
		for (let i = 0; i <= gameArray.length; i++) {
			if (gameArray[i] === gameId) {
				return true;
			}
		}
	};

	if (!schedules) return null;
	if (!match) return null;

	if (purchasePlan.isLoaded && !purchasePlan.error) {
		window.location.reload();
	}

	let games = schedules.data && schedules.data.length !== 0 && schedules.data.schedules;

	let gameArray;
	if (user?.games) {
		gameArray = user.games.split(', ');
	}
	return (
		<React.Fragment>
			{games?.length ? (
				games.map((game, i) => (
					<Col id={game.game_id} lg={4} key={i} className='cardCol'>
						<div className='cardColDiv'>
							{(user?.login || (!user?.login && i == 0)) ? (
								<Col lg={12} className='cardDetailsCol'>
									<p className='cardDetails text-center' style={{ fontSize: '20px' }}>
										Team Health Scores
									</p>
								</Col>
							) : null}

							{/* Game Spread Section */}

							<div className='cardSpreadDiv'>{(user?.login || (!user?.login && i == 0)) ? <CardBets game={game} /> : <div style={{ padding: '27.5px 0' }} />}</div>

							{/* Game Home/Away Team Columns */}

							<CardColumns handleSubscription={gameArray && handleSubscription(gameArray, game.game_id)} game={game} gameArray={gameArray} userAllowed={(user?.login || (!user?.login && i == 0))} sportsID={user?.sportsID} />

							<Col lg={12} className='cardViewGameCol'>
								<Link
									className='cardViewGameButton hover-delay'
									title='View Game'
									to={{
										pathname: `/${sportsName || 'nfl' || 'nfl'}/matchup/${game.game_id}`,
										match: match
									}}
									onClick={handleLinkClick}
								>
									{(user?.login || (!user?.login && i == 0)) ? 'VIEW MATCHUP' : 'LOG IN OR JOIN FOR FREE'}
								</Link>
							</Col>

							{/* Game Spread */}

							<Col lg={12} className='cardDetailsCol'>
								<p className='cardDetails cardDate text-center' style={{ fontSize: '14px' }}>
									{(game.date_time && formatDate(false, game.date_time?.date)) || (game.day && formatDate(false, game.day)) ? (game.date_time ? formatDate(false, game.date_time?.date) : formatDate(false, game.day)) : 'TBD'}
								</p>
							</Col>
						</div>
					</Col>
				))
			) : (
				<h2 style={{ textAlign: 'center', margin: '30px auto' }}>No games available currently, please check again later.</h2>
			)}

			<Modal show={showModal} onHide={() => setShowModal(false)} centered size='lg'>
				<Modal.Body>
					<section className='signupMainSection'>
						<div className='signupMainDiv'>
							<div className='text-center'>
								<img src={Logo} alt='Inside Injuries Logo' className='signupLogo' />
								<p className='loginFlowText'>
									Already have an account?
									<a href='/login' className='loginFlowLink ps-2'>
										Login here
									</a>
								</p>
							</div>
							<div className='signupFormDiv'>
 
								<>
								<Form model="userActions.signup" onSubmit={handleSignup}>
								<div className="inputCol">
									<InputFields model=".first_name" inputType="text" type="text" placeholder="Enter your first name" label="First Name" />
								</div>
								<div className="inputCol">
									<InputFields model=".email" inputType="text" type="email" placeholder="Enter your email" label="Email" />
								</div>
								<div className="inputCol">
									<InputFields model=".password" inputType="text" type="password" placeholder="Enter your password" label="Password" />
								</div>
								<div className="w-100">
									{/* eslint-disable-next-line react/jsx-pascal-case */}
									<Control.button model="userActions.signup" className="loginButton" type="submit">
										SIGN UP
									</Control.button>
								</div>
							</Form>
							<p className='signupHeaderText'>Join for free and get access to all injury data.</p>

								</>
								
							</div>
						</div>
					</section>
				</Modal.Body>
			</Modal>
		</React.Fragment>
	);
}

const mapStateToProps = (state) => ({
	isLoading: state.isLoading,
	isLoaded: state.isLoaded,
	schedules: state.schedules,
	match: state.match,
	user: state.user,
	purchasePlan: state.purchasePlan,
	sources: state.sources
});

export default connect(mapStateToProps)(MatchupCard);
