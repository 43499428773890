import {
	ADMIN_DASHBOARD_SEARCH_SUBMIT,
	ADMIN_DASHBOARD_SEARCH_SUCCESS,
	ADMIN_DASHBOARD_SEARCH_ERROR,
	ADMIN_DASHBOARD_CHANGE_SUBMIT,
	ADMIN_DASHBOARD_CHANGE_SUCCESS,
	ADMIN_DASHBOARD_CHANGE_ERROR,
	ADMIN_DASHBOARD_INSERT_SUBMIT,
	ADMIN_DASHBOARD_INSERT_SUCCESS,
	ADMIN_DASHBOARD_INSERT_ERROR,
	GET_DASHBOARD_OPTIONS,
	GET_DASHBOARD_OPTIONS_SUCCESS,
	GET_DASHBOARD_OPTIONS_ERROR,
	GET_ADMIN_CSV,
	GET_ADMIN_CSV_SUCCESS,
	GET_ADMIN_CSV_ERROR,
	RUN_CALCULATIONS,
	RUN_CALCULATIONS_SUCCESS,
	RUN_CALCULATIONS_ERROR,
	GET_TEAM_HEALTH_SCORE_NFL,
	GET_TEAM_HEALTH_SCORE_NFL_SUCCESS,
	GET_TEAM_HEALTH_SCORE_NFL_ERROR
} from './constants';

const initialState = {
	isLoaded: false,
	isLoading: false
	// data: {}
};

const userInitialState = {
	payload: null
};

export const adminDashboardCSV = (state = initialState, { type, payload = '' }) => {
	switch (type) {
		case GET_ADMIN_CSV:
			return {
				...state,
				isLoading: true
			};
		case GET_ADMIN_CSV_SUCCESS:
			return {
				isLoading: false,
				data: payload
			};
		case GET_ADMIN_CSV_ERROR:
			return {
				...state,
				isLoading: false,
				error: 'There was an error retrieving the files'
			};
		default:
			return state;
	}
};
export const adminTeamHealthScoreNFL = (state = initialState, { type, payload = '' }) => {
	switch (type) {
		case GET_TEAM_HEALTH_SCORE_NFL:
			return {
				...state,
				isLoading: true
			};
		case GET_TEAM_HEALTH_SCORE_NFL_SUCCESS:
			return {
				...state,
				success: true,
				isLoading: false,
				message: payload.message,
				error: false,
				records: payload?.records || [],
				total_records: payload?.total_records || 0
			};
		case GET_TEAM_HEALTH_SCORE_NFL_ERROR:
			return {
				...state,
				success: false,
				isLoading: false,
				message: payload.message,
				error: true
			};
		default:
			return state;
	}
};

export const adminDashboardOptions = (state = initialState, { type, payload = '' }) => {
	switch (type) {
		case GET_DASHBOARD_OPTIONS:
			return {
				...state,
				isLoading: true
			};
		case GET_DASHBOARD_OPTIONS_SUCCESS:
			return {
				isLoading: false,

				data: payload
			};
		case GET_DASHBOARD_OPTIONS_ERROR:
			return {
				...state,
				isLoading: false,
				error: 'There was an error retrieving the admin dashboard options'
			};
		default:
			return state;
	}
};

export const adminDashboardSearch = (state = initialState, { type, payload = '' }) => {
	switch (type) {
		case ADMIN_DASHBOARD_SEARCH_SUBMIT:
		case ADMIN_DASHBOARD_CHANGE_SUBMIT:
		case ADMIN_DASHBOARD_INSERT_SUBMIT:
			return {
				...state,
				isLoading: true
			};
		case ADMIN_DASHBOARD_SEARCH_SUCCESS:
			return {
				...state,
				isLoading: false,
				info: payload.info,
				details: payload.details,
				ircTable: payload.ircTable,
				hpfTable: payload.hpfTable,
				rawCrawler: payload.rawCrawler,
				convertedCrawler: payload.convertedCrawler,
				teamTable: payload.teamTable,
				schedulesTable: payload.schedulesTable,
				heatmapTable: payload.heatmapTable,
				message: payload.message,
				error: false
			};
		case ADMIN_DASHBOARD_CHANGE_SUCCESS:
			return {
				...state,
				success: true,
				isLoading: false,

				message: payload.message,
				error: false
			};
		case ADMIN_DASHBOARD_INSERT_SUCCESS:
			return {
				...state,
				success: true,
				isLoading: false,

				message: payload.message,
				error: false
			};
		case ADMIN_DASHBOARD_SEARCH_ERROR:
		case ADMIN_DASHBOARD_CHANGE_ERROR:
		case ADMIN_DASHBOARD_INSERT_ERROR:
			return {
				...state,
				success: false,
				isLoading: false,
				message: payload.message,
				error: true
			};
		default:
			return state;
	}
};

export const adminDashboardCalculations = (state = initialState, { type, payload = '' }) => {
	switch (type) {
		case RUN_CALCULATIONS:
			return {
				...state,
				isLoading: true
			};
		case RUN_CALCULATIONS_SUCCESS:
			return {
				...state,
				success: true,
				isLoading: false,
				message: payload.message,
				error: false
			};
		case RUN_CALCULATIONS_ERROR:
			return {
				...state,
				success: false,
				isLoading: false,
				message: payload.message,
				error: true
			};
		case ADMIN_DASHBOARD_SEARCH_SUBMIT:
		case ADMIN_DASHBOARD_CHANGE_SUBMIT:
		case ADMIN_DASHBOARD_INSERT_SUBMIT:
			return {
				isLoading: false,
				message: payload.message
			};
		default:
			return state;
	}
};
