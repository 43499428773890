import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { useCountdown } from './countdownTimer';
import './style.css';

function GameStartTimer(props) {
	const { sportsID } = props;
	const [days, hours, minutes, seconds] = useCountdown(props);
	return (
		<>
			{days + hours + minutes + seconds <= 0 ? null : (
				<p className='userHeadCountdown'>
					{days} days {hours} hours {minutes} minutes {seconds} seconds until
					{sportsID === 1 ? ' kickoff' : ''}
					{sportsID === 2 ? ' tipoff' : ''}
				</p>
			)}
		</>
	);
}

function UserHeader(props) {
	const { matchup, teams, player, backgroundColor, padding, username, user, title, match, hideUserHeader, toggleScheduleView, clearMatch, clearPlayer, game } = props;

	// const userHeaderLegend =
	//   "ADJUSTED LINE EDGE: The true line as determined by our data algorithm, inclusive of injury risk analysis and projected player performance for both teams.";
	const userHeaderLegend = '';
	const { sportsName } = user;
	const gameID = match && match.match && match.match[0] && match.match[0].game_id;
	const teamID = teams && teams.teamInfo && teams.teamInfo[0] && teams.teamInfo[0].team_id;

	return (
		<div>
			{!matchup || !teams ? (
				<section className='userHeaderSection userHeaderSectionPadding container-fluid'>
					<Row className='userHeaderTextRow'>
						<Col lg={2} className='userHeaderNameCol'>
							<p className='userHeaderName'>Hi {username && username.split(' ')[0]}.</p>
						</Col>
						<Col lg={8} className='userHeaderTitleCol'>
							<p className='userHeaderTitle'>{title}</p>
						</Col>
						{/* <Col lg={2} className="userHeaderDateCol">
                <div className="userHeaderDateDiv">
                  <p className="userHeaderDateDashboard">
                    Current Time: {formatDate(true, null)}
                  </p>
                </div>
              </Col> */}
					</Row>
				</section>
			) : (
				<section className='userHeaderSection userHeaderMatchSection' style={{ backgroundColor: backgroundColor, padding: padding }}>
					<Row className='userHeaderTextRow'>
						<Col lg={12} className='userHeaderNameCol userHeaderNameMatchCol'>
							{!player && <p className='userHeaderDescription'>{userHeaderLegend}</p>}
							<div className='userHeaderDateDiv'>
								{!hideUserHeader ? (
									<div className='userHeaderNavDiv'>
										<Link
											onClick={() => (!player ? clearMatch() : clearPlayer())}
											className='userHeaderNavLink hover-delay'
											to={{
												pathname: `/${sportsName || 'nfl'}/upcominggames`,
												search: teamID ? '?teamView=true' : ''
											}}
										>
											<p className='userHeaderNavItem'>Team Scores</p>
										</Link>
										{teamID || gameID ? (
											<>
												<span className='userHeaderNavArrow'>{`>`}</span>
												<Link
													onClick={() => (!player ? clearMatch() : clearPlayer())}
													className='userHeaderNavLink hover-delay'
													to={{
														pathname: `/${sportsName || 'nfl'}/${gameID ? 'matchup' : 'team'}/${gameID || teamID}`
													}}
												>
													<p className='userHeaderNavItem'>{gameID ? 'Matchup' : 'Team'}</p>
												</Link>
											</>
										) : null}
										{player ? (
											<>
												<span className='userHeaderNavArrow'>{`>`}</span>
												<p className='userHeaderNavItem'>Player</p>
											</>
										) : null}
										{game?.date_time?.date ? <GameStartTimer gameDate={game?.date_time?.date} sportsID={user?.sportsID} /> : null}
									</div>
								) : (
									<a onClick={() => toggleScheduleView()} className='userHeaderClose hover-delay' href='#/'>
										<div className='userHeaderCloseTable'>
											<p className='userHeaderCloseText'>{'Back to Team View'}</p>
											<p className='userHeaderCloseIcon'>X</p>
										</div>
									</a>
								)}
							</div>
						</Col>
					</Row>
				</section>
			)}
		</div>
	);
}

const mapStateToProps = (state) => ({
	match: state.match,
	teams: state.teams,
	user: state.user
});

export default connect(mapStateToProps)(UserHeader);
