import React from 'react';
import { connect } from 'react-redux';
import './style.css';
import fsta2017 from './img/fsta2017.png';
import { Row, Col, Button } from 'react-bootstrap';
import Matchup from './img/matchup.png';
import Category from './img/positionCategories.png';
import MatchupCard from './img/NFLGamePreview.png';
import { Event } from '../Tracking';
// import ReactPlayer from "react-player";
import GeneralCover from '../GeneralCover';

function About(props) {
	const { user, dispatch, sources } = props;
	if (user.isLoading) {
		return (
			<div id='fullHeight'>
				<div id='loading' />
			</div>
		);
	}
	return (
		<div>
			<GeneralCover page='about' arrowScrollID='#aboutScienceSection' />
			<section id='aboutScienceSection' className='aboutScienceSection container-fluid'>
				<Row className='scienceRow'>
					<Col lg={12} className='scienceCol'>
						<div className='scienceDiv'>
							<h1 className='aboutScienceHeader'>The science behind the science</h1>
							<p className='aboutScienceText'>
								Pioneered from extensive medical research and shaped by the expertise of leading orthopedic surgeons, radiologists, and data scientists, the Inside Injuries algorithm harnesses the power of over one million data points. These data points aren't just numbers in a spreadsheet - they're a blueprint. We meticulously analyze each player's injury
								history - detailing body part, severity, position, and more. Using this information, we not only shed light on a player's current performance potential but also forecast their injury risk moving forward.
								<br />
								<br />
								The data doesn't stop there, though. Once gathered, every number and metric is funneled into a subsequent algorithm, which paints a complete picture of each team's overall health.
								<br />
								<br />
								<b className='aboutScienceSubHeader'>So how can you use our analysis to win? Let's start by taking a look at our Team Health Scores for each matchup.</b>
							</p>
						</div>
					</Col>
				</Row>
				<Row className='aboutScienceRow'>
					<Col lg={6} className='aboutScienceCol aboutScienceColLeft'>
						<div className='aboutScienceDiv'>
							<p className='aboutScienceText'>
								In sports betting, knowledge is key. But at Inside Injuries, we give you something even better: expert medical insight. We combine top Sports Medicine Expertise with injury data, so you're not just informed—you're ahead of the game. Want to know how injuries truly affect teams? Our sports doctors provide the answers.
								<br />
								<br />
								With our deep data and expert analysis, you get the clear edge. Whether you're making a bet or picking your fantasy team, we set you up to win.
								<br />
								<br />
								Built on the foundation of the industry's most extensive sports injury database, our predictive models provide you with a glimpse into the future performance of players and teams. Think of it as having a team doctor's perspective right at your fingertips.
								<br />
								<br />
								With our Health Scores, you'll understand your fantasy team inside and out, staying one step ahead of your competition. From a comprehensive view of player health to game bets, our Health Score offers the inside track to making informed and winning decisions.
								<br />
								<br />
								You can go even deeper by looking at the Player Health Score: your ultimate tool for individual player insights. Imagine making your daily fantasy picks with the same confidence a team doctor has about a player's condition. Factor this in with the Team Health Score and you can really start pinpointing which players are set to shine. It
								offers control, helping you curate winning lineups with precision. With the Player Health Score, say goodbye to second-guessing and hello to more wins and more money!
							</p>
						</div>
					</Col>
					<Col lg={6} className='aboutScienceCol aboutScienceColRight'>
						<img src={MatchupCard} className='aboutMatchupImg' alt='Matchup' />
					</Col>
				</Row>
			</section>
			<section className='aboutLatestSection container-fluid'>
				<Row className='aboutLatestRow'>
					<Col lg={6} className='aboutLatestCol aboutLatestColRight col-lg-push-6'>
						<div className='aboutLatestDiv'>
							<p className='aboutLatestText'>If you want to dig deeper into a matchup click the “view matchup” button. Here you will be able to see the health of key players and how each position group matches up with the other team. You can also view the scores of every single player on the roster, which is helpful for setting fantasy lineups.</p>
						</div>
					</Col>
					<Col lg={6} className='aboutLatestCol col-lg-pull-6'>
						<img src={Matchup} className='aboutMatchupImg' alt='Matchup' />
					</Col>
					{/* <Col
              lg={6}
              className="aboutLatestCol aboutLatestColLeft col-lg-pull-6"
            /> */}
				</Row>
				{/* <Row className="aboutMatchupRow">
            <Col lg={6} className="aboutMatchupCol">
              <img src={Matchup} className="aboutMatchupImg" alt="Matchup" />
            </Col>
            <Col lg={6} className="aboutMatchupCol">
              <img
                src={Category}
                className="aboutCategoryImg"
                alt="Position Categories"
              />
            </Col>
          </Row> */}
			</section>
			<section className='aboutInfoSection'>
				<div className='aboutInfoDiv'>
					<p className='aboutInfoText'>Unlock the Power of the Health Score and Watch Your Winnings Skyrocket</p>
					<img src={Category} className='aboutCategoryImg' alt='Position Categories' />

					{!user?.login ? (
						<>
							<p className='aboutSubscribeText'>
								<b>Inside Injuries is offering free access so you can see for yourself how all of our analysis works.</b>
							</p>
							<Button
								className='signupButton homeCoverSignupButton'
								onClick={() => {
									dispatch({
										type: 'AUDIT',
										payload: { action: 'SIGNUP' }
									});
									Event(sources?.source, 'CLICK_SIGNUP', 'DASHBOARD_SIGNUP_BUTTON');
								}}
								href='/signup'
							>
								JOIN FOR FREE
							</Button>
						</>
					) : null}
				</div>
				{/* <div className="aboutVideoDiv">
          <h1 className="aboutVideoHeader">
            Watch this video to take a tour through the site.
          </h1>
          <ReactPlayer
            url="https://youtu.be/WG7YYOwVq1g"
            width="100%"
            style={{ margin: "0 0 0 auto" }}
            controls={true}
          />
        </div> */}
			</section>
			<section className='aboutLogoSection'>
				<div className='aboutLogoDiv'>
					<img src={fsta2017} alt='FSTA 2017' className='aboutFstaImg' />
					<h1 className='aboutFstaHeader'>Winner - 2017 FSTA Best Data Provider & 2018 Finalist</h1>
				</div>
			</section>
		</div>
	);
}

const mapStateToProps = (state) => ({
	user: state.user,
	freeAccess: state.freeAccess,
	sources: state.sources,
	prices: state.prices
});

export default connect(mapStateToProps)(About);
