import React, { Component } from 'react';
import '../Dashboard/matchup.css';
import { Row, Col } from 'react-bootstrap';
import upArrow from '../Dashboard/img/upArrow.png';

class TeamLegend extends Component {
	constructor() {
		super();
		this.state = {
			showLegend: false,
			rotateArrow: false
		};
	}

	hideLegend() {
		let arrowBool = this.state.rotateArrow;
		arrowBool = !arrowBool;
		this.setState({
			showLegend: !this.state.showLegend,
			rotateArrow: arrowBool
		});
	}

	render() {
		const { showLegend } = this.state;
		return (
			<Row className='gameLegendRow'>
				<Col lg={2} className='gameLegendCol'>
					<p className='gameLegenLabelText'>HEALTH SCORE</p>
					{showLegend && <p className='gameLegenLabelText gameLegenLabelTextDescription'>Scores are based on the precise calculation of individual starting player health scores by position groupings.</p>}
				</Col>
				<Col lg={2} className='gameLegendCol'>
					<div className='gameLegendStatusDiv'>
						<div className='gameLegendBox cardSpreadDarkGreen' />
						<p className='gameLegendStatus'>
							Optimal Health (The Edge) <br />8 - 10
						</p>
					</div>
					{showLegend && <p className={`gameLegendInfo`}>Completely healthy, or close to healthy, with key players contributing at expected levels of production.</p>}
				</Col>
				<Col lg={2} className='gameLegendCol'>
					<div className='gameLegendStatusDiv'>
						<div className='gameLegendBox cardSpreadGreen' />
						<p className='gameLegendStatus'>
							Good Health (Take The Points)
							<br />6 - 8
						</p>
					</div>
					{showLegend && <p className={`gameLegendInfo`}>Relatively healthy, resulting in contributions slightly lower than projected levels.</p>}
				</Col>
				<Col lg={2} className='gameLegendCol'>
					<div className='gameLegendStatusDiv'>
						<div className='gameLegendBox cardSpreadYellow' />
						<p className='gameLegendStatus'>
							Moderate Health (Even Money)
							<br />4 - 6
						</p>
					</div>
					{showLegend && <p className={`gameLegendInfo`}>Some injury(ies) leading to underperformance when compared to expectations or projections.</p>}
				</Col>
				<Col lg={2} className='gameLegendCol'>
					<div className='gameLegendStatusDiv'>
						<div className='gameLegendBox cardSpreadRed' />
						<p className='gameLegendStatus'>
							Poor Health (Off The Board)
							<br />1 - 4
						</p>
					</div>
					{showLegend && <p className={`gameLegendInfo`}>Significant injury(ies) resulting in substantial underperformance compared to expected levels of production.</p>}
				</Col>
				<Col lg={2} className='gameLegendCol'>
					<a href='#/' className='gameLegendArrowLink' title='Legend Arrow'>
						<img src={upArrow} className={`gameLegendArrow ${this.state.rotateArrow && 'gameLegendArrowRotate'}`} alt='Arrow Symbol' onClick={() => this.hideLegend()} />
					</a>
				</Col>
			</Row>
		);
	}
}

export default TeamLegend;
