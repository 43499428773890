import React from 'react';
import { Button } from 'react-bootstrap';
import './style.css';
import HomeImg from './img/homeBg.png';
import AboutImg from './img/aboutBg.png';
import DownArrow from './img/downArrow.svg';

function GeneralCover(props) {
	const { user, page, arrowScrollID, customHeaderContent, customSubHeaderContent, customCoverImg } = props;
	let customStyle = { backgroundImage: `url(${HomeImg})` };
	let customOverlayStyle = {};
	if (page === 'about' || page === 'whitepaper') {
		customStyle = {
			backgroundImage: `url(${AboutImg})`
		};
	}
	if (customCoverImg) {
		customStyle = {
			backgroundImage: `url(${customCoverImg})`
		};
		customOverlayStyle = {
			backgroundColor: 'rgba(0,0,0,.5)',
			height: '100%',
			width: '100%',
			left: '0',
			position: 'absolute'
		};
	}
	return (
		<section className='generalCoverSection' style={customStyle}>
			<div className='generalCoverDiv'>
				{page === 'home' ? (
					<>
						<h2 className='generalCoverHeader'>Years of NFL injury data, analyzed by a doctor and a data scientist, then formatted to provide player and team performance scores for fantasy football players and high confidence picks for sports bettors.</h2>
						<Button className='generalButton generalSignupButton' href={!user?.login ? '/signup' : `/${user?.sportsName}/upcominggames`} title='Signup'>
							{!user?.login ? 'SIGN UP FOR A FREE TRIAL TODAY' : 'VIEW UPCOMING GAMES'}
						</Button>
					</>
				) : (
					<>
						{!customHeaderContent ? (
							<>
								<h1 className='generalCoverHeader'>Get the Real Scoop on NFL Injuries + How They Impact Each Team</h1>
								<p className='generalCoverText'>Beat the Books by Making Data-Driven Decisions with Doctor-backed Predictions</p>
							</>
						) : (
							<div className='generalCoverHeaderDiv'>
								<h1 className='generalCoverHeader'>{customHeaderContent}</h1>
								{customSubHeaderContent ? (
									<>
										<br />
										<h3 className='generalCoverSubHeader'>
											<i>{customSubHeaderContent}</i>
										</h3>
									</>
								) : null}
							</div>
						)}
					</>
				)}
				{arrowScrollID ? (
					<div className='generalCoverDownArrowDiv'>
						<a href={arrowScrollID} className='generalCoverDownArrowLink' title='Down Arrow'>
							<img src={DownArrow} className='generalCoverDownArrow' alt='Down Arrow' />
						</a>
					</div>
				) : null}
			</div>
			<div className='generalCoverOverlayDiv' style={customOverlayStyle}></div>
		</section>
	);
}

export default GeneralCover;
