import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import InputFields from '../InputFields';
import { Form, Control } from 'react-redux-form';
import Logo from '../../global/img/logo_full_white.png';
import Arrow from './img/arrow.png';
import './style.css';

function SignUpLoginPage(props) {
	const { user, dispatch, userActions, passwordReset } = props;
	const page = window.location.pathname.split('/')[1]?.toUpperCase();
	const [signupFlow, setSignupFlow] = useState(page === 'SIGNUP' && !user?.login ? true : false);
	const [loginFlow, setLoginFlow] = useState(page === 'LOGIN' && !user?.login ? true : false);
	const [forgotPasswordFlow, setForgotPasswordFlow] = useState(false);
	const [resetPasswordFlow, setResetPasswordFlow] = useState(page === 'RESETPASSWORD' ? true : false);
	const [fpToken, setFPToken] = useState(null);

	useEffect(() => {
		const queryParams = new URLSearchParams(window.location.search);
		setFPToken(queryParams.get('fp_token'));
		if (fpToken) {
			dispatch({
				type: 'VERIFY_PASSWORD_RESET_TOKEN',
				payload: {
					token: fpToken
				}
			});
			setResetPasswordFlow(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fpToken]);

	const handleSignup = (data) => {
		dispatch({
			type: 'SIGNUP',
			payload: data
		});
	};

	const handleLogin = () => {
		dispatch({
			type: 'LOGIN',
			payload: { login: userActions.login }
		});
	};

	const handleForgotPassword = () => {
		dispatch({
			type: 'SEND_PASSWORD_RESET',
			payload: {
				email: userActions?.forgot_password?.email
			}
		});
	};

	const handleResetPassword = () => {
		dispatch({
			type: 'RESET_PASSWORD',
			payload: {
				reset_password: userActions.reset_password,
				token: fpToken
			}
		});
	};

	const checkPasswordMatch = () => {
		if (userActions?.reset_password?.password?.length >= 6 && userActions?.reset_password?.confirm_password?.length >= 6 && userActions?.reset_password?.password !== userActions?.reset_password?.confirm_password) {
			return 'Passwords do not match.';
		}
		return '';
	};

	return (
		<section className='signupMainSection'>
			<div className='signupMainDiv'>
				<img src={Logo} alt='Inside Injuries Logo' className='signupLogo' />
				{signupFlow ? (<p className='loginFlowText'>
					Already have an account?
					<a href='/login' className='loginFlowLink ps-2'>
						Login here
					</a>
				</p>) : null}
				{loginFlow ? <p className='signupHeaderText'>Login</p> : null}
				{forgotPasswordFlow || (resetPasswordFlow && !passwordReset?.passwordResetSuccess) ? <p className='signupHeaderText'>Reset your password</p> : null}
				<div className='signupFormDiv'>
					{signupFlow ? (
						<>
							<Form model="userActions.signup" onSubmit={handleSignup}>
								<div className="inputCol">
									<InputFields model=".first_name" inputType="text" type="text" placeholder="Enter your first name" label="First Name" />
								</div>
								<div className="inputCol">
									<InputFields model=".email" inputType="text" type="email" placeholder="Enter your email" label="Email" />
								</div>
								<div className="inputCol">
									<InputFields model=".password" inputType="text" type="password" placeholder="Enter your password" label="Password" />
								</div>
								<div className="w-100">
									{/* eslint-disable-next-line react/jsx-pascal-case */}
									<Control.button model="userActions.signup" className="loginButton" type="submit">
										SIGN UP
									</Control.button>
								</div>
							</Form>
							<p className='signupHeaderText'>Join for free and get access to all injury data.</p>
						</>
					) : null}
					{loginFlow ? (
						<>
							<Form className='loginForm' model='userActions.login' onSubmit={() => handleLogin()}>
								<div className='inputCol'>
									<InputFields model='.email' inputType='text' type='email' placeholder='Enter your email' label='Email' />
								</div>
								<div className='inputCol'>
									<InputFields model='.password' inputType='text' type='password' placeholder='Enter your password' label='Password' />
								</div>
								<div className='inputCol'>
									{/* eslint-disable-next-line react/jsx-pascal-case */}
									<Control.button model='userActions.login' className='loginButton' type='submit'>
										Sign In
									</Control.button>
								</div>
							</Form>
							<a
								href='#/'
								className='loginFlowLink'
								onClick={() => {
									setForgotPasswordFlow(true);
									setLoginFlow(false);
									setSignupFlow(false);
								}}
							>
								Forgot Password
							</a>
							<p className='loginFlowText'>
								Need access?
								<a href='/signup' className='loginFlowLink'>
									Join for free
								</a>
							</p>
						</>
					) : null}
					{forgotPasswordFlow ? (
						<>
							<Form className='' model='userActions.forgot_password' onSubmit={() => handleForgotPassword()}>
								<div className='inputCol'>
									<InputFields model='.email' inputType='text' type='email' placeholder='Email' label='Email' />
								</div>
								<div className='inputCol'>
									{/* eslint-disable-next-line react/jsx-pascal-case */}
									<Control.button model='userActions.forgot_password' className='loginButton' type='submit'>
										Reset Password
									</Control.button>
								</div>
							</Form>
							<a
								href='#/'
								className='loginFlowLink'
								onClick={() => {
									setForgotPasswordFlow(false);
									setLoginFlow(true);
								}}
							>
								Back to login
							</a>
							<p className='loginFlowText'>
								Need access?
								<a href='/signup' className='loginFlowLink'>
									Join for free
								</a>
							</p>
						</>
					) : null}
					{passwordReset?.passwordResetSuccess ? (
						<>
							<h3 className='loginModalHeader'>Password Reset Succesfully</h3>
							<Button href='/' title='Home' className='loginButton'>
								Home
							</Button>
						</>
					) : null}
					{resetPasswordFlow && !passwordReset?.passwordResetSuccess ? (
						<>
							<Form model='userActions.reset_password' onSubmit={() => handleResetPassword()}>
								{fpToken ? (
									<>
										<div className='inputCol'>
											<InputFields model='.password' inputType='text' type='Create a password' placeholder='Password' label='Password' />
										</div>
										<div className='inputCol'>
											<InputFields model='.confirm_password' inputType='text' type='Confirm password' placeholder='Confirm Password' label='Confirm Password' />
										</div>
										<div className='inputCol'>
											{/* eslint-disable-next-line react/jsx-pascal-case */}
											<Control.button model='userActions.reset_password' className='loginButton' type='submit' disabled={userActions?.reset_password?.password !== userActions?.reset_password?.confirm_password}>
												Reset Password
											</Control.button>
										</div>
									</>
								) : (
									<div className='inputCol'>
										{/* eslint-disable-next-line react/jsx-pascal-case */}
										<Button href='/' className='loginButton' disabled={true}>
											Incorrect Link
										</Button>
									</div>
								)}
							</Form>
							<b style={{ color: 'red' }}>{checkPasswordMatch()}</b>
						</>
					) : null}
					{!passwordReset?.passwordResetSuccess && (!user?.login) ? (
						<a href='/' title='Home' className='signupHomeLink'>
							<img src={Arrow} alt='Arrow' className='signupHomeLinkArrow' />
							Home
						</a>
					) : null}
				</div>
			</div>
		</section>
	);
}

const mapStateToProps = (state) => ({
	options: state.options,
	user: state.user,
	userActions: state.userActions,
	passwordReset: state.passwordReset
});

export default connect(mapStateToProps)(SignUpLoginPage);
