import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import './style.css';

function Confirmation(props) {
	const { user, dispatch } = props;
	useEffect(() => {
		const query = new URLSearchParams(window.location.search);

		if (query.get('canceled')?.toUpperCase() === 'TRUE') {
			dispatch({ type: 'LOGOUT' });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (user.isLoading) {
		return (
			<div id='fullHeight'>
				<div id='loading' />
			</div>
		);
	}
	return (
		<div>
			<section id='confirmationSection' className='confirmationSection'>
				<h1 className='confirmationHeader'>Thanks for signing up!</h1>
				<a href={`/${user?.sportsName || 'nba'}/upcominggames`} className='confirmationButton' title='LOGIN'>
					Access Upcoming Games and Player Data Now
				</a>
			</section>
		</div>
	);
}

const mapStateToProps = (state) => ({
	user: state.user,
	freeAccess: state.freeAccess,
	sources: state.sources,
	prices: state.prices
});

export default connect(mapStateToProps)(Confirmation);
