import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { highlightColumn, highlightColumnColor } from '../../utils/dashboardCalculations';

class cardBets extends Component {
	componentDidMount() {}

	render() {
		const { game } = this.props;
		const homeHealthScore = game?.home_team_gaming_score;
		const awayHealthScore = game?.away_team_gaming_score;
		const highlightCol = highlightColumn(homeHealthScore, awayHealthScore);
		const homeHighlightColor = highlightColumnColor(homeHealthScore, 'cardSpread');
		const awayHighlightColor = highlightColumnColor(awayHealthScore, 'cardSpread');
		return (
			<div>
				<Row className='cardSpreadRow'>
					<React.Fragment>
						<Col xs={6} className={`${highlightCol === 'away' && 'cardHighlight'} ${awayHighlightColor}`}>
							<div className='cardSpreadDiv'>
								<h4 className='cardSpread cardSpreadNumber text-center'>{awayHealthScore && highlightCol === 'away' ? awayHealthScore : awayHealthScore || 'N/A'} </h4>
							</div>
						</Col>
						<Col xs={6} className={`${highlightCol === 'home' && 'cardHighlight'} ${homeHighlightColor}`}>
							<div className='cardSpreadDiv'>
								<h4 className='cardSpread cardSpreadNumber text-center'>{homeHealthScore && highlightCol === 'home' ? homeHealthScore : homeHealthScore || 'N/A'}</h4>
							</div>
						</Col>
					</React.Fragment>
				</Row>
			</div>
		);
	}
}

export default cardBets;
